import jwt_decode from 'jwt-decode';

export class JwtToken{
    getDecodedAccessToken(token: string): any {
        console.log("token : "+token);
        try {
            console.log("jwt_decode(token) : "+jwt_decode(token));
            return jwt_decode(token);
        } catch(Error) {
            return null;
        }
    }
}
export interface JwtLoginResponse{
    email: string;
    phone: string;
    name: string;
    role: string;
    jti: string;
    iat: number;
    exp: number;
}