import { Component, OnInit } from '@angular/core';
import { OtpVerificationComponent } from '../otp-verification/otp-verification.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { bounceInRightOnEnterAnimation, bounceInLeftOnEnterAnimation, bounceOutRightOnLeaveAnimation } from 'angular-animations';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'womean-doctor-connect',
  templateUrl: './doctor-connect.component.html',
  styleUrls: ['./doctor-connect.component.scss'],
  animations: [
    bounceInRightOnEnterAnimation({ anchor: 'enter1' }),
    bounceInRightOnEnterAnimation({ anchor: 'enter2', delay: 100 }),
    bounceInRightOnEnterAnimation({ anchor: 'enter3', delay: 200, animateChildren: 'none' }),
    bounceInLeftOnEnterAnimation(),
    bounceOutRightOnLeaveAnimation(),
  ]
})
export class DoctorConnectComponent implements OnInit {
  personalDetails = false;
  public personalForm: FormGroup;
  public EducationformDataTable: FormGroup;
  public specializationform: FormGroup;
  submitted = false;
  IsSubmit = false;
  issubmitted = false;
  Filename:String;
  isLoading = false;
   Loading = false;
  dataSource = new MatTableDataSource([]);
  displayedColumns: string[] = [
    'position', 
    'course', 
    'year', 
    'collegename',
    'certificate',
    'action'
  ];
  button = 'Save & Continue';
  finalSavebutton = 'Save'
  constructor(
    public dialog: MatDialog,private formBuilder: FormBuilder,
    private router: Router
  ) { 

  }

  ngOnInit(): void {
    this.personalForm = this.formBuilder.group({
      Firstname: new FormControl('', Validators.required),
      Lastname: ['', Validators.required],
      Middlename: [''],
      DateOfBirth: ['', Validators.required],
      gender: ['', Validators.required],
      state:['', Validators.required],
    });
    this.EducationformDataTable = this.formBuilder.group({
      Course: ["", Validators.required],
      Year: ["", Validators.required],
      CollegeName: ["", Validators.required],
      InputFile:['',Validators.required]
    });
    this.specializationform = this.formBuilder.group({
      pLanguage: ["", Validators.required],
      imcId: ["", Validators.required],
      speciality: ["", Validators.required],
      yearofExperience:['',Validators.required]
    });
  }

  onSubmit(){
    this.submitted = true;

    if(this.personalForm.status=="INVALID"){
      return
    }
    this.isLoading = true;
    this.button = 'Processing';
    this.personalDetails = true;
  }
  openInput() {
    document.getElementById("fileInput")?.click();
  }
  selectFile($event:any) {
    this.Filename = $event.target.files[0].name; // <--- File Object for future use.
    if ($event.target.files.length > 0)
    {
        let ftu: File = null;
        ftu = $event.target.files[0];
        this.EducationformDataTable.controls['InputFile'].setValue(ftu);
    }
  }

  addTable() {
    this.issubmitted = true;
    if (this.EducationformDataTable.status == "INVALID") {
      return;
    }
    this.dataSource.data.push({
      course: this.EducationformDataTable.value.Course,
      year: this.EducationformDataTable.value.Year,
      collegename: this.EducationformDataTable.value.CollegeName,
      certificate:this.Filename
    });
    this.dataSource.data = [...this.dataSource.data]; //to refresh the table
    this.EducationformDataTable.reset();
    this.Filename ='';
  }

  getOtp() {
    this.dialog.open(OtpVerificationComponent, {
      disableClose: true,
      width: '420px',
      autoFocus: false
    })
  }
  deleteFromTable(element:any) {
    this.dataSource.data.splice(this.dataSource.data.indexOf(element), 1);
    this.dataSource.data = [...this.dataSource.data];
  }

  onSubmitSpecial(){
    this.IsSubmit = true;
    if (this.specializationform.status == "INVALID") {
      return;
    }
    this.Loading = true;
    this.button = 'Processing';
  }

}

// export interface PeriodicElement {
//   position: number;
//   course: string;
//   year: string;
//   collegename: string;
//   certificate: string;
//   action: string;
// }

// const ELEMENT_DATA: PeriodicElement[] = [
//   {
//     position: 1, 
//     course: '', 
//     year: '', 
//     collegename: '', 
//     certificate: '', 
//     action: ''
//   },
// ];
