import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ContinueComponent } from '../continue/continue.component';
import { UserPrefernceService } from '../core/services/user-prefernce.service';
import { JwtLoginResponse, JwtToken } from '../jwtToken';
import { PeriodTrack2Component } from '../period-track2/period-track2.component';
import { RestService } from '../rest.service';
import { SigninComponent } from '../signin/signin.component';
import { Track } from '../userCredentials';
import { NgxSpinnerService } from 'ngx-spinner'; 
import { fadeInOnEnterAnimation,fadeInExpandOnEnterAnimation, fadeOutCollapseOnLeaveAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';

@Component({
  selector: 'app-period-track3',
  templateUrl: './period-track3.component.html',
  styleUrls: ['./period-track3.component.scss'],
  animations: [
    fadeInOnEnterAnimation(),
    fadeOutOnLeaveAnimation(),
    fadeInExpandOnEnterAnimation({ duration: 400 }),
    fadeOutCollapseOnLeaveAnimation({ duration: 400 }),
  ]
})
export class PeriodTrack3Component implements OnInit {
  @Output() onClickContinue = new EventEmitter<any>();
  SigninComponent = SigninComponent;
  ContinueComponent = ContinueComponent;
  jwtTokenData: JwtLoginResponse = undefined
  PeriodTrack2Component = PeriodTrack2Component;
  static periodTrack: Track;
  pId: any;
  email: any;
  public continueHide = false;
  button = 'Continue';
  isLoading = false;
  trackData: any
  Menstrual: any
  MenstrualCycle: any
  showMsg : boolean
  constructor(private route: Router, private rest: RestService, private userPrefernceService: UserPrefernceService,private spinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    PeriodTrack3Component.periodTrack = PeriodTrack2Component.track;
    const jwtToken = new JwtToken();
    this.jwtTokenData = jwtToken.getDecodedAccessToken(this.userPrefernceService.getToken());
    console.log("data : " +JSON.stringify(this.jwtTokenData) );
    if (this.jwtTokenData) {
      PeriodTrack3Component.periodTrack.p_Id = this.jwtTokenData.jti;
      PeriodTrack3Component.periodTrack.e_mail = this.jwtTokenData.email;
    }
    this.getEditData()
  }
  async submit(data: any) {
    this.spinnerService.show();
    this.button = 'Processing';
    PeriodTrack3Component.periodTrack.p_Id = this.jwtTokenData.jti;
    PeriodTrack3Component.periodTrack.e_mail = this.jwtTokenData.email;
    PeriodTrack3Component.periodTrack.assumed_PeriodCycle = data.Menstrual;
    console.log("periodTrack details : " + JSON.stringify(PeriodTrack3Component.periodTrack));
    //localStorage.setItem("periodTrackDetails", JSON.stringify(PeriodTrack3Component.periodTrack));
    let res = await this.rest.periodsUpdate(PeriodTrack3Component.periodTrack);
    if (res.status) {
      this.spinnerService.hide();
      this.route.navigate(["/period-track4"]);
    }
    else {
      this.spinnerService.hide();
      this.button = 'Continue';
    }
  }

  getEditData() {
    this.rest.getPeriodTrackEditData().subscribe((res: any) => {
      console.log(res)
      this.trackData = res
      if (Object.keys(this.trackData).length != 0) {
        this.MenstrualCycle = res.data.assumedPeriodCycle
        this.continueHide = true
        console.log(this.Menstrual)
      }
    })

  }
  countNumber(event: Event) {
    const target = event.target as HTMLInputElement
    if (Number(target.value) > 9 && Number(target.value) < 51 && target.value != "" && target.value.length == 2) {
      this.continueHide = true
      this.showMsg=false
    }
    else {
      this.showMsg=true
      this.continueHide = false
    }
    // if (target.value.length == 2) {
    //   this.continueHide = true
    // }
    // else {
    //   this.continueHide = false
    // }
  }

  goBack(){
    //this.onClickContinue.emit('period-track2')
    this.onClickContinue.emit('back')
  }

}
