import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'womean-pregnancy-steps',
  templateUrl: './pregnancy-steps.component.html',
  styleUrls: ['./pregnancy-steps.component.scss']
})
export class PregnancyStepsComponent implements OnInit {
  @ViewChild('stepper') private pregnancyStepper: MatStepper;
  activeStepper : string
  constructor() { }

  ngOnInit(): void {
  }

  stepperRoute(event: any){ 
    console.log(event)
    if(event == 'back'){
      this.pregnancyStepper.previous();
    }
    else{  
      this.activeStepper=event
      this.pregnancyStepper.next();
    }
  }
}
