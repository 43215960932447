import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-connect-footer',
  templateUrl: './connect-footer.component.html',
  styleUrls: ['./connect-footer.component.scss']
})
export class ConnectFooterComponent implements OnInit {
  @Input() showSocialMediaLink: boolean | undefined;

  socialMediaLinksData = [
    {
      link: "https://www.facebook.com/wearewomean",
      image: "assets/images/facebook.png",
      alt: 'facebook',
    },
    {
      link: "https://www.instagram.com/wearewomean/?igshid=YmMyMTA2M2Y%3D",
      image: "assets/images/instagram.png",
      alt: 'instagram',
    },
    {
      link: "https://twitter.com/wearewomean?s=21&t=xGHvNlOyumKy63WmJ1XO-g",
      image: "assets/images/twitter.png",
      alt: 'twitter',
    }
    
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
