import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { DateRange } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { BaseTrackComponent } from '../base-tracking.component';
import { ErrorModalComponent } from '../modal/error-modal/error-modal.component';
import { RestService } from '../rest.service';
import { Track } from '../userCredentials';
import { WomeanMaterialCalendarComponent } from '../shared/components/womean-material-calendar/womean-material-calendar.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { fadeInOnEnterAnimation,fadeInExpandOnEnterAnimation, fadeOutCollapseOnLeaveAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { UserPrefernceService } from '../core/services/user-prefernce.service';
@Component({
  selector: 'app-pregnancy-track',
  templateUrl: './pregnancy-track.component.html',
  styleUrls: ['./pregnancy-track.component.scss'],
  animations: [
    fadeInOnEnterAnimation(),
    fadeOutOnLeaveAnimation(),
    fadeInExpandOnEnterAnimation({ duration: 400 }),
    fadeOutCollapseOnLeaveAnimation({ duration: 400 }),
  ]
})
export class PregnancyTrackComponent
  extends BaseTrackComponent
  implements OnInit {
  @ViewChild('PregnancyDetails') PregnancyDetails: FormGroupDirective;
  @Output() onClickContinue = new EventEmitter<any>();
  pregnancyTracking = true;
  onPeriod: boolean;
  periods: string;
  pregnant: string;
  static lastPeriodStartDate: any;
  static lastPeriodEndDate: any;
  static trackDetails: Track;
  wantToGetPregnant: boolean;
  animation = 'bounceIn';

  state = true;
  pP = false;
  pR = false;
  showPeriodStartedDate: boolean;
  showYesPeriod: boolean;

  public continueHide = false;
  start_day: any;
  start_month: any;
  start_year: any;
  end_day: any;
  end_month: any;
  end_year: any;
  start_date: any;
  end_date: any;
  trackData: any
  pId: any;
  showMsg: boolean;
  email: any;
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  constructor(public dialog: MatDialog,private userPrefernceService: UserPrefernceService,  private rest: RestService,private location: Location,private router: Router,) {
    super();
  }
  ngOnInit(): void {
    this.getEditData()
  }
  setComponentTrackDates() {
    PregnancyTrackComponent.lastPeriodStartDate = this.start;
    PregnancyTrackComponent.lastPeriodEndDate = this.end;
  }

  getEditData() {
    this.rest.getpregnancyTrackEditData().subscribe((res: any) => {
      console.log(res)
      this.trackData = res
      if (Object.keys(this.trackData).length != 0) {
        if (this.trackData?.data?.onPeriod) {
          this.periodsSaveYes()
        }
        else {
          this.periodsSaveNo()
        }
      }
    })
  }

  editIfnotOnpregnancy(res: any) {
    if (res.data?.lastPeriodStartDate && res.data?.lastPeriodEndDate) {
      console.log(this.selectedRangeValue)
      this.start_day = String(new Date(res.data.lastPeriodStartDate).getDate()).padStart(2, '0');
      this.start_month = String(new Date(res.data.lastPeriodStartDate).getMonth() + 1).padStart(2, '0'); //January is 0!
      this.start_year = String(new Date(res.data.lastPeriodStartDate).getFullYear()).padStart(2, '0');
      this.start = this.start_year + '-' + this.start_month + '-' + this.start_day;
      this.start_date = this.start_day + '-' + this.start_month + '-' + this.start_year;

      this.end_day = String(new Date(res.data.lastPeriodEndDate).getDate()).padStart(2, '0');
      this.end_month = String(new Date(res.data.lastPeriodEndDate).getMonth() + 1).padStart(2, '0'); //January is 0!
      this.end_year = String(new Date(res.data.lastPeriodEndDate).getFullYear()).padStart(2, '0');
      this.end = this.end_year + '-' + this.end_month + '-' + this.end_day;
      this.end_date = this.end_day + '-' + this.end_month + '-' + this.end_year;

      this.selectedRangeValue = new DateRange(moment(this.start), moment(this.end));
      this.handleInputStartDate()

      if (res.data?.pregnant) {
        this.pregnantSaveYes()
      }
      else {
        this.pregnantSaveNo()
      }
    }
  }

  periodsSaveNo(): void {
    this.pP = false;
    this.onPeriod = false;
    this.periods = 'No';
    this.showPeriodStartedDate = true;
    this.showYesPeriod = false;
    this.showCalendar = true; 
    this.editIfnotOnpregnancy(this.trackData)
  }

  periodsSaveYes(): void { 
    this.state = !this.state;
    this.pP = false;
    this.onPeriod = true;
    this.periods = 'Yes';
    this.showPeriodStartedDate = false;
    this.showYesPeriod = true;
    this.showCalendar = false;
    this.continueHide = false;
  }

  pregnantSaveNo(): void {
    this.pR = false;
    this.wantToGetPregnant = false;
    this.continueHide = false;
    this.pregnant = 'No';
    this.showYesPeriod = true;
  }

  pregnantSaveYes(): void {
    this.pR = false;
    this.wantToGetPregnant = true;
    this.continueHide = true;
    this.pregnant = 'Yes';
    this.showYesPeriod = false;
  }

  onSubmit(details: any): void {
    const track = new Track();
    track.period_Tracking = false;
    track.on_Period = this.onPeriod;
    track.day_OfPeriod = 0;
    track.last_PeriodStartDate = Date.parse(this.start);
    track.last_PeriodEndDate = Date.parse(this.end);
    track.having_Cramps = false;
    track.having_Pms = false;
    track.cramp_Severity = 0;
    track.pms_Severity = 0;
    track.assumed_PeriodCycle = 0;
    track.pregnancy_Tracking = true;
    track.pregnant1 = this.wantToGetPregnant;
    track.want_ToGetPregnant = this.wantToGetPregnant;
    track.ovulation_Tracking = true;
    track.to_Notify = false;
    PregnancyTrackComponent.trackDetails = track;
    this.onClickContinue.emit('PregnancyTrack2')
  }

  openDialog() {
    const dialogRef = this.dialog.open(WomeanMaterialCalendarComponent, {panelClass: 'calendar'});

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  displayBox(){
    var modal = document.getElementById("model_");
    modal.style.display = 'block';
  }

  closeBox(){
    var modal = document.getElementById("model_");
    modal.style.display = 'none';
  }

  goBack(){
    this.location.back();
  }

  async handleNavigation(module: string) {
    let sector = ''
    let trackName = module.replace("/", "")
    if (trackName == 'period-steps') sector = 'PERIODS'
    else if (trackName == 'PregnancySteps') sector = 'PREGNANCY'
    else sector = 'OVULATION'
    this.email = this.userPrefernceService.getCurrentUserEmailId();
    this.pId = this.userPrefernceService.getCurrentUserJti();
    if (this.pId != null || this.pId != undefined) {
      let check = await this.rest.trackPostCore(this.pId, this.email, sector);
      if (check.status) {
        if (check.data.tracked) {
          if (trackName == 'period-steps') this.router.navigate(['/period-track4']) //track dashboard
          else if (trackName == 'PregnancySteps') this.router.navigate(['/PregnancyTrack4']) //track dashboard
          else this.router.navigate(['/OvulationTrack3']) //track dashboard
        }
        else {
          this.router.navigate([module]);
        }
      }
    }
  }
}
