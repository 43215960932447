import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
  })
export class CommonDataSharingService{
    public showFooterEvent= new EventEmitter<boolean>();
    public componentForBackground= new EventEmitter<string>();

    private role: string = null;
    constructor(private http: HttpClient) { }

    set ROLE(role: string) {
        this.role = role;
    }

    get ROLE(): string {
        return this.role;
    }

    setFooterSetting(show:boolean):void{
        return this.showFooterEvent.emit(show);
    }
    setComponentForBackground(component: string):void{
        this.componentForBackground.emit(component);
    }
}