<div class="main" >
    <img [src]="imageUrl" alt="img">
    <span class="date d-none">{{date | date: "MMM d"}}</span>
    <div class="overlay d-none">
        <div class="text"><a href="javascript:void(0);" class="link">Read More...</a></div>
    </div>
</div>
<div class="ag-quote">
    <!-- <h5 class="pt-2 mb-1">
        <div class="d-flex justify-content-between">
            <span>{{topic}}</span>
            <a href="javascript:void(0);" class="text-decoration-none">Read More...</a>
        </div>
    </h5> -->
    <p>{{heading}}</p>
    <h5 class="by-auth">By {{author}}</h5>
    <a href="javascript:void(0);" class="read-more">Read More...</a>
</div>
