
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Meta } from '@angular/platform-browser';
import { CanonicalService } from './shared/service/canonical.service';
export let browserRefresh = false;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'womean-frontend';
  subscription: Subscription;
  userForm: FormGroup | any
  constructor(private fb: FormBuilder,
    private router: Router,
    private metaservice: Meta,
    private canonicalService: CanonicalService) { 
    //detect page refresh
    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
  });

    this.userForm = this.fb.group({
      name: ['', Validators.required],
      contactNo: ['', [Validators.required, Validators.minLength(10)]],
    })
  }
  ngOnInit(): void {
    this.canonicalService.setCanonicalURL();
    this.metaservice.addTags([ 
      { name: 'description', content: "Womean provides a one stop solution for everything related to women's health & hygiene."}
  ]);
  }
}
