<div class="modal-title">
    <h2>Big things are on the horizon!
    </h2>
    <span class="close" (click)="onCancel()">
        <img src="assets/images/cross.png" alt="">
    </span>
</div>
<div class="modal-body">
    <div class="modal-img">
        <img src="assets/images/modal-build-1.png" alt="">
    </div>
    <div class="ag-modal-content">
        Embrace the power of connection! Our latest feature brings women together, fostering a supportive community
        where you can connect and inspire each other. Join us in embracing strength, solidarity, and endless
        possibilities. Stay Tuned.
    </div>
</div>