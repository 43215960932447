<header>
  <div class="social-links" *ngIf="showSocialMediaLink">
    <a *ngFor="let mediaLink of socialMediaLinksData" href="{{ mediaLink.link }}" target="_blank"
      class="insta-link"><img src="{{ mediaLink.image }}" alt="{{ mediaLink.alt }}" class="social-media-logo" /></a>
  </div>
  <div class="ag-nav-menu">
    <div class="ag-logo" (click)="clickOnlogo()">
      <img class="logo" src="assets/images/logox.png" alt="logo" />
    </div>
    <div class="ag-menu-items">
      <nav class="navbar navbar-expand-lg">
        <img src="assets/images/close.svg" alt="close" id="mobile-exit" class="mobile-menu-exit" />
        <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
          <!-- <span class="navbar-toggler-icon"></span> -->
          <img src="assets/images/toggle-bar4.png" alt="">
        </button>
        <div class="collapse navbar-collapse ag-mobile-view" [ngClass]="{ 'show': navbarOpen }">
          <div class="ag-nav-toggler ag-right">
            <button class="ag-navbar-toggler menu-d-md-none" type="button" (click)="toggleNavbar()">
              <i class="fa fa-close" aria-hidden="true"></i>
            </button>
          </div>
          <div class="ag-profile">
            <span class="d-flex">
              <img src="assets/images/logo.png" alt="profile_picture">
              <h3>Womean</h3>
            </span>
          </div>

          <div class="ag-user-profile">
            <span class="d-flex">
              <span class="no-user-icon" *ngIf="!userloggedIn" >
                <img src="assets/images/user-icon.png"   alt="profile_picture">
              </span>
              <span class="user-icon" *ngIf="userloggedIn" >
                <img [src]="imageUrl" class="user-span-icon" alt="profile_picture">
              </span>
              <h3>
                <span *ngIf="userloggedIn" class="user-name">{{username}}</span>
                <!-- <span *ngIf="!userloggedIn" class="sign-in-btn"><a href="javascript:void(0);" (click)="navigateToSignIn()"><i aria-hidden="true"
                  class="fa fa-sign-out menu-d-md-none ag-icon-signin"></i> Sign In</a></span> -->
                <span  *ngIf="userloggedIn" class="user-contact">
                  <span><i aria-hidden="true" class="fa fa-envelope icon-color menu-d-md-none mr-2"></i>
                    {{email}}</span>
                  <span><i aria-hidden="true" class="fa fa-phone icon-color menu-d-md-none mr-2"></i>{{phone}}</span>
                </span>
              </h3>
            </span>
          </div>
          <!-- <div> -->
          <ul class="nav-links navbar-nav mr-auto">
            <li *ngFor="let nav of navigationUrls">
              <a routerLink="{{ nav.link }}" [ngClass]="nav.active ? 'active' : '' "><i [ngClass]="nav.icons"
                  aria-hidden="true" class="menu-d-md-none mr-4"></i> {{ nav.urls }}</a>
            </li>
            <li class="menu-show-mobile" *ngIf="userloggedIn" >
              <a href="javascript:void(0);" (click)="signOut()"><i aria-hidden="true"
                  class="fa fa-sign-out menu-d-md-none mr-4"></i> Logout</a>
            </li>
            <!-- <li class="menu-show-mobile">
              <a href="javascript:void(0);" (click)="signOut()"><i aria-hidden="true"
                  class="fa fa-comment menu-d-md-none mr-4"></i> Send Feedback</a>
            </li> -->
          </ul>
          <!-- </div> -->
          <div class="ag-menu-footer d-none">
            <div class="l-out d-none">
              <button><i class="fa fa-sign-out" aria-hidden="true"></i></button>
            </div>
            <div class="r-rserve">
              <a *ngFor="let mediaLink of socialMediaLinksData" href="{{ mediaLink.link }}" target="_blank"
                class="insta-link"><img src="{{ mediaLink.image }}" alt="{{ mediaLink.alt }}"
                  class="social-media-logo" /></a>
            </div>
          </div>
        </div>
      </nav>
    </div>
    <div class="ag-profile">
      <div class="profile">
        <a [matMenuTriggerFor]="menu">
          <div class="profile-logo">
            <i class="icon fa-solid fa-user"></i>
          </div>
          <!-- <div class="myProfile">
            <i class="icon fa fa-chevron-down"></i>
          </div> -->
        </a>
        <!-- <button mat-button [matMenuTriggerFor]="menu">Menu</button> -->
        <mat-menu #menu="matMenu" xPosition="after">
          <div class="kg-profile-name" *ngIf="username">{{username}}</div>
          <div class="sign-up" *ngIf="!userloggedIn"><span class="ag-login" (click)="navigateToSignIn()">Sign In</span></div>
          <div class="choose-user" *ngIf="!userloggedIn">
            <div class="doctor">
              <div class="doc-label">Doctor</div>
              <div class="doc-option">
                <!-- <span class="doc-login" (click)="navigateToSignIn('DOCTOR')">Sign In</span> <span class="ag-pipeline">|</span>  -->
                <span class="doc-signup" (click)="modalConnct()">Sign Up</span>
              </div>
            </div>
            <div class="retailer">
              <div class="retailer-label">Retailer</div>
              <div class="retailer-option">
                <!-- <span class="retailer-login" (click)="navigateToSignIn('RETAILER')">Sign In</span> <span class="ag-pipeline">|</span>  -->
                <span class="retailer-signup" (click)="modalShop()">Sign Up</span>
              </div>
            </div>
            <div class="user">
              <div class="user-label">User</div>
              <div class="user-option">
                <!-- <span class="user-login" (click)="navigateToSignIn('USER')">Sign In</span> <span class="ag-pipeline">|</span>  -->
                <span class="user-signup" (click)="navigateToSignUp('USER')">Sign Up</span>
              </div>
            </div>
          </div>
          <!-- <button mat-menu-item (click)="navigateToSignIn()" *ngIf="!userloggedIn">
            <mat-icon>login</mat-icon>
            <span>Sign In</span>
          </button> -->
          <button mat-menu-item (click)="navigateToModule('Continue')" *ngIf="userloggedIn">
            <mat-icon>person</mat-icon>
            <span>My Profile</span>
          </button>
          <button mat-menu-item *ngIf="userloggedIn" (click)="signOut()">
            <mat-icon>power_settings_new</mat-icon>
            <span>Logout</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</header>