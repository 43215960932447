import { NgModule } from '@angular/core';
import { WomeanSingleSelectAutocompleteComponent } from '../shared/components/womean-single-select-autocomplete/womean-single-select-autocomplete.component';
import { NoRecoredFoundComponent } from './components/no-recored-found/no-recored-found.component';
import { WomeanTrackStartComponent } from './components/womean-track-start/womean-track-start.component';
@NgModule({
  declarations: [
    NoRecoredFoundComponent,
  ],
  imports: [
  ],
  providers: [],
})
export class CoreModule { }
