import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CacheDataService } from 'src/app/core/services/cache-service/cache-data.service';
import { UserPrefernceService } from 'src/app/core/services/user-prefernce.service';
import { BuildModalComponent } from 'src/app/modal/build-modal/build-modal.component';
import { ConnectModalComponent } from 'src/app/modal/connect-modal/connect-modal.component';
import { ShopModalComponent } from 'src/app/modal/shop-modal/shop-modal.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() showSocialMediaLink: boolean | undefined;

  socialMediaLinksData = [
    {
      link: "https://www.facebook.com/wearewomean",
      image: "assets/images/fb.png",
      alt: 'facebook',
    },
    {
      link: "https://www.instagram.com/wearewomean/?igshid=YmMyMTA2M2Y%3D",
      image: "assets/images/insta.png",
      alt: 'instagram',
    },
    {
      link: "https://twitter.com/wearewomean?s=21&t=xGHvNlOyumKy63WmJ1XO-g",
      image: "assets/images/tw.png",
      alt: 'twitter',
    }
    
  ]

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private cacheDataService: CacheDataService,
    private userPrefernceService: UserPrefernceService,
  ) { }

  ngOnInit(): void {
  }

  navigateToModule(moduleName: modules): void {
    //if (this.userPrefernceService.getToken()) {
    this.cacheDataService.setModule(moduleName);
    this.router.navigate([this.cacheDataService.getModule()]);
    console.log(this.cacheDataService.getModule());
    //}
    // else {
    //   this.router.navigate(["/SignIn"]);
    // }
  }

  modalShop() {
    this.dialog.open(ShopModalComponent, { width: '1104px', disableClose: true });
  }

  modalConnct() {
    this.dialog.open(ConnectModalComponent, { width: '1104px', disableClose: true });
  }

  modalBuild() {
    this.dialog.open(BuildModalComponent, { width: '1104px', disableClose: true });
  }
  
}
