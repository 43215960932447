<div class="body">

    <div class="left">
        <span class="ag-signin-logo">
            <img src="/assets/images/logox.png" class="logo">
            <h2>Ovulation Tracking</h2>
        </span>

        <div class="down">
            <p>Womean Inc. 2022 All rights reserved</p>
        </div>
    </div>
    <div class="right">
        <div class="ag-scroll-manage">
            <mat-stepper labelPosition="bottom" #stepper>
                <mat-step>
                    <app-ovulation-track (onClickContinue)="stepperRoute($event)"></app-ovulation-track>
                </mat-step>
                <mat-step>
                    <app-ovulation-track2 *ngIf="activeStepper == 'OvulationTrack2'"
                        (onClickContinue)="stepperRoute($event)"></app-ovulation-track2>
                </mat-step>

            </mat-stepper>
        </div>
    </div>

    <div class="down2">
      <p>Womean Inc. 2022 All rights reserved</p>
    </div>
</div>