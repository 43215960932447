<div class="modal-title">
    <h2>Big things are on the horizon!
    </h2>
    <span class="close" (click)="onCancel()">
        <img src="assets/images/cross.png" alt="">
    </span>
</div>
<div class="modal-body">
    <div class="modal-img">
        <img src="assets/images/modal-shop-1.png" alt="">
    </div>
    <div class="ag-modal-content">
        Prepare yourself for a revolutionary shopping experience, catering to all your needs, right from the comfort of
        your home. Stay tuned for the grand reveal.
    </div>
</div>