import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  bounceInRightOnEnterAnimation,
  bounceInLeftOnEnterAnimation,
  bounceOutRightOnLeaveAnimation,
} from 'angular-animations';
import { OtpVerificationComponent } from '../otp-verification/otp-verification.component';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'womean-retailer-connect',
  templateUrl: './retailer-connect.component.html',
  styleUrls: ['./retailer-connect.component.scss'],
  animations: [
    bounceInRightOnEnterAnimation({ anchor: 'enter1' }),
    bounceInRightOnEnterAnimation({ anchor: 'enter2', delay: 100 }),
    bounceInRightOnEnterAnimation({
      anchor: 'enter3',
      delay: 200,
      animateChildren: 'none',
    }),
    bounceInLeftOnEnterAnimation(),
    bounceOutRightOnLeaveAnimation(),
  ],
})
export class RetailerConnectComponent implements OnInit {
  constructor(public dialog: MatDialog, private router: Router) {}

  ngOnInit(): void {}

  getOtp() {
    this.dialog.open(OtpVerificationComponent, {
      disableClose: true,
      width: '420px',
      autoFocus: false,
    });
  }

  displayedColumns: string[] = [
    'position',
    'course',
    'year',
    'collegename',
    'certificate',
    'action',
  ];
  dataSource = ELEMENT_DATA;

  task: Task = {
    name: 'Select Categories',
    completed: false,
    // color: 'primary',
    subtasks: [
      { name: 'Category 1', completed: false },
      { name: 'Category 2', completed: false },
      { name: 'Category 3', completed: false },
      { name: 'Category 4', completed: false },
      { name: 'Category 5', completed: false }
    ],
  };

  allComplete: boolean = false;

  updateAllComplete() {
    this.allComplete =
      this.task.subtasks != null &&
      this.task.subtasks.every((t) => t.completed);
  }

  someComplete(): boolean {
    if (this.task.subtasks == null) {
      return false;
    }
    return (
      this.task.subtasks.filter((t) => t.completed).length > 0 &&
      !this.allComplete
    );
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.task.subtasks == null) {
      return;
    }
    this.task.subtasks.forEach((t) => (t.completed = completed));
  }
}

export interface PeriodicElement {
  position: number;
  course: string;
  year: string;
  collegename: string;
  certificate: string;
  action: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    position: 1,
    course: '',
    year: '',
    collegename: '',
    certificate: '',
    action: '',
  },
];

export interface Task {
  name: string;
  completed: boolean;
  // color: ThemePalette;
  subtasks?: Task[];
}
