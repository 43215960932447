<div class="body">

    <div class="left">
        <span class="ag-signin-logo">
            <img src="/assets/images/logox.png" class="logo">
            <h2>Pregnancy Tracking</h2>
        </span>

        <div class="down">
            <p>Womean Inc. 2022 All rights reserved</p>
        </div>
    </div>
    <div class="right">
        <div class="ag-scroll-manage">
            <mat-stepper labelPosition="bottom" #stepper>
                <mat-step>
                    <app-pregnancy-track (onClickContinue)="stepperRoute($event)"></app-pregnancy-track>
                </mat-step>
                <mat-step>
                    <app-pregnancy-track2 *ngIf="activeStepper == 'PregnancyTrack2'"
                        (onClickContinue)="stepperRoute($event)"></app-pregnancy-track2>
                </mat-step>
                <mat-step>
                    <app-pregnancy-track3 *ngIf="activeStepper == 'PregnancyTrack3'"
                        (onClickContinue)="stepperRoute($event)"></app-pregnancy-track3>
                </mat-step>
            </mat-stepper>
        </div>
    </div>

    <div class="down2">
      <p>Womean Inc. 2022 All rights reserved</p>
    </div>
</div>