import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'womean-connect-modal',
  templateUrl: './connect-modal.component.html',
  styleUrls: ['./connect-modal.component.scss']
})
export class ConnectModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConnectModalComponent>,
  ) { }

  ngOnInit(): void {
  }

  onCancel() { 
    this.dialogRef.close(); 
  }

}
