<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please Wait... </p>
</ngx-spinner>

<!-- <div class="ag-sm-container">
    <span class="ag-signin-logo">
        <img src="/assets/images/logo.png" alt="" (click)="clickOnlogo()">
        <h2 class="d-md-none">Forgot Password</h2>
    </span>

    <div class="left">
        <p class="d-992-none">Forgot Password</p>
        <div class="ag-email-verification">
            <div class="ag-left-menu">
                <ul>
                    <li [ngClass]="emailValidate?'disabled' : 'active'"><i class="fa fa-envelope ag-i"></i> Email
                        Verification</li>
                    <li [ngClass]="!emailValidate?'disabled' : 'active'"><i class="fa fa-key ag-i"></i> Password Reset
                    </li>
                    <li [ngClass]="!OTPValidate?'disabled' : 'active'"><i class="fa fa-check-circle ag-i"></i> Validate OTP</li>
                </ul>
            </div>
            <div class="ag-right-section">
                <div class="ag-stepper-menu-top">
                    <i class="fa fa-envelope ag-i"></i>
                    <span>Email Verification</span>
                </div>

                <div class="ag-form" *ngIf="!emailValidate && !OTPValidate">
                    <form name="emailForm" #emailForm="ngForm" (ngSubmit)="emailSubmit(emailForm.value)"
                        class="ag-form">
                        <input type="email" class="form-control" id="field" placeholder="Email" value=""
                            pattern="[a-z0-9._%+-]+[^@]+@[a-z0-9.-]+\.[a-z]{2,4}" name="field" #field="ngModel" ngModel
                            required>
                        <div class="danger" style="color: #000;" *ngIf="field.invalid && field.touched">
                            <span *ngIf="field.hasError('pattern')">Invalid Email!</span>
                            <span *ngIf="field.value==''">Email is required!</span>
                        </div>
                        <div class="kg-signin">
                            <button type="submit" class="Signin" [disabled]="emailForm.invalid && isLoading"><i
                                    [ngClass]="{'fa fa-spinner fa-spin': isLoading}"></i>&nbsp;{{button}}</button>
                        </div>
                    </form>
                </div>


                <div class="ag-form" *ngIf="emailValidate">
                    <form name="resetForm" #resetForm="ngForm" (ngSubmit)="resetSubmit(resetForm.value)"
                        class="ag-form">
                        <input id="password" [type]="hide ? 'password' : 'text'" class="form-control" minlength="8"
                            placeholder="Password" (change)="validateInputs(password.value)" title="Password Should contains: &#10;
                        Minimum 8 characters &#10;
                        Atleast 1 numeric &#10;
                        Atleast 1 uppercase &#10;
                        Atleast 1 lowercase" data-html="false" rel="tooltip" required name="password"
                            #password="ngModel" ngModel>
                        <a href="javascript:void:(0);" class="kg-eye" (click)="hide = !hide">
                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </a>
                        <div class="danger" style="color: #FFFFFF;" *ngIf="password.invalid && password.touched">
                            <div class="kg-float-balance">
                                <span *ngIf="!passwordCheckStatus && password.value!=''">{{this.passwordCheck}}</span>
                                <span *ngIf="password.value==''">Password is required!</span>
                            </div>
                        </div>

                        <input id="confirm_password" [type]="confirm_hide ? 'password' : 'text'"
                            class="form-control agkg-mt" placeholder="Confirm Password" title="Password Should contains:&#10;
                Minimum 8 characters&#10;
                Atleast 1 numeric&#10; 
                Atleast 1 uppercase&#10; 
                Atleast 1 lowercase" data-html="false" rel="tooltip" required name="confirm_password"
                            #confirm_password="ngModel" ngModel>
                        <a href="javascript:void:(0);" class="kg-eye" (click)="confirm_hide = !confirm_hide">
                            <mat-icon>{{confirm_hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </a>
                        <div class="danger" style="color: #FFFFFF;"
                            *ngIf="confirm_password.invalid && confirm_password.touched">
                            <div class="kg-float-balance">
                                <span>Confirm Password is required!</span>
                            </div>
                        </div>

                        <div class="danger" style="color: #FFFFFF;"
                            *ngIf="(confirm_password.value != password.value) && confirm_password.valid">
                            <div class="kg-float-balance">
                                <span>Password and Confirm Password should be same!</span>
                            </div>
                        </div>

                        <div class="kg-signin">
                            <button type="submit" class="Signin" [disabled]="resetForm.invalid && isLoading"><i
                                    [ngClass]="{'fa fa-spinner fa-spin': isLoading}"></i>&nbsp;{{button1}}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</div> -->
<div class="ag-sm-container">

    <div class="left">
        <span class="ag-signin-logo">
            <img src="/assets/images/logox.png" class="logo">
            <h2>Reset Password</h2>
            <!-- <div class="user-icon">
                <img src="/assets/images/user-icon2.png">
            </div> -->
        </span>
    </div>
    <div class="right">
        <div class="ag-scroll-manage">
            <h1>Reset Your Password!</h1>
            <!-- <p>Enter Your Details To Sign Up</p> -->
            <div class="ag-email-verification">
                <div class="ag-left-menu">
                    <!-- <ul>
                        <li [ngClass]="emailValidate?'disabled' : 'active'"><i class="fa fa-envelope ag-i"></i> Email
                            Verification</li>
                        <li [ngClass]="!emailValidate?'disabled' : 'active'"><i class="fa fa-key ag-i"></i> Password
                            Reset
                        </li>
                        <li [ngClass]="!OTPValidate?'disabled' : 'active'"><i class="fa fa-check-circle ag-i"></i>
                            Validate OTP</li>
                    </ul> -->

                    <ol class="stepper">
                        <li  [ngClass]="emailValidate?'stepper-item complete' : 'stepper-item'">
                            <span class="stepper-counter"></span>
                            <a href="javascript:void(0);" class="stepper-link">
                                <span>Email Verification</span>
                            </a>
                            <span class="stepper-line"></span>
                        </li>

                        <li  [ngClass]="!emailValidate?'stepper-item' : 'stepper-item'">
                            <span class="stepper-counter"></span>
                            <a href="javascript:void(0);" class="stepper-link">
                                <span>Password Reset</span>
                            </a>
                            <span class="stepper-line"></span>
                        </li>
                    </ol>
                </div>
                <div class="ag-right-section">
                    <div class="ag-stepper-menu-top" *ngIf="!emailValidate" [@bounceInLeftOnEnter] [@bounceOutRightOnLeave]>
                        <span class="stepper-counter">1</span>
                        <a href="javascript:void(0);" class="stepper-link">
                            <span>Email Verification</span>
                        </a>
                        <span class="stepper-line"></span>
                    </div>

                    <div class="ag-form" *ngIf="!emailValidate">
                        <form name="emailForm" #emailForm="ngForm" (ngSubmit)="emailSubmit(emailForm.value)"
                            class="ag-form">

                            <div class="row g-3 mb-4 align-items-center">
                                <div class="col-md-12">
                                    <label class="col-form-label">Email</label>
                                    <input type="email" class="form-control" id="field" placeholder="Email" value=""
                                        pattern="[a-z0-9._%+-]+[^@]+@[a-z0-9.-]+\.[a-z]{2,4}" name="field"
                                        #field="ngModel" ngModel required>
                                    <div class="danger" *ngIf="field.invalid && field.touched">
                                        <span *ngIf="field.hasError('pattern')">Invalid Email!</span>
                                        <span *ngIf="field.value==''">Email is required!</span>
                                    </div>
                                </div>
                            </div>
                            <div class="kg-signin">
                                <button type="submit" class="Signin" [disabled]="emailForm.invalid && isLoading"><i
                                        [ngClass]="{'fa fa-spinner fa-spin': isLoading}"></i>&nbsp;{{button}}</button>
                            </div>
                        </form>
                    </div>

                    <div class="ag-stepper-menu-top" *ngIf="emailValidate">
                        <span class="stepper-counter">1</span>
                        <a href="javascript:void(0);" class="stepper-link">
                            <span>Email Verification</span>
                        </a>
                        <span class="stepper-line"></span>
                    </div>

                    <div class="ag-form" *ngIf="emailValidate">
                        <form name="resetForm" #resetForm="ngForm" (ngSubmit)="resetSubmit(resetForm.value)"
                            class="ag-form">

                            <div class="row g-3 mb-4 align-items-center">
                                <div class="col-md-12">
                                    <label class="col-form-label">Password</label>
                                    <input id="password" [type]="hide ? 'password' : 'text'" class="form-control"
                                        minlength="8" placeholder="Password" (change)="validateInputs(password.value)"
                                        title="Password Should contains: &#10;
                                    Minimum 8 characters &#10;
                                    Atleast 1 numeric &#10;
                                    Atleast 1 uppercase &#10;
                                    Atleast 1 lowercase" data-html="false" rel="tooltip" required name="password"
                                        #password="ngModel" ngModel>
                                    <a href="javascript:void:(0);" class="kg-eye" (click)="hide = !hide">
                                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    </a>
                                    <div class="danger" *ngIf="password.invalid && password.touched">
                                        <div class="kg-float-balance">
                                            <span
                                                *ngIf="!passwordCheckStatus && password.value!=''">{{this.passwordCheck}}</span>
                                            <span *ngIf="password.value==''">Password is required!</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <label class="col-form-label">Confirm Password</label>
                                    <input id="confirm_password" [type]="confirm_hide ? 'password' : 'text'"
                                        class="form-control agkg-mt" placeholder="Confirm Password" title="Password Should contains:&#10;
                            Minimum 8 characters&#10;
                            Atleast 1 numeric&#10; 
                            Atleast 1 uppercase&#10; 
                            Atleast 1 lowercase" data-html="false" rel="tooltip" required name="confirm_password"
                                        #confirm_password="ngModel" ngModel>
                                    <a href="javascript:void:(0);" class="kg-eye"
                                        (click)="confirm_hide = !confirm_hide">
                                        <mat-icon>{{confirm_hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    </a>
                                    <div class="danger" *ngIf="confirm_password.invalid && confirm_password.touched">
                                        <div class="kg-float-balance">
                                            <span>Confirm Password is required!</span>
                                        </div>
                                    </div>

                                    <div class="danger"
                                        *ngIf="(confirm_password.value != password.value) && confirm_password.valid">
                                        <div class="kg-float-balance">
                                            <span>Password and Confirm Password should be same!</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="kg-signin">
                                <button type="submit" class="Signin" [disabled]="resetForm.invalid && isLoading"><i
                                        [ngClass]="{'fa fa-spinner fa-spin': isLoading}"></i>&nbsp;{{button1}}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>