import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserPrefernceService } from '../core/services/user-prefernce.service';
import { RestService } from '../rest.service';

@Component({
  selector: 'app-track-module',
  templateUrl: './track-module.component.html',
  styleUrls: ['./track-module.component.scss']
})
export class TrackModuleComponent implements OnInit {
  pId: any;
  email: any;
  userName: any;
  constructor(private router: Router,
    private rest: RestService,
    private userPreferenceService: UserPrefernceService,
  ) { }

  navigationUrls = [
    {
      urls: 'Home Page',
      link: '/homepage',
      icons: 'fa fa-home',
      active: false
    },
    {
      urls: 'The Initiative',
      link: '/initiative',
      icons: 'fa fa-file',
      active: false
    },
    {
      urls: 'The Team',
      link: '/aboutus',
      icons: 'fa fa-info-circle',
      active: false
    },
    {
      urls: 'Contact Us',
      link: '/contactus',
      icons: 'fa fa-phone',
      active: false
    }
  ]

  trackModules = [
    {
      trackName: 'PERIODS',
      image: '../../assets/images/PERIOD.png',
      alt: 'periodTrackImg',
      trackUrls: '/period-steps',
      subtitle:'Track your periods'
    },
    {
      trackName: 'PREGNANCY',
      image: '../../assets/images/pregnant.png',
      alt: 'trimesterTrackIMg',
      trackUrls: '/PregnancySteps',
      subtitle:'Track your Pregnancy'
    },
    {
      trackName: 'OVULATIONS',
      image: '../../assets/images/OVULATION.png',
      alt: 'ovulationTrackImg',
      trackUrls: '/OvulationSteps',
      subtitle:'Track your Ovulations'
    },
  ]

  ngOnInit(): void {
    this.userName = this.userPreferenceService.getCurrentUserName();
  }


  async handleNavigation(tracks: string) {
    let sector = ''
    let trackName = tracks.replace("/", "")
    if (trackName == 'period-steps') sector = 'PERIODS'
    else if (trackName == 'PregnancySteps') sector = 'PREGNANCY'
    else sector = 'OVULATION'
    this.email = this.userPreferenceService.getCurrentUserEmailId();
    this.pId = this.userPreferenceService.getCurrentUserJti();
    if (this.pId != null || this.pId != undefined) {
      let check = await this.rest.trackPostCore(this.pId, this.email, sector);
      if (check.status) {
        if (check.data.tracked) {
          if (trackName == 'period-steps') this.router.navigate(['/period-track4']) //track dashboard
          else if (trackName == 'PregnancySteps') this.router.navigate(['/PregnancyTrack4']) //track dashboard
          else this.router.navigate(['/OvulationTrack3']) //track dashboard
        }
        else{
          this.router.navigate([tracks])
        }
      }
    }
  }
}
