<div class="body">
  <div>
    <form #OvulationDetails="ngForm" class="formExtension">
      <h1>Are you on your Periods?</h1>
      <div class="input-container text-center">
        <div (click)="pP = true">
          <button type="button" class="input-box icon-select" value="">
            {{ periods }}
          </button>
          <!-- <span class="icon2 fa-solid fa-caret-down ag-pointer"></span> -->
        </div>

        <div class="radio-tile-group" *ngIf="pP"  [@fadeInOnEnter] [@fadeOutOnLeave]>
          <div class="input-container">
            <button class="input-box" type="button" (click)="periodsSaveYes()">
              Yes
            </button>
          </div>
          <div class="input-container">
            <button class="input-box" type="button" (click)="periodsSaveNo()">
              No
            </button>
          </div>
        </div>
        <div *ngIf="showPeriodStartedDate"  [@fadeInOnEnter] [@fadeOutOnLeave]>
          <h1>Enter your last period start date</h1>
          <div (click)="handleInputStartDate()" (click)="displayBox()">
            <input type="text" value="{{ selectedRangeValue?.start | date: 'dd-MM-yyyy' }}" class="input-box"
              [(ngModel)]="start_date" placeholder="DD-MM-YYYY" name="startDate" #startDate="ngModel" ngModel
              readonly (click)="displayBox()" />
            <span class="ag-date ag-pointer"></span>
          </div>
          <div *ngIf="showPeriodStartedDate && selectedRangeValue?.start" (click)="displayBox()">
            <h1>Enter your last period end date</h1>
            <div>
              <input type="text" value="{{ selectedRangeValue?.end | date: 'dd-MM-yyyy' }}" class="input-box"
                [(ngModel)]="end_date" placeholder="DD-MM-YYYY" [defaultValue]="99 - 99 - 9999" name="endDate"
                #endDate="ngModel" ngModel readonly (click)="displayBox()" />
              <span class="ag-date ag-pointer"></span>
            </div>

          </div>
        </div>
        <!-- <div class="d-flex justify-content-center">
          <div class="calendar">
            <div class="mat-calendar-wrapper ag-calendar-wrapper">
              <womean-material-calendar *ngIf="showCalendar && showPeriodStartedDate" [selected]="selectedRangeValue"
                (selectedChange)="selectedChange($event)" [type]="'Range'"></womean-material-calendar>
            </div>
          </div>
        </div> -->
        <div class="modal" id="model_" style="display:none;">
          <div class="modal-content">
            <h1>Enter your last period <br> (start date - end date)</h1>
            <div class="mat-calendar-wrapper ag-calendar-wrapper">
              <womean-material-calendar *ngIf="showCalendar && showPeriodStartedDate" [selected]="selectedRangeValue"
                (selectedChange)="selectedChange($event)" [type]="'Range'"></womean-material-calendar>
            </div>
            <div class="close">
              <button (click)="closeBox()">Done <i class="fa fa-angle-right ml-1"></i></button>
            </div>
          </div>
        </div>
        <div *ngIf="showPeriodStartedDate && selectedRangeValue?.start && selectedRangeValue.end != null">
          <h1  [@fadeInOnEnter] [@fadeOutOnLeave]>Are you looking to get Pregnant?</h1>
          <div class="select">
            <div (click)="pR = true">
              <button type="button" class="input-box icon-select" value="">
                {{ pregnant }}
              </button>
              <!-- <span class="icon2 fa-solid fa-caret-down ag-pointer"></span> -->
            </div>
            <div class="radio-tile-group" *ngIf="pR"  [@fadeInOnEnter] [@fadeOutOnLeave]>
              <div class="input-container">
                <button class="input-box" type="button" (click)="pregnantSaveYes()">
                  Yes
                </button>
              </div>
              <div class="input-container">
                <button class="input-box" type="button" (click)="pregnantSaveNo()">
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="showYesPeriod">
          <h1  [@fadeInOnEnter] [@fadeOutOnLeave]>Enter your Day Of Period</h1>
          <input type="text" class="input-box" pattern="[0-9]+" value="" name="dayOfPeriod" #dayOfPeriod="ngModel"
            [(ngModel)]="day_Period" (keyup)="countNumber($event)" ngModel   [@fadeInOnEnter] [@fadeOutOnLeave]/>
          <div class="text-danger" *ngIf="showMsg">
            <i class="fa-sharp fa-solid fa-circle-exclamation ag-pointer"></i> 
            Length of your Menstrual Cycle should be 1-7
          </div>
          <div *ngIf="
              (OvulationDetails.touched ) || showlookingToGetPregnant
            "  [@fadeInOnEnter] [@fadeOutOnLeave]>
            <h1>Are you looking to get Pregnant?</h1>
            <div class="select">
              <div (click)="pR = true">
                <button type="button" class="input-box icon-select" value="">
                  {{ pregnant }}
                </button>
                <!-- <span class="icon2 fa-solid fa-caret-down ag-pointer"></span> -->
              </div>
              <div class="radio-tile-group" *ngIf="pR" [@fadeInOnEnter] [@fadeOutOnLeave]>
                <div class="input-container">
                  <button class="input-box" type="button" (click)="pregnantSaveYes()">
                    Yes
                  </button>
                </div>
                <div class="input-container">
                  <button class="input-box" type="button" (click)="pregnantSaveNo()">
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ag-track-section" *ngIf="!wantToGetPregnant"  [@fadeInOnEnter] [@fadeOutOnLeave]>
          <h1 class="text-center">Looks like You Do Not Need to Track Your Ovulation</h1>
          <div class="text-center">
            <button type="button" class="select-btn" (click)="handleNavigation('/period-steps')">Track Period</button>
            <button type="button" class="select-btn" routerLink="/read">Explore Articles</button>
            <button type="button" class="select-btn" (click)="handleNavigation('/PregnancySteps')">Track Pregnancy</button>
          </div>
        </div>
      </div>
    </form>
    <div class="ag-continue" [@fadeInOnEnter] [@fadeOutOnLeave]>
      <!-- <button type="button" class="back" (click)="goBack()">
        <i class="icon1 fa-sharp fa-solid fa-play"></i> Go Back
      </button> -->
      <button type="submit" class="Continue" *ngIf="continueHide" [disabled]="showMsg"  [@fadeInOnEnter] [@fadeOutOnLeave] (click)="onSubmit(OvulationDetails.value)">
        Continue
      </button>
    </div>
  </div>
</div>