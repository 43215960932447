import { Injectable, NgZone } from '@angular/core';
import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, Observable, of, tap } from 'rxjs';
import { UserPrefernceService } from '../services/user-prefernce.service';
import { Router } from '@angular/router';

@Injectable()
export class APIInterceptorService implements HttpInterceptor {
  private token: string;
  constructor(
    private userPrefernceService: UserPrefernceService,
    private router: Router,
    private zone: NgZone,
  ) {
    
  }
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    this.token = this.userPrefernceService.getToken();
    console.log(this.token)
    if (this.token) req = req.clone({ setHeaders: { token: this.token } });
    return next.handle(req).pipe(
      catchError(error=>{
        if (error.status == 401 || error.status==400) {
          this.userPrefernceService.removeToken();
          this.zone.run(() => {
            this.router.navigate(['SignIn']);
          });
        } else if(error.status==500){
          this.zone.run(()=>{
            this.router.navigate(['no-record-found'])
          })
        }
        else
        console.log(error);
        return of();
      }),
      tap({
        // next: () => {this.router.navigate([
        //   'SignIn'
        // ]);},
       
      })
    );
  }
 
}
