import { PeriodTrackComponent } from './period-track/period-track.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomepageComponent } from './homepage/homepage.component';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { myRoutings } from './app-routing.module';
import { SigninComponent } from './signin/signin.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ContinueComponent } from './continue/continue.component';
import { GETSTARTEDComponent } from './getstarted/getstarted.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SignSucessComponent } from './sign-sucess/sign-sucess.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { AdminConsole2Component } from './admin-console2/admin-console2.component';
import { AdminConsole3Component } from './admin-console3/admin-console3.component';
import { AdminConsole4Component } from './admin-console4/admin-console4.component';
import { OvulationTrackComponent } from './ovulation-track/ovulation-track.component';
import { OvulationTrack2Component } from './ovulation-track2/ovulation-track2.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { OvulationTrack3Component } from './ovulation-track3/ovulation-track3.component';
import { PregnancyTrackComponent } from './pregnancy-track/pregnancy-track.component';
import { PregnancyTrack2Component } from './pregnancy-track2/pregnancy-track2.component';
import { PregnancyTrack3Component } from './pregnancy-track3/pregnancy-track3.component';
import { CoreModule } from './core/core.module';
import { PregnancyTrack4Component } from './pregnancy-track4/pregnancy-track4.component';
import { TrackModuleComponent } from './track-module/track-module.component';
import { SharedHeaderComponent } from './common/shared-header/shared-header.component';
import { APIInterceptorService } from './core/interceptor/api-interceptor';
import { PeriodTrack2Component } from './period-track2/period-track2.component';
import { PeriodTrack3Component } from './period-track3/period-track3.component';
import { PeriodTrack4Component } from './period-track4/period-track4.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { OnlyAlphabetsDirective } from './only-alphabets.directive';
import { OnlyNumericsDirective } from './only-numerics.directive';
import { SharedModule } from './shared/shared.module';
import { ConnectModuleComponent } from './connect-module/connect-module.component';
import { BuildModuleComponent } from './build-module/build-module.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { InitiativeComponent } from './initiative/initiative.component';
import { ScrollToTopComponent } from './common/scroll-to-top/scroll-to-top.component';
import { AuthGuard } from './core/services/auth.guard';
import { SuccessModalComponent } from './modal/success-modal/success-modal.component';
import { ConfirmationModalComponent } from './modal/confirmation-modal/confirmation-modal.component';
import { ContinueModalComponent } from './modal/continue-modal/continue-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ErrorModalComponent } from './modal/error-modal/error-modal.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

import { ConnectHeaderComponent } from './connect-module/layout/connect-header/connect-header.component';
import { ConnectFooterComponent } from './connect-module/layout/connect-footer/connect-footer.component';
import { OnlyDecimaNumberDirective } from './only-decima-number.directive';
import { BuildHeaderComponent } from './build-module/layout/build-header/build-header.component';
import { BuildFooterComponent } from './build-module/layout/build-footer/build-footer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import { PregnancyStepsComponent } from './pregnancy-steps/pregnancy-steps.component';
import { PeriodStepsComponent } from './period-steps/period-steps.component';
import { OvulationStepsComponent } from './ovulation-steps/ovulation-steps.component';
import { OtpVerificationComponent } from './otp-verification/otp-verification.component';
import { DoctorSignUpComponent } from './doctor-sign-up/doctor-sign-up.component';
import { DoctorConnectComponent } from './doctor-connect/doctor-connect.component';
import { MatTableModule } from '@angular/material/table';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgOtpInputModule } from 'ng-otp-input';
import { RetailerSignupComponent } from './retailer-signup/retailer-signup.component';
import { RetailerConnectComponent } from './retailer-connect/retailer-connect.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ShopModalComponent } from './modal/shop-modal/shop-modal.component';
import { BuildModalComponent } from './modal/build-modal/build-modal.component';
import { ConnectModalComponent } from './modal/connect-modal/connect-modal.component';
@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    HeaderComponent,
    FooterComponent,
    TrackModuleComponent,
    PeriodTrackComponent,
    SharedHeaderComponent,
    myRoutings,
    HomepageComponent,
    SigninComponent,
    SignUpComponent,
    ContinueComponent,
    GETSTARTEDComponent,
    SignSucessComponent,
    AdminLoginComponent,
    AdminConsole2Component,
    AdminConsole3Component,
    AdminConsole4Component,
    OvulationTrackComponent,
    OvulationTrack2Component,
    OvulationTrack3Component,
    PregnancyTrackComponent,
    PregnancyTrack2Component,
    PregnancyTrack3Component,
    PregnancyTrack4Component,
    PeriodTrack2Component,
    PeriodTrack3Component,
    PeriodTrack4Component,
    OnlyAlphabetsDirective,
    OnlyNumericsDirective,
    ConnectModuleComponent,
    BuildModuleComponent,
    AboutUsComponent,
    ContactUsComponent,
    InitiativeComponent,
    ScrollToTopComponent,
    SuccessModalComponent,
    ContinueModalComponent,
    ConfirmationModalComponent,
    ErrorModalComponent,
    ForgotPasswordComponent,
    BuildHeaderComponent,
    BuildFooterComponent,
    ConnectHeaderComponent,
    ConnectFooterComponent,
    OnlyDecimaNumberDirective,
    PregnancyStepsComponent,
    PeriodStepsComponent,
    OvulationStepsComponent,
    OtpVerificationComponent,
    DoctorSignUpComponent,
    DoctorConnectComponent,
    RetailerSignupComponent,
    RetailerConnectComponent,
    ShopModalComponent,
    BuildModalComponent,
    ConnectModalComponent
  ],
  imports: [
    CoreModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    // import HttpClientModule after BrowserModule.
    HttpClientModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    SharedModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    NgbModule,
    NgxSpinnerModule,
    NgCircleProgressModule.forRoot({}),
    MatStepperModule, 
    MatDialogModule,
    MatTableModule,
    MatProgressBarModule ,
    MatDialogModule,
    NgOtpInputModule,
    MatCheckboxModule
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIInterceptorService,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    AuthGuard
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
