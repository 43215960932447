<div class="body womean-about-us">
  <app-shared-header [navigationUrls]="navigationUrls" [showSocialMediaLink]="false"></app-shared-header>

  <div class="container">
    <div class="about-img">
      <img class="img-aboutUs" src="\assets\images\AboutUs.png" alt="">
    </div>
    <div class="page-heading">About Us</div>

    <div class="quoted-text">
      <img src="/assets/images/quotation.png" alt="">
      Often new ideas float when an old problem remains unaddressed"
    </div>

    <div class="ag-p-text">
      With this thought in mind, and an existing problem in front of us, team <span>Womean</span> set up shop to fight
      <br>
      against what has constantly been talked about, but mostly also hushed down.
    </div>

    <div class="ag-p-text">
      At <span>Womean</span>, our aim is to create a one stop solution to women's queries about their bodies, and the
      changes <br>
      they go through, and easing their way through womanhood, by providing them <br>
      with resources to make their navigation through it less bumpy.
    </div>

    <div class="ag-p-text">
      <span>Womean</span> functions with a 5 pronged approach to bridge the gap between the people and product.
    </div>

    <div class="ag-p-text">
      It aims to build an omnidirectional platform so that people are able to find the most accurate information <br>
      and also gain the most accurate understanding of their problems.
    </div>

    <div class="areafocus">
      Our Areas of Focus
    </div>

    <div class="row justify-content-center">
      <div class="col-md-4">
        <div class="ag-count">
          <div class="count-num">01</div>
          <!-- <div class="count-text">
            Lack of knowledge about the <span>body and its functions</span> with respect to the biological
            clock
          </div> -->
          <div class="heading">Modernising health</div>
          <p>Personalise your support through our AI based
            (hopefully questionnaires) tools, that help you track,
            analyse, and resolve your bodily functions</p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="ag-count">
          <div class="count-num">02</div>
          <!-- <div class="count-text">
            Lack of information regarding <span>what is normal and what is not</span> and remedies to
            counter the irregularities
          </div> -->
          <div class="heading">Lets understand our bodies</div>
          <p>Access the platform to gain insights about the signals 
            that your body provides to you at different stages- that 
            time of the month or this stage of the life! Become an 
            expert on YOURSELF, take control.</p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="ag-count">
          <div class="count-num">03</div>
          <!-- <div class="count-text">
            <span>Dependency on other people</span> for solution and support
          </div> -->
          <div class="heading">Let us help you find the right services</div>
          <p>Consult and meet with our wide network of doctors to 
            discuss your body with ease, while being worry free 
            about privacy and judgement.</p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="ag-count">
          <div class="count-num">04</div>
          <!-- <div class="count-text">
            Lack of preparedness and <span>supplies for cases of emergencies</span>
          </div> -->
          <div class="heading">One stop to meet all your needs</div>
          <p>Make way to our shopping segment to find all products 
            to make your experience smoother- from menstrual 
            hygiene to sexual health- all in one place.</p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="ag-count">
          <div class="count-num">05</div>
          <!-- <div class="count-text">
            Not having enough <span>reliable people</span> around to listen, discuss and share experiences.
          </div> -->
          <div class="heading">All of us, hand in hand</div>
          <p>At womean, we aim to carry forward the whole of community- people 
            who menstruate, girls entering womanhood, women entering 
            motherhood, and mothers caring for their daughters and families- <br>
            one for all, and all for one.</p>
        </div>
      </div>
      <!-- <div class="col-md-4">
        <div class="ag-count">
          <div class="count-num">06</div>
          <div class="count-text">
            Lack of Awareness on <span>health, hygiene & lifestyle choices.</span>
          </div>
        </div>
      </div> -->
    </div>

    <!-- <div class="row align-items-center">
      <div class="col-md-6">
        <div class="d-flex justify-content-center flex-column">
          <div class="areafocus-content">
            <div class="count-sec">01</div>
            <div class="heading">Modernising health</div>
            <p>Personalise your support through our AI based
              (hopefully questionnaires) tools, that help you track,
              analyse, and resolve your bodily functions</p>
          </div>
          <div class="areafocus-content d-none-993">
            <div class="count-sec">02</div>
            <div class="heading">Lets understand our bodies</div>
            <p>Access the platform to gain insights about the signals <br>
              that your body provides to you at different stages- that <br>
              time of the month or this stage of the life! Become an <br>
              expert on YOURSELF, take control.</p>
          </div>
          <div class="areafocus-content">
            <div class="count-sec">03</div>
            <div class="heading">Let us help you find the right services</div>
            <p>Consult and meet with our wide network of doctors to <br>
              discuss your body with ease, while being worry free <br>
              about privacy and judgement.</p>
          </div>
          <div class="areafocus-content d-none-993">
            <div class="count-sec">04</div>
            <div class="heading">One stop to meet all your needs</div>
            <p>Make way to our shopping segment to find all products <br>
              to make your experience smoother- from menstrual <br>
              hygiene to sexual health- all in one place.</p>
          </div>
          <div class="areafocus-content">
            <div class="count-sec">05</div>
            <div class="heading">All of us, hand in hand</div>
            <p>At womean, we aim to carry forward the whole of community- people <br>
              who menstruate, girls entering womanhood, women entering <br>
              motherhood, and mothers caring for their daughters and families- <br>
              one for all, and all for one.</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 d-none-992">
        <div class="d-flex justify-content-center flex-column">
          <div class="areafocus-content">
            <div class="count-sec">02</div>
            <div class="heading">Lets understand our bodies</div>
            <p>Access the platform to gain insights about the signals <br>
              that your body provides to you at different stages- that <br>
              time of the month or this stage of the life! Become an <br>
              expert on YOURSELF, take control.</p>
          </div>
          <div class="areafocus-content">
            <div class="count-sec">04</div>
            <div class="heading">One stop to meet all your needs</div>
            <p>Make way to our shopping segment to find all products <br>
              to make your experience smoother- from menstrual <br>
              hygiene to sexual health- all in one place.</p>
          </div>
        </div>
      </div>
    </div> -->

  </div>
  <div class="footer">
    <app-footer></app-footer>
  </div>
</div>