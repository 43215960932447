import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RestService } from '../rest.service';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { UserPrefernceService } from '../core/services/user-prefernce.service';
import { HttpClient } from '@angular/common/http';
import { ValidateOTP } from '../userCredentials';
import { Router } from '@angular/router';
import { ErrorModalComponent } from '../modal/error-modal/error-modal.component';
import { SuccessModalComponent } from '../modal/success-modal/success-modal.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'womean-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.scss']
})
export class OtpVerificationComponent implements OnInit {
  @ViewChild('ngOtpInput') ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
  };
  public FormData: FormGroup;
  hide = true;
  button = 'Sign in';
  isLoading = false;
  public uid: any;
  otp: any = '';
  showBtn = false;
  Otpbutton = 'Verify';
  constructor(
    public dialogRef: MatDialogRef<OtpVerificationComponent>,private rest: RestService,
    private http: HttpClient,
    private formBuilder: FormBuilder,private spinnerService: NgxSpinnerService,
    private userPrefernceService: UserPrefernceService,    private _fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,    private route: Router,   public dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }
  onCancel(action : boolean) {
    this.dialogRef.close(action);
  }
  onOtpChange(value: any): void {
    if(value.length==6){
      this.otp=value;
      this.showBtn = true
    }
  }
  async onValidate() {
    this.isLoading = true;
    this.Otpbutton = 'Processing';
    this.dialogRef.close(this.otp);
  }
  async resendOtp() {
    this.spinnerService.show();
    this.otp = "";
    const user = new ValidateOTP(this.data, this.otp);
    // Function call to hit the signin user API
    let res = await this.rest.onResendOtp(user);
    console.log(res)
    this.spinnerService.hide();
    if (res.status) {
      let message = "OTP sent successfully";
      const dialogRef = this.dialog.open(SuccessModalComponent, { width: '420px', data: message, disableClose: true });
      dialogRef.afterClosed().subscribe(result => {

      })
    }
  }
}
