import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContinueComponent } from '../continue/continue.component';
import { CacheDataService } from '../core/services/cache-service/cache-data.service';
import { UserPrefernceService } from '../core/services/user-prefernce.service';
import { JwtToken } from '../jwtToken';
import { RestService } from '../rest.service';
import { SigninComponent } from '../signin/signin.component';

@Component({
  selector: 'app-getstarted',
  templateUrl: './getstarted.component.html',
  styleUrls: ['./getstarted.component.scss'],
})
export class GETSTARTEDComponent implements OnInit {
  SigninComponent = SigninComponent;
  ContinueComponent = ContinueComponent;
  constructor(
    private rest: RestService,
    private route: Router,
    private userPreferenceService: UserPrefernceService,
    private cacheDataService: CacheDataService,
    private router: Router
  ) {}

  static userName: any;

  pId: any;
  email: any;
  username: any;
  async ngOnInit(): Promise<void> {
    this.username = this.userPreferenceService.getCurrentUserName();
    GETSTARTEDComponent.userName = this.username;
  }
  async getStarted() {
    this.username = this.userPreferenceService.getCurrentUserName();
    this.email = this.userPreferenceService.getCurrentUserEmailId();
    this.pId = this.userPreferenceService.getCurrentUserJti();
    GETSTARTEDComponent.userName = this.pId;
    this.route.navigate(['/track']);
    // if (this.pId != null || this.pId != undefined) {
    //   let check = await this.rest.trackPostCore(this.pId,this.email);
    //   if (check.status) {
    //     this.route.navigate(['/track']);
    //   }
    // }
  }

  navigationUrls = [
    {
      urls: 'Home Page',
      link: '/homepage',
      icons: 'fa fa-home',
      active: false
    },
    {
      urls: 'The Initiative',
      link: '/initiative',
      icons: 'fa fa-file',
      active: false
    },
    {
      urls: 'The Team',
      link: '/aboutus',
      icons: 'fa fa-info-circle',
      active: false
    },
    {
      urls: 'Contact Us',
      link: '/contactus',
      icons: 'fa fa-phone',
      active: false
    }
  ];

  handleNavigation(module: string) {
    this.router.navigate([module]);
  }
}
