<div class="ag-sm-container">
  <div class="left">
    <span class="ag-signin-logo" (click)="clickOnlogo()">
      <img src="/assets/images/logox.png" class="logo d-none-992">
      <h2>Help us set you up</h2>
    </span>
    <div class="d-none-992">
      <h1>Good Evening!</h1>
    </div>
    <br>
    <div class="kg-cont-support">
      <p class="font-weight-bold">E-Mail: {{e_mail}}</p>
      <p class="font-weight-bold">Phone: {{phone}}</p>
      <p class="font-weight-bold">Premium Account : Not Active</p>
      <div class="mt-3 mb-4 d-flex align-items-center">
        <div class="float-left form-inline display-5" (click)="clickOnHeadPhone()">
          <i style="color: #38031E;" class="fa-solid fa-headphones-simple">
            <!-- <a href="javascript:void:(0);" ></a> -->
          </i>
          <p class="font-weight-bold mb-0 pl-3">Contact Support</p>
        </div>
      </div>

      <div class="text-left ag-df-g10">
        <div class="text-left">
          <div class="float-left form-inline display-6">
            <i style="color: #38031E;" class='fab fa-facebook'></i>
          </div>
        </div>
        <div class="text-center">
          <div class="float-left form-inline display-6">
            <i style="color: #38031E;" class='fab fa-google-plus'>fa-google-plus</i>
          </div>
        </div>

        <div class="text-left">
          <div class="float-left form-inline display-6">
            <i style="color: #38031E;" class='fab fa-instagram'>fa-instagram</i>
          </div>
        </div>
      </div>
    </div>

    <span class="ag-copyright">Womean Inc. 2022 All rights reserved</span>
  </div>

  <div class="center user-icon d-none-992">
    <span class="user-span">
      <img *ngIf="imageUrl" class="user-span-icon" [src]="imageUrl" alt="">
      <div *ngIf="!imageUrl"><i [ngClass]="{'fa fa-spinner fa-spin': imageUrldownloading}"></i></div>
      <div *ngIf="defaultimage"><i [ngClass]="{'fa fa-user user-span-icon': defaultimage}"></i></div>
    </span>
    <a href="javascript:void:(0);" class="ag-plus-i" type="file" (click)="openInput()">
      <input id="fileInput" hidden type="file" (change)="fileChange($event)" accept="image/png, image/jpeg,">
      <i class="fa fa-plus"></i></a>
  </div>


  <div class="right">
    <div class="ag-respobsive-menu">
      <app-header> </app-header>
    </div>
    <div class="d-none-993">
      <img src="/assets/images/logox.png" class="img-1" alt="" height="130" width="130">
    </div>

    <div class="center user-icon2 user-d-none-993">
      <span class="user-span">
        <img *ngIf="imageUrl" class="user-span-icon" [src]="imageUrl" alt="">
        <div *ngIf="!imageUrl"><i [ngClass]="{'fa fa-spinner fa-spin': imageUrldownloading}"></i></div>
        <div *ngIf="defaultimage"><i [ngClass]="{'fa fa-user user-span-icon': defaultimage}"></i></div>
      </span>
      <a href="javascript:void:(0);" class="ag-plus-i" type="file" (click)="openInput()">
        <input id="fileInput" hidden type="file" (change)="fileChange($event)" accept="image/png, image/jpeg,">
        <i class="fa fa-plus"></i></a>
    </div>

    <div class="ag-scroll-manage">

      <div class="col-md-10 offset-2 text-left">
        <form [formGroup]="registerForm">
          <div class="ag-form">

            <div class="row g-3 mb-4">
              <div class="col-md-7">

                <div class="row align-items-center">
                  <div class="col-md-12">
                    <label class="col-form-label">First Name *</label>
                    <input name="Firstname" id="Firstname" onlyAlphabets type="text" class="input-box" [minLength]="4"
                      placeholder=" First Name *" value="{{Firstname}}" formControlName="Firstname" required>
                  </div>
                  <div class="col-md-12">
                    <label class="col-form-label">Middle Name</label>
                    <input id="Middlename" onlyAlphabets type="text" class="input-box" [minLength]="4"
                      placeholder=" Middle Name" value="{{Middlename}}" formControlName="Middlename">
                  </div>
                  <div class="col-md-12">
                    <label class="col-form-label">Last Name *</label>
                    <input id="Lastname" onlyAlphabets type="text" class="input-box" [minLength]="4"
                      placeholder=" Last Name *" value="{{Lastname}}" formControlName="Lastname" required>
                  </div>
                </div>
                <div class="row g-3 align-items-center">
                  <div class="col-md-6">
                    <label class="col-form-label">Gender</label>
                    <select id="Gender" name="Gender" value="{{Gender}}" formControlName="Gender"
                      class="input-box option-select-placeholder">
                      <option disabled selected hidden value="Gender" class="gender">Gender *</option>
                      <option value="Female">Female</option>
                      <option value="Male">Male</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label class="col-form-label">Date of Birth</label>
                    <input readonly matInput [matDatepicker]="picker1" id="DateOfBirth" class="input-box icon3"
                      [value]="DateOfBirth" placeholder="Date of Birth" max="Date.now()" formControlName="DateOfBirth"
                      required>
                    <span class="date-mat-icon">
                      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                      <mat-datepicker #picker1></mat-datepicker>
                    </span>
                  </div>
                </div>
                <div class="row g-3 align-items-center">
                  <div class="col-md-6">
                    <label class="col-form-label">Location</label>
                    <select id="Location" name="Location" value="{{Location}}" formControlName="Location"
                      class="input-box option-select-placeholder">
                      <option disabled selected hidden value="Location" class="gender">Location *</option>
                      <option value="Andhra Pradesh">Andhra Pradesh</option>
                      <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                      <option value="Assam">Assam</option>
                      <option value="Bihar">Bihar</option>
                      <option value="Chhattisgarh">Chhattisgarh</option>
                      <option value="Goa">Goa</option>
                      <option value="Gujarat">Gujarat</option>
                      <option value="Haryana">Haryana</option>
                      <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                      <option value="Jharkhand">Jharkhand</option>
                      <option value="Karnataka">Karnataka</option>
                      <option value="Kerala">Kerala</option>
                      <option value="Madhya Pradesh">Madhya Pradesh</option>
                      <option value="Maharashtra">Maharashtra</option>
                      <option value="Manipur">Manipur</option>
                      <option value="Meghalaya">Meghalaya</option>
                      <option value="Mizoram">Mizoram</option>
                      <option value="Nagaland">Nagaland</option>
                      <option value="Odisha">Odisha</option>
                      <option value="Punjab">Punjab</option>
                      <option value="Rajasthan">Rajasthan</option>
                      <option value="Sikkim">Sikkim</option>
                      <option value="Tamil Nadu">Tamil Nadu</option>
                      <option value="Telangana">Telangana</option>
                      <option value="Tripura">Tripura</option>
                      <option value="Uttar Pradesh">Uttar Pradesh</option>
                      <option value="Uttarakhand">Uttarakhand</option>
                      <option value="West Bengal">West Bengal</option>
                      <option value="Others">others</option>
                    </select>
                  </div>
                  <!-- <div class="col-md-12">
                <label class="col-form-label"></label>

              </div>
              <div class="col-md-12">
                <label class="col-form-label"></label>

              </div> -->
                </div>
              </div>
            </div>

            <div class="d-block ag-h-50">
              <input id="Weight" womeanOnlyDecimaNumber type="text" class="input-box2" placeholder=" Weight"
                value="{{Weight}}" formControlName="Weight">

              <div class="">
                <input type="radio" name="WeightType" value="kgs" id="kgs" [checked]="WeightType=='kgs'"
                  (change)="onChangeWeightType()" formControlName="WeightType">
                <input type="radio" name="WeightType" value="lbs" id="lbs" [checked]="WeightType=='lbs'"
                  (change)="onChangeWeightType()" formControlName="WeightType">
                <div class="switch">
                  <label for="kgs">kgs</label>
                  <label for="lbs">lbs</label>
                </div>
              </div>
            </div>

            <div class="d-block">
              <input id="Height" womeanOnlyDecimaNumber type="text" class="input-box2" placeholder=" Height"
                value="{{Height}}" formControlName="Height">

              <div id="HeightType">
                <input type="radio" name="HeightType" value="cm" id="cm" [checked]="HeightType=='cm'"
                  (change)="onChangeHeightType()" formControlName="HeightType">
                <input type="radio" name="HeightType" value="in" id="in" [checked]="HeightType=='in'"
                  (change)="onChangeHeightType()" formControlName="HeightType">
                <div class="switch2">
                  <label for="cm">cm</label>
                  <label for="in">in</label>
                </div>
              </div>
            </div>

            <div class="ag-continue" *ngIf="!jwtTokenData">
              <button type="submit" class="Continue" [disabled]="isLoading" (click)="onContinue()"><i
                  [ngClass]="{'fa fa-spinner fa-spin': isLoading}"></i>&nbsp;{{button}}</button>
            </div>
            <div class="ag-continue" *ngIf="jwtTokenData">
              <button type="submit" class="Continue" [disabled]="isLoading" (click)="onSave()"><i
                  [ngClass]="{'fa fa-spinner fa-spin': isLoading}"></i>&nbsp;{{button}}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <span class="ag-copyright">Womean Inc. 2022 All rights reserved</span>
  </div>
</div>