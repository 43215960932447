import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { SigninComponent } from './signin/signin.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ContinueComponent } from './continue/continue.component';
import { GETSTARTEDComponent } from './getstarted/getstarted.component';
import { SignSucessComponent } from './sign-sucess/sign-sucess.component'
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { AdminConsole2Component } from './admin-console2/admin-console2.component';
import { AdminConsole3Component } from './admin-console3/admin-console3.component';
import { AdminConsole4Component } from './admin-console4/admin-console4.component';
import { OvulationTrackComponent } from './ovulation-track/ovulation-track.component';
import { OvulationTrack2Component } from './ovulation-track2/ovulation-track2.component';
import { OvulationTrack3Component } from './ovulation-track3/ovulation-track3.component';
import { PregnancyTrackComponent } from './pregnancy-track/pregnancy-track.component';
import { PregnancyTrack2Component } from './pregnancy-track2/pregnancy-track2.component';
import { PregnancyTrack3Component } from './pregnancy-track3/pregnancy-track3.component';
import { PregnancyTrack4Component } from './pregnancy-track4/pregnancy-track4.component';
import { PeriodTrackComponent } from './period-track/period-track.component';
import { TrackModuleComponent } from './track-module/track-module.component';
import { NoRecoredFoundComponent } from './core/components/no-recored-found/no-recored-found.component';
import { PeriodTrack2Component } from './period-track2/period-track2.component';
import { PeriodTrack3Component } from './period-track3/period-track3.component';
import { PeriodTrack4Component } from './period-track4/period-track4.component';
import { ConnectModuleComponent } from './connect-module/connect-module.component';
import { BuildModuleComponent } from './build-module/build-module.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { InitiativeComponent } from './initiative/initiative.component';
import { AuthGuard } from './core/services/auth.guard';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PregnancyStepsComponent } from './pregnancy-steps/pregnancy-steps.component';
import { OvulationStepsComponent } from './ovulation-steps/ovulation-steps.component';
import { PeriodStepsComponent } from './period-steps/period-steps.component';
import { DoctorSignUpComponent } from './doctor-sign-up/doctor-sign-up.component';
import { DoctorConnectComponent } from './doctor-connect/doctor-connect.component';
import { RetailerConnectComponent } from './retailer-connect/retailer-connect.component';
import { RetailerSignupComponent } from './retailer-signup/retailer-signup.component';

const routes: Routes = [
  { path: '', redirectTo: 'homepage', pathMatch: 'full' },
  { path: 'homepage', component: HomepageComponent },
  { path: 'SignIn', component: SigninComponent },
  { path: 'SignUp', component: SignUpComponent },
  { path: 'SignSucess', component: SignSucessComponent },
  { path: 'Continue',  component: ContinueComponent },
  { path: 'GETSTARTED', canActivate: [AuthGuard],component: GETSTARTEDComponent },
  { path: 'AdminLogin', component: AdminLoginComponent },
  { path: 'AdminConsole2', component: AdminConsole2Component },
  { path: 'AdminConsole3', component: AdminConsole3Component },
  { path: 'AdminConsole4', component: AdminConsole4Component },
  { path: 'OvulationSteps',canActivate: [AuthGuard], component: OvulationStepsComponent },
  { path: 'OvulationTrack',canActivate: [AuthGuard], component: OvulationTrackComponent },
  { path: 'OvulationTrack2',canActivate: [AuthGuard], component: OvulationTrack2Component },
  { path: 'OvulationTrack3',canActivate: [AuthGuard], component: OvulationTrack3Component },
  { path: 'PregnancySteps',canActivate: [AuthGuard], component: PregnancyStepsComponent },
  { path: 'PregnancyTrack',canActivate: [AuthGuard], component: PregnancyTrackComponent },
  { path: 'PregnancyTrack2',canActivate: [AuthGuard], component: PregnancyTrack2Component },
  { path: 'PregnancyTrack3',canActivate: [AuthGuard], component: PregnancyTrack3Component },
  { path: 'PregnancyTrack4',canActivate: [AuthGuard], component: PregnancyTrack4Component },
  { path: 'track',canActivate: [AuthGuard], component: TrackModuleComponent },
  { path: 'period-steps',canActivate: [AuthGuard], component: PeriodStepsComponent },
  { path: 'period-track',canActivate: [AuthGuard], component: PeriodTrackComponent },
  { path: 'period-track2',canActivate: [AuthGuard], component: PeriodTrack2Component },
  { path: 'period-track3',canActivate: [AuthGuard], component: PeriodTrack3Component },
  { path: 'period-track4',canActivate: [AuthGuard], component: PeriodTrack4Component },
  { path: 'connect', component: ConnectModuleComponent },
  { path: 'build', component: BuildModuleComponent },
  { path: 'aboutus', component: AboutUsComponent },
  { path: 'contactus', component: ContactUsComponent },
  { path: 'read',canActivate: [AuthGuard], loadChildren: () => import('./core/modules/read/read.module').then(m => m.ReadModule) },
  { path: 'no-record-found', component: NoRecoredFoundComponent },
  { path: 'tracking', loadChildren: () => import('./core/modules/track/track.module').then(m => m.TrackModule) },
  { path: 'initiative', component: InitiativeComponent },
  { path: 'ForgotPassword', component: ForgotPasswordComponent },
  { path: 'doctor-signup', component: DoctorSignUpComponent },
  { path: 'doctor-connect', component: DoctorConnectComponent },
  { path: 'retailer-signup', component: RetailerSignupComponent },
  { path: 'retailer-connect', component: RetailerConnectComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const myRoutings = [

  SignUpComponent,
  ContinueComponent,
  GETSTARTEDComponent,
  SigninComponent,
  SignSucessComponent,
]