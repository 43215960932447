<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please Wait... </p>
</ngx-spinner>
<div class="body">
    <form #OvulationTrack2="ngForm" class="formExtension text-center">
        <h1>What usually is the Length of your Menstrual Cycle in Days?</h1>
        <p>(The menstrual cycle is the time from the first day of your period to the day before your next period.)</p>
        <input appOnlyNumerics type="text" pattern="[0-9]+" class="input-box"
            [(ngModel)]="MenstrualCycle" maxlength="2" (keyup)="countNumber($event)" #Menstrual="ngModel"
            name="Menstrual" ngModel>
        <div class="text-danger" *ngIf="showMsg">
            <i class="fa-sharp fa-solid fa-circle-exclamation"></i>
            Length of your Menstrual Cycle should not be more than 50 days
        </div>


        <div class="ag-continue">
            <button type="button" class="back"  (click)="goBack()">Previous</button>
            <button type="submit" class="Continue" *ngIf="continueHide" (click)="submit(OvulationTrack2.value)">
                {{button}}</button>
        </div>
    </form>
</div>