<header>
  <div class="ag-nav-menu">

    <div class="ag-menu-items">
      <nav class="navbar navbar-expand-lg">
        <img src="assets/images/close.svg" alt="close" id="mobile-exit" class="mobile-menu-exit" />
        <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
          <!-- <span class="navbar-toggler-icon"></span> -->
          <img src="assets/images/toggle-bar3.png" alt="">
        </button>
        <div class="collapse navbar-collapse ag-mobile-view" [ngClass]="{ 'show': navbarOpen }">
          <div class="ag-nav-toggler ag-right">
            <button class="ag-navbar-toggler menu-d-md-none" type="button" (click)="toggleNavbar()">
              <i class="fa fa-close" aria-hidden="true"></i>
            </button>
          </div>
          <div class="ag-profile">
            <span class="d-flex">
              <img src="assets/images/logo.png" alt="profile_picture">
              <h3>Womean</h3>
            </span>
          </div>

          <div class="ag-user-profile">
            <span class="d-flex">
              <!-- <span class="no-user-icon">
                <img src="assets/images/user-icon.png"   alt="profile_picture">
              </span> -->
              <span class="user-icon" >
                <img [src]="imageUrl" class="user-span-icon" alt="profile_picture">
              </span>
              <h3>
                <span class="user-name">{{username}}</span>
                <!-- <span class="sign-in-btn"><a href="javascript:void(0);"><i aria-hidden="true"
                  class="fa fa-sign-out menu-d-md-none ag-icon-signin"></i> Sign In</a></span> -->
                <span  class="user-contact">
                  <span><i aria-hidden="true" class="fa fa-envelope icon-color menu-d-md-none mr-2"></i>
                    {{email}}</span>
                  <span><i aria-hidden="true" class="fa fa-phone icon-color menu-d-md-none mr-2"></i>{{phone}}</span>
                </span>
              </h3>
            </span>
          </div>

          <ul class="nav-links navbar-nav mr-auto">
            <li *ngFor="let nav of navData">
              <a routerLink="{{ nav.link }}"><i [ngClass]="nav.icons" aria-hidden="true" class="menu-d-md-none mr-4"></i>  {{ nav.label }}</a>
            </li>
            <li class="menu-show-mobile">
              <a href="javascript:void(0);"><i aria-hidden="true"
                  class="fa fa-sign-out menu-d-md-none mr-4"></i> Logout</a>
            </li>
            <!-- <li class="menu-show-mobile">
              <a href="javascript:void(0);"><i aria-hidden="true"
                  class="fa fa-comment menu-d-md-none mr-4"></i> Send Feedback</a>
            </li> -->
          </ul>
          <div class="ag-menu-footer">
            <div class="l-out d-none">
              <button><i class="fa fa-sign-out" aria-hidden="true"></i></button>
            </div>
            <div class="r-rserve">
              <a *ngFor="let mediaLink of socialMediaLinksData" href="{{ mediaLink.link }}" target="_blank"
                class="insta-link"><img src="{{ mediaLink.image }}" alt="{{ mediaLink.alt }}"
                  class="social-media-logo" /></a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</header>