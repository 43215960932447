import { navbarInterface } from "./types";

export const   navData: navbarInterface[] = [
    {
      id: 'homepage',
      label: 'Home Page',
      link: '/homepage',
      icons : 'fa fa-home',
      active : false
    },
    {
      id: 'initiative',
      label: 'The Initiative',
      link: '/initiative',
      icons : 'fa fa-file',
      active : false
    },
    // {
    //   id: 'stats',
    //   label: 'The Statistics',
    //   link: '#'
    // },
    {
      id: 'team',
      label: 'The Team',
      link : '/aboutus',
      // link: '#',
      icons : 'fa fa-people-group',
      active : false
    },
    {
      id: 'Contactus',
      label: 'Contact Us',
      link: '/contactus',
      icons : 'fa fa-phone',
      active : false
    },
  ]