import { Injectable } from "@angular/core";
import { FeatureConstants } from "../../constants/feature-constants";

@Injectable({
    providedIn: 'root',
  })
export class CacheDataService{
    protected cache: Map<string,any>;
    public constructor(){
        console.log("intialized")
        this.cache=new Map<string,any>();
    }
    public set(key:string, value:any):void{
        this.cache.set(key,value);
    }
    public setModule(value:modules):void{
        this.cache.set('module',value);
    }
    public removeModule(): boolean{
        return this.cache.delete('module');
    }
    public getModule():any{
        return this.cache.get('module');
    }
    public get(key: string):any{
        return this.cache.get(key);
    }
    public exists(key:string): boolean{
        return this.cache.has(key) && this.get(key);
    }
    public remove(key: string): boolean{
        return this.cache.delete(key);
    }
    public clear():void{
        return this.cache.clear();
    }
    public clearSortStorage(): void {
        
    }
}