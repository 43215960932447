<div class="center">
    <div class="image">
        <img src="assets\womean_logo_PNG-removebg-preview.png" alt=""height="120" width="120">
    </div>
    <form #submitArticleForm="ngForm" (ngSubmit)="onSubmit(submitArticleForm.value)">
    <div class="text-center">
        <h1>Please Enter the Header of the Article</h1>
        <input type="text" required #header="ngModel" class="input-box" name="header" ngModel><br>
        <div class="text-danger" *ngIf="header.touched && header.invalid">
            <i class="fa-sharp fa-solid fa-circle-exclamation"></i>
            <span> Header of the Article is required</span>
        </div>

        <h2>Select a Category</h2>

        <div class="container">
            <div  class="radio-tile-group">
              <div class="input-container">
                <input id="pregnancy" class="radio-button" onlyAlphabets  type="radio" #category="ngModel" name="category" value="Pregnancy" ngModel/>
                <div class="radio-tile">
                  <label for="pregnancy" class="radio-tile-label">Pregnancy</label>
                </div>
              </div>
          
              <div class="input-container">
                <input id="hygiene" class="radio-button"   type="radio" #category="ngModel" name="category" value="Hygiene" ngModel/>
                <div class="radio-tile">
                    <label for="hygiene" class="radio-tile-label">Hygiene</label>
                </div>
              </div>
          
              <div class="input-container">
                <input id="ovulation" class="radio-button"  type="radio" #category="ngModel" name="category" value="Ovulation" ngModel/>
                <div class="radio-tile">
                  <label for="ovulation" class="radio-tile-label">Ovulation</label>
                </div>
              </div>
          
              <div class="input-container">
                <input id="fitness" class="radio-button"  type="radio" #category="ngModel" name="category" value="Fitness" ngModel/>
                <div class="radio-tile">
                  <label for="fitness" class="radio-tile-label">Fitness</label>
                </div>
              </div>

              <div class="input-container">
                <input id="periods" class="radio-button"  type="radio" #category="ngModel" name="category" value="Periods" ngModel/>
                <div class="radio-tile">
                  <label for="periods" class="radio-tile-label">Periods</label>
                </div>
              </div>
            </div>
          </div>
          <div class="text-danger" *ngIf="category.touched && category.value==''">
            <i class="fa-sharp fa-solid fa-circle-exclamation"></i>
            <span> Select category</span>
        </div>
        <button type="submit" class="btn-select" [disabled]="submitArticleForm.invalid || category.value==''" >Continue</button>
      </div>
    </form>
    <div class="right float-right">
        <p>Womean Inc. 2022 All rights reserved</p>
    </div>
</div>