// import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// import { map } from 'rxjs';
import { RestService } from '../rest.service';
import { EmailUser, PasswodResetUser, ValidateOTP } from '../userCredentials';
import { MatDialog } from '@angular/material/dialog';
import { SuccessModalComponent } from '../modal/success-modal/success-modal.component';
import { ErrorModalComponent } from '../modal/error-modal/error-modal.component';
import { SigninComponent } from '../signin/signin.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { OtpVerificationComponent } from '../otp-verification/otp-verification.component';
import { bounceInRightOnEnterAnimation, bounceInLeftOnEnterAnimation, bounceOutRightOnLeaveAnimation } from 'angular-animations';
@Component({
  selector: 'womean-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  animations: [
    bounceInRightOnEnterAnimation({ anchor: 'enter1' }),
    bounceInRightOnEnterAnimation({ anchor: 'enter2', delay: 100 }),
    bounceInRightOnEnterAnimation({ anchor: 'enter3', delay: 200, animateChildren: 'none' }),
    bounceInLeftOnEnterAnimation(),
    bounceOutRightOnLeaveAnimation(),
  ]
})
export class ForgotPasswordComponent implements OnInit {


  // form declaration
  // public signupForm:FormGroup;
  email: any = "";
  phone: any = "";
  password: any = "";
  confirm_password: any = "";
  passwordCheckStatus = false;
  passwordCheck: any = "";
  // Global variables declaration
  static Email: any;
  static result: any;
  static phone: any;
  hide = true;
  confirm_hide = true;
  button = 'Continue';
  button1 = 'Continue';
  button2 = 'Validate & Continue';
  isLoading = false;
  passwordReset = false
  emailValidate = false
  OTPValidate = false
  validateEmail: any
  public uid: any;
  otp: any = '';
  constructor(public route: Router,
    public dialog: MatDialog,
    private rest: RestService,
    private spinnerService: NgxSpinnerService
    // private formBuilder : FormBuilder
  ) {

  }

  ngOnInit(): void {

  }


  async emailSubmit(emailDetails: any) {
    this.validateEmail = emailDetails.field
    this.email = emailDetails.field;
    //Creating new signup user object
    const user = new EmailUser(this.email);
    if (this.email == "") {
      return
    }
    this.isLoading = true;
    this.button = 'Processing';
    // Function call to hit the create user API 
    let res = await this.rest.onValidateEmail(user);
    console.log(res)
    if (res.status) {
      this.passwordReset = res.data
      if (this.passwordReset) {
        this.isLoading = false;
        this.emailValidate = true;
      }
      else {
        let message = "Invalid Email";
        const dialogRef = this.dialog.open(ErrorModalComponent, { width: '420px', data: message, disableClose: true });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.route.navigate(["/SignIn"]);
          }
        })
      }
    }
  }

  async resetSubmit(passwordDetails: any) {
    this.email = this.validateEmail;
    this.password = passwordDetails.password;
    this.confirm_password = passwordDetails.confirm_password;

    //Creating new signup user object
    const user = new PasswodResetUser(this.email, this.password, this.confirm_password);
    if (this.passwordCheckStatus) {
      this.isLoading = true;
      this.button1 = 'Processing';
      // Function call to hit the create user API 
      let res = await this.rest.onResetPassword(user);
      this.uid = res.uid
      if (res.data) {
        //this.OTPValidate = true
        this.isLoading = false;
        const dialogRef = this.dialog.open(OtpVerificationComponent, { width: '420px',disableClose: true,data:this.uid});
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
           this.OTPSubmit(result);
          }
        })
      }

    }
    else {
      this.isLoading = false;
      //this.button = 'Sign in';
    }
  }


  async OTPSubmit(otpDetails: any) {
    this.otp = otpDetails;
    if (this.otp == "") {
      return
    }
    this.isLoading = true;
    this.button = 'Processing';
    const user = new ValidateOTP(this.uid, this.otp);
    // Function call to hit the signin user API
    let res = await this.rest.onValidateOtp(user);
    console.log(res)
    if (res.data) {
      let message = "password reset successful";
      const dialogRef = this.dialog.open(SuccessModalComponent, { width: '420px', data: message, disableClose: true });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.route.navigate(["/SignIn"]);
        }
      })
    }
    else{
      this.isLoading = false;
      //this.button = 'Validate OTP';
      let message = "Invalid OTP";
      const dialogRef = this.dialog.open(ErrorModalComponent, { width: '420px', data: message, disableClose: true });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialog.open(OtpVerificationComponent, {
            disableClose: true,
            width: '420px',
            autoFocus: false,
            data:this.uid
          }).afterClosed().subscribe((res: any) => {
            if (res) {
              this.OTPSubmit(res)
            }
          });
        }
      })
    }

  }

  async resendOtp() {
    this.spinnerService.show();
    this.otp = "";
    const user = new ValidateOTP(this.uid, this.otp);
    // Function call to hit the signin user API
    let res = await this.rest.onResendOtp(user);
    console.log(res)
    this.spinnerService.hide();
    if (res.status) {
      let message = "OTP sent successfully";
      const dialogRef = this.dialog.open(SuccessModalComponent, { width: '420px', data: message, disableClose: true });
      dialogRef.afterClosed().subscribe(result => {

      })
    }
  }
  // password input validations
  validateInputs(password: string) {
    const containsNumber = /\d/;
    const doesItHaveNumber = containsNumber.test(password);
    if (checkSpaces(password)) {
      this.passwordCheckStatus = false;
      this.passwordCheck = "password should not contain spaces!";
    } else if (!checkSymbol(password)) {
      this.passwordCheckStatus = false;
      this.passwordCheck = "password should contains atleast 1 symbol!";
    } else if (!doesItHaveNumber) {
      this.passwordCheckStatus = false;
      this.passwordCheck = "password should contains atleast 1 number!";
    } else if (!checkUppercase(password) && checkLowercase(password)) {
      this.passwordCheckStatus = false;
      this.passwordCheck = "password should contains atleast 1 Uppercase and 1 Lowercase!";
    } else if (password.length < 8) {
      this.passwordCheckStatus = false;
      this.passwordCheck = "password should contain atleast 8 characters!";
    } else {
      this.passwordCheckStatus = true;
      this.passwordCheck = "";
    }
  }

  clickOnlogo() {
    this.route.navigate(["/homepage"]);
  }
}
// Check for uppercase in the string
function checkUppercase(password: any) {
  for (var i = 0; i < password.length; i++) {
    if (password.charAt(i) == password.charAt(i).toUpperCase() && password.charAt(i).match(/[a-z]/i)) {
      return true;
    }
  }
  return false;
}
// Check for lowercase in the string
function checkLowercase(password: any) {
  for (var i = 0; i < password.length; i++) {
    if (password.charAt(i) == password.charAt(i).toLowerCase() && password.charAt(i).match(/[a-z]/i)) {
      return true;
    }
  }
  return false;
}
// Check for Symbol in the string
function checkSymbol(password: any) {
  var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  if (format.test(password)) {
    return true;
  } else {
    return false;
  }
}
// Check for Spaces in the string
function checkSpaces(password: any) {
  for (var i = 0; i < password.length; i++) {
    if (password.charAt(i) == " ") {
      return true;
    }
  }
  return false;
}


