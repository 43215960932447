<div class="body womean-initiative">

    <app-shared-header [navigationUrls]="navigationUrls" [showSocialMediaLink]="false"></app-shared-header>

    <div class="initiative-top-image">
        <img src="\assets\images\initiative-2.png" alt="">
    </div>

    <div class="container mt-3">
        <!-- <div class="page-heading">The Initiative</div> -->
        <div class="initiative-top">
          <div class="initiative-top-text">
            <div class="page-heading">The Initiative</div>
            <div class="quoted-text">
                <img src="/assets/images/quotation.png" alt="">
                For the windsheilds have always been bigger than the rear view mirrors,
                and where you’re headed holds more importance than what you left behind”
            </div>
          </div>
        </div>
        <!-- <div class="image">
            <img class="img-aboutUs" src="\assets\images\initiative.png" alt="">
        </div>
        <div class="womean-text">
            WOMEAN
        </div> -->
        
        <div class="ag-p-text">
            In the last two decades, digital transformations and technology have contributed immensely <br>
            in breaking stereotypes and established practices to make the world safer, more <br>
            connected and more efficient.
        </div>
        <div class="ag-p-text">
            At <span>Womean</span> we aim to cross hurdles in improving women health & hygiene. We aim to <br>
            break stereotypes, provide ease of access to facilities and information and <br>
            remove taboos around health and hygiene.
        </div>
        <div class="ag-p-text">
            At <span>Womean</span>, we aim to pioneer a ‘One Stop Solution’ for health, hygiene and care for <br>
            women while connecting people to a community for support.
        </div>
        <div class="ag-p-text2">
            The taboos and lack of awareness surrounding the first period, the ovulation cycles and fertility windows,
            the menstrual cycles and their irregularities, the inconsistencies in flows, the pregnancy trimesters or the
            menopause raise concern and anxiety for women more often than widely understood in society.
        </div>
        <div class="ag-p-text5">
            To provide a scalable & sustainable solution to this problem, we summarized the problem and possible reasons
            as follows:
        </div>

        <div class="row">
            <div class="col-md-4">
                <div class="ag-count">
                    <div class="count-num">01</div>
                    <div class="count-text">
                        Lack of knowledge about the <span>body and its functions</span> with respect to the biological
                        clock
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="ag-count">
                    <div class="count-num">02</div>
                    <div class="count-text">
                        Lack of information regarding <span>what is normal and what is not</span> and remedies to
                        counter the irregularities
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="ag-count">
                    <div class="count-num">03</div>
                    <div class="count-text">
                        <span>Dependency on other people</span> for solution and support
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="ag-count">
                    <div class="count-num">04</div>
                    <div class="count-text">
                        Lack of preparedness and <span>supplies for cases of emergencies</span>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="ag-count">
                    <div class="count-num">05</div>
                    <div class="count-text">
                        Not having enough <span>reliable people</span> around to listen, discuss and share experiences.
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="ag-count">
                    <div class="count-num">06</div>
                    <div class="count-text">
                        Lack of Awareness on <span>health, hygiene & lifestyle choices.</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="ag-p-text3">
            <span>Womean</span> at its core is a digital product specifically designed to tackle these issues.
            Through Womean, <br>
            we aim to make the women self sufficient and hence help them connect to a part of a larger community <br>
            supporting and guiding others like them in need.
        </div>

        <div class="ag-p-text4">
            Come join us, and be a part of this solution.
        </div>

        <div class="ag-welcome">
            Welcome to Womean
        </div>

    </div>
    <div class="footer">
        <app-footer></app-footer>
    </div>
</div>