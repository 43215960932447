import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'womean-explore-article',
  templateUrl: './womean-explore-article.component.html',
  styleUrls: ['./womean-explore-article.component.scss']
})
export class WomeanExploreArticleComponent implements OnInit {

  constructor() { }
  @Input() imageUrl='https://source.unsplash.com/user/c_v_r'
  @Input() topic:string='Pregnancy';
  @Input() author:string="Akash deep mitra";
  @Input() heading:string="Lets find out about the content";
  @Input() date:number=1111111;
  ngOnInit(): void {
  }
  navigateTo():void{

  }
}
