
<div class="body">

    <!-- <div class="logo">
        <img
          src="assets\womean_logo_PNG-removebg-preview.png"
          alt=""
          height="130"
          width="130" />
    
        <div class="nav float-right">
          <ul class="nav-links">
            <li><a routerLink="/Homepage">Home Page</a></li>
            <li><a href="#">The Initiative</a></li>
            <li><a href="#">About Us</a></li>
            <li><a href="#">Contact Us</a></li>
          </ul>
          <div class="profile" type="button" routerLink="/Continue">
            <i class="icon fa-solid fa-user"></i>
          </div>
        </div>
    </div> -->
    <app-connect-header [navigationUrls]="navigationUrls" [showSocialMediaLink]="true"></app-connect-header>
    <div class="content">
        <div class="center">
            <h1>Welcome to Our Connect Platform</h1><br>
            <p>When you don't feel like stepping out of your tough days,</p>
            <p>we will provide you everything you might need at your doorstep in just 1 hour</p>
            <img class="image" src="\assets\images\img_Chethascope.png" alt="" width="300" height="auto">
            <h2>Coming soon to your city</h2>
            <h2>Keep supporting us</h2>
        </div>
        <img  class="image2" src="\assets\images\img_femaleDoctor.png" alt="" width="auto" height="300">
        <button class="btn-backhome"  routerLink="/homepage">BACK TO HOME</button>
    </div>
    
   <!-- FOOTER -->
   
  <app-connect-footer></app-connect-footer>
    <!-- <footer>
        <div class="footermenu">
          <div class="footer-grid1">
            COMPANY
            <ul class="grid1-list">
              <li><a href="#">About Womean</a></li>
              <li><a href="#">Media Center</a></li>
              <li><a href="#">Payment Support</a></li>
            </ul>
          </div>
          <div class="footer-grid2">
            OUR POLICIES
            <ul class="grid2-list">
              <li><a href="#">Privacy Policy Policies</a></li>
              <li><a href="#">Terms of Usage</a></li>
              <li><a href="#">User Agreement</a></li>
            </ul>
          </div>
          <div class="footer-grid3">
            OUR PRODUCTS
            <ul class="grid3-list">
              <li><a href="#">Read</a></li>
              <li><a href="#">Track</a></li>
              <li><a href="#">Shop</a></li>
              <li><a href="#">Connect</a></li>
              <li><a href="#">Build</a></li>
            </ul>
          </div>
        </div>
       
        <div class="footmarks">
            <div class="btn-footer">
              <input type="text" id="btn-text" placeholder="Write to us directly" />
              <input type="button" class="btn-send" value="Send" />
            </div>
            <div class="btn-right offset-5">
                <button type="button" class="btn1-admin" routerLink="/AdminLogin">Admin</button>
                <p class="float-right" style="font-size:10pt ;">Womean Inc. 2022 All rights reserved</p>
              </div>
          </div>
        
      </footer> -->
</div>
    
