import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PeriodTrackComponent } from '../period-track/period-track.component';
import { Track } from '../userCredentials';
import { RestService } from '../rest.service';
import { Location } from '@angular/common';
import { PeriodStepsComponent } from '../period-steps/period-steps.component'; 
import { fadeInOnEnterAnimation,fadeInExpandOnEnterAnimation, fadeOutCollapseOnLeaveAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';

@Component({
  selector: 'app-period-track2',
  templateUrl: './period-track2.component.html',
  styleUrls: ['./period-track2.component.scss'],
  animations: [
    fadeInOnEnterAnimation(),
    fadeOutOnLeaveAnimation(),
    fadeInExpandOnEnterAnimation({ duration: 400 }),
    fadeOutCollapseOnLeaveAnimation({ duration: 400 }),
  ]
})
export class PeriodTrack2Component implements OnInit {

  PeriodTrackComponent = PeriodTrackComponent;
  @Output() onClickContinue = new EventEmitter<any>();
  showOptions:boolean;
  moodValue:boolean;
  mood : string;
  showIntenseMood:boolean;
  pmsCount = 0;
  static track:Track;
  countsOfCramps = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  public continueHide = false;
  trackData : any
  constructor(private route : Router,private rest: RestService,private location: Location,) { }
  moodNo(){
    this.showOptions=false;
    this.moodValue = false;
    this.mood = "No";
    this.showIntenseMood = false;
    this.continueHide = true;
    this.pmsCount =0;
  }

  moodYes(){
    this.showOptions=false;
    this.moodValue =true;
    this.mood = "Yes";
    this.showIntenseMood = true;
    this.pmsCount = this.trackData?.data?.pmsSeverity
    if(this.pmsCount) this.continueHide=true;
    else this.continueHide=false;
   
  }

  handleCountCramps(event: Event) {
    const target = event.target as HTMLInputElement
    this.pmsCount = Number(target.value)
    console.log("this.crampsCount : "+this.pmsCount);
    this.continueHide=true;

  }
  ngOnInit(): void {
    this.getEditData()
  }


  getEditData(){
    this.rest.getPeriodTrackEditData().subscribe((res: any) => {
      console.log(res)
      this.trackData = res
      if(Object.keys(this.trackData).length != 0){
        if(this.trackData?.data?.havingPms){
        this.moodYes()
        
        }
        else{
        this.moodNo()
        this.editIfnotOnPeriod(this.trackData)
        }
      }
    })

  }
  editIfnotOnPeriod(res : any){
      if(res.data.havingPms){
        this.pmsCount = res.data.pmsSeverity
        this.continueHide=true;
      }
    
  }
  onSubmit(details:any){
    PeriodTrack2Component.track = PeriodTrackComponent.trackDetails;
    PeriodTrack2Component.track.having_Pms =this.moodValue;
    PeriodTrack2Component.track.pms_Severity= this.pmsCount //details.flexRadioDefault;
    console.log("track in 2nd page : "+JSON.stringify(PeriodTrack2Component.track));
    this.onClickContinue.emit('period-track3')
    //this.route.navigate(["/period-track3"]);
  }

  onContinue(){
    let data ={data : 'period-track2'}
    this.onClickContinue.emit('period-track2')
  }

  goBack(){
    this.onClickContinue.emit('back')
  }
}
