import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { JWTTokenService } from "../utils/jwt-token.service";

@Injectable({
    providedIn: 'root',
  })
export class UserPrefernceService{

    constructor(private router: Router) {}

    private authorizationKey: string='Authorization'; 
    public getCurrentUserName(): string {
        return JWTTokenService.parseJWT(this.getToken()).name;
    }
    public getCurrentUserEmailId(): string {
        return JWTTokenService.parseJWT(this.getToken()).email;
    }
    public getCurrentUserJti(): string {
        return JWTTokenService.parseJWT(this.getToken()).jti;
    }
    public getCurrentUserRole(): string {
        return JWTTokenService.parseJWT(this.getToken()).role;
    }
    public setToken(token: string): void {
        localStorage.setItem(this.authorizationKey, token);
    }
    public getToken(): string{
        return localStorage.getItem(this.authorizationKey);
    }
    public removeToken(): void { 
        localStorage.removeItem(this.authorizationKey);
        this.router.navigate(['/']);
    }
}