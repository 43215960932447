<div class="body center">

    <!-- <div class="logo">
        <img src="assets\womean_logo_PNG-removebg-preview.png" alt=""height="130" width="130">
        
        <div class="nav float-right">
            <ul class="nav-links">
                <li><a href="#" routerLink="/Homepage">Home Page</a></li>
                <li><a href="#">The Initiative</a></li>
                <li><a href="#">The Team</a></li>
                <li><a href="#">Contact Us</a></li>
            </ul>
            <button style="border: none; background :none;" class=" profile" type="button" routerLink="/Continue">
                <i class="profile-logo icon fa-solid fa-user"></i>   
                <div class="myProfile">My Profile</div>
            </button>
            
        </div>
    </div> -->
    <app-shared-header [navigationUrls]="navigationUrls" [showSocialMediaLink]="false"></app-shared-header>
    <div class="gs-bg">
        <div class="greeting">
            <h1>Hey there!</h1>
            <h1>{{username}}</h1>
        </div>
        <div class="text-center">
            <div class="ag-680-img">
                <img src="assets/images/getstarted-img.png" alt="">
            </div>
            <h2>Let us help you be regular at <br>
                tracking your cycles</h2>
            <button type="submit" class="Getstarted" (click)="getStarted()">GET STARTED</button>
        </div>
    </div>
    <div class="down">
        <app-footer></app-footer>
    </div>
</div>