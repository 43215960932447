<div class="ag-sm-container">

    <div class="left">
        <span class="ag-signin-logo">
            <img src="/assets/images/logo.png" class="logo">
            <h2>Help us set you up</h2>
            <!-- <div class="user-icon">
                <img src="/assets/images/user-icon2.png">
            </div> -->
        </span>

        <div class="ag-stepper">
            <ul>
                <li  [ngClass]="personalDetails?'disabled' : 'active'">
                    <div class="list-icon">
                        <div class="list-img"><img src="/assets/images/personal-details.png" class="logo"></div>
                    </div>
                    <div class="list-stage-name">
                        <h5>Personal Details</h5>
                        <span class="progress-done">Done</span>
                    </div>
                    <div class="list-status-success">
                        <i class="fa fa-check"></i>
                    </div>
                </li>
                <li [ngClass]="!personalDetails?'disabled' : 'active'">
                    <div class="list-icon">
                        <div class="list-img"><img src="/assets/images/academic.png" class="logo"></div>
                    </div>
                    <div class="list-stage-name">
                        <h5>Education & Specialization</h5>
                        <span class="progress-pending">Pending</span>
                    </div>
                    <div class="list-status-gray">
                        <i class="fa fa-check"></i>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="right">
        <div class="ag-scroll-manage">

            <div class="ag-progress">
                <div class="ag-progress-bar">
                    <div class="ag-progress-text">
                        <span class="status-label"><i class="fa fa-check"></i></span>
                        <span class="persentage">100%</span>
                        <span class="stage">Profile Complete</span>
                    </div>
                    <div class="ag-progress-sec">
                        <mat-progress-bar mode="determinate" value="40"></mat-progress-bar>
                    </div>
                </div>
                <h1>Personal Details</h1>
            </div>
            <div [@bounceInLeftOnEnter] [@bounceOutRightOnLeave]>

                <div class="ag-form" *ngIf="!personalDetails">
                    <form name="personalForm" [formGroup]="personalForm" (ngSubmit)="onSubmit()" class="">
                        <div class="ag-form">
                            <div class="row g-3 mb-4">
                                <div class="col-md-7">
                                    <div class="row g-3 align-items-center">
                                        <!-- <div class="col-md-4">
                                        <label class="col-form-label">Name</label>
                                    </div> -->
                                        <div class="col-md-12">
                                            <label class="col-form-label">First Name</label>
                                            <input id="Firstname" type="text" formControlName="Firstname" onlyAlphabets
                                                class="form-control" placeholder="First Name" name="Firstname" required>
                                        </div>
                                        <div class="row align-items-center justify-content-end"
                                            *ngIf="submitted && !personalForm.get('Firstname')?.valid">
                                            <div class="col-md-12">
                                                <div class="danger">
                                                    <span
                                                        *ngIf="submitted && !personalForm.get('Firstname')?.valid">Enter
                                                        your first name</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <label class="col-form-label">Middle Name</label>
                                            <input id="Middlename" type="text" class="form-control" onlyAlphabets
                                                formControlName="Middlename" placeholder="Middle Name"
                                                name="Middlename">
                                        </div>

                                        <div class="col-md-12">
                                            <label class="col-form-label">Last Name</label>
                                            <input id="Lastname" type="text" class="form-control" onlyAlphabets
                                                formControlName="Lastname" placeholder="Last Name" name="Lastname"
                                                required>
                                        </div>

                                        <div class="row align-items-center justify-content-end"
                                            *ngIf="submitted && !personalForm.get('Lastname')?.valid">
                                            <div class="col-md-12">
                                                <div class="danger">
                                                    <span
                                                        *ngIf="submitted && !personalForm.get('Lastname')?.valid">Enter
                                                        your last name</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row gx-5 align-items-center">
                                        <div class="col-md-7">
                                            <label class="col-form-label">Date Of Birth</label>
                                            <input id="" type="date" class="form-control" placeholder="Date Of Birth"
                                                formControlName="DateOfBirth" required>
                                            <div class="danger"><span
                                                    *ngIf="submitted && !personalForm.get('DateOfBirth')?.valid">Enter
                                                    your date of birth</span></div>
                                        </div>
                                        <div class="col-md-5">
                                            <label class="col-form-label w-100">Gender</label>
                                            <span class="image-r-btn">
                                                <label class="img-btn">
                                                    <input type="radio" name="gender-flags" checked id="female"
                                                        value="female" name="gender" formControlName="gender">
                                                    <img src="/assets/images/gender-f.png" alt="Female">
                                                </label>

                                                <span class="r-label">Female</span>
                                            </span>
                                            <span class="image-r-btn">
                                                <label class="img-btn">
                                                    <input type="radio" name="gender-flags" id="male" value="male"
                                                        name="gender" formControlName="gender">
                                                    <img src="/assets/images/gender-m.png" alt="Female">
                                                </label>

                                                <span class="r-label">Male</span>
                                            </span>
                                            <div class="danger"><span
                                                    *ngIf="submitted && !personalForm.get('gender')?.valid">select your
                                                    gender</span></div>
                                        </div>
                                    </div>

                                    <div class="row gx-5 mb-4 align-items-end">
                                        <div class="col-md-7">
                                            <label class="col-form-label">State</label>
                                            <select id="state" class="form-control" formControlName="state"
                                                placeholder="Select State">
                                                <option value="Andhra Pradesh">Andhra Pradesh</option>
                                                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                                <option value="Assam">Assam</option>
                                                <option value="Bihar">Bihar</option>
                                                <option value="Chhattisgarh">Chhattisgarh</option>
                                                <option value="Goa">Goa</option>
                                                <option value="Gujarat">Gujarat</option>
                                                <option value="Haryana">Haryana</option>
                                                <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                                <option value="Jharkhand">Jharkhand</option>
                                                <option value="Karnataka">Karnataka</option>
                                                <option value="Kerala">Kerala</option>
                                                <option value="Madhya Pradesh">Madhya Pradesh</option>
                                                <option value="Maharashtra">Maharashtra</option>
                                                <option value="Manipur">Manipur</option>
                                                <option value="Meghalaya">Meghalaya</option>
                                                <option value="Mizoram">Mizoram</option>
                                                <option value="Nagaland">Nagaland</option>
                                                <option value="Odisha">Odisha</option>
                                                <option value="Punjab">Punjab</option>
                                                <option value="Rajasthan">Rajasthan</option>
                                                <option value="Sikkim">Sikkim</option>
                                                <option value="Tamil Nadu">Tamil Nadu</option>
                                                <option value="Telangana">Telangana</option>
                                                <option value="Tripura">Tripura</option>
                                                <option value="Uttar Pradesh">Uttar Pradesh</option>
                                                <option value="Uttarakhand">Uttarakhand</option>
                                                <option value="West Bengal">West Bengal</option>
                                                <option value="Others">others</option>
                                            </select>
                                            <div class="danger"><span  *ngIf="submitted && !personalForm.get('state')?.valid">Select your state</span></div>
                                        </div>
                                        <div class="col-md-5">
                                            <button type="submit" class="Signin"><i
                                                [ngClass]="{'fa fa-spinner fa-spin': isLoading}"></i>&nbsp;{{button}}</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="row g-3 align-items-center">
                                        <div class="row justify-content-center">
                                            <div class="col-md-12 ag-image-upload">
                                                <div class="circle">
                                                    <img class="profile-pic" src="/assets/images/user-icon2.png">
                                                </div>
                                                <div class="p-image">
                                                    <i class="fa fa-camera upload-button"></i> Upload Profile Image
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="ag-form" *ngIf="personalDetails">
                <div class="ag-form">
                    <!-- <h1>Education & Specialization</h1> -->
                    <form [formGroup]="EducationformDataTable">
                        <div class="row g-3 mb-4">
                            <div class="col-md-3">
                                <label class="col-form-label">Course</label>
                                <input id="Course" type="text" class="form-control" formControlName="Course"
                                    placeholder="Course" required>
                                <div class="danger"><span
                                        *ngIf="issubmitted && !EducationformDataTable.get('Course')?.valid">Enter your
                                        course</span></div>
                            </div>
                            <div class="col-md-2">
                                <label class="col-form-label">Year</label>
                                <input id="Year" type="text" class="form-control" formControlName="Year" appOnlyNumerics  pattern="[0-9]+"
                                    placeholder="Year" required>
                                <div class="danger"><span
                                        *ngIf="issubmitted && !EducationformDataTable.get('Year')?.valid">Enter your
                                        year</span></div>
                            </div>
                            <div class="col-md-3">
                                <label class="col-form-label">College Name</label>
                                <input id="CollegeName" type="text" class="form-control" formControlName="CollegeName"
                                    placeholder="College Name" required>
                                <div class="danger"><span
                                        *ngIf="issubmitted && !EducationformDataTable.get('CollegeName')?.valid">Enter
                                        your college name</span></div>
                            </div>
                            <div class="col-md-4">
                                <label class="col-form-label">Certificate</label>
                                <div class="row align-items-center">
                                    <div class="col-md-10">
                                        <!-- <input id="" type="file" required multiple> -->
                                        <div class="custom-file">
                                            <a href="javascript:void:(0);" class="ag-plus-i" type="file" (click)="openInput()">
                                                <input id="fileInput" hidden class="custom-file-input" type="file"  accept=".pdf"
                                                (change)="selectFile($event)" />
                                            <label class="custom-file-label" for="inputGroupFile01"> {{Filename}}</label></a>
                                        </div>
                                    </div>
                                    <div class="col-md-2 d-none-992">
                                        <span class="add-certificate" (click)="addTable()"><i class="fa fa-plus-circle"
                                                aria-hidden="true"></i></span>
                                    </div>
                                </div>
                                <div class="danger"><span *ngIf="issubmitted && !EducationformDataTable.get('InputFile')?.valid">Upload your certificate</span></div>
                            </div>

                            <div class="d-add">
                                <button class="ag-add" (click)="addTable()">Add</button>
                            </div>
                        </div>
                    </form>
                    <div class="border-outline mb-4">
                        <div class="">
                            <div class="table-responsive">
                                <!-- <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Course</th>
                                                <th>Year</th>
                                                <th>College Name</th>
                                                <th>Certificate</th>
                                            </tr>
                                        </thead>
                                    </table> -->
                                <table mat-table [dataSource]="dataSource" class="w-100">

                                    <!--- Note that these columns can be defined in any order.
                                              The actual rendered columns are set as a property on the row definition" -->

                                    <!-- Position Column -->
                                    <ng-container matColumnDef="position">
                                        <th mat-header-cell *matHeaderCellDef class="text-center"> Sl No </th>
                                        <td mat-cell *matCellDef="let element;let i = index;" class="text-center">
                                            {{i+1}} </td>
                                    </ng-container>

                                    <!-- Name Column -->
                                    <ng-container matColumnDef="course">
                                        <th mat-header-cell *matHeaderCellDef> Course </th>
                                        <td mat-cell *matCellDef="let element"> {{element.course}} </td>
                                    </ng-container>

                                    <!-- Weight Column -->
                                    <ng-container matColumnDef="year">
                                        <th mat-header-cell *matHeaderCellDef> Year </th>
                                        <td mat-cell *matCellDef="let element"> {{element.year}} </td>
                                    </ng-container>

                                    <!-- Weight Column -->
                                    <ng-container matColumnDef="collegename">
                                        <th mat-header-cell *matHeaderCellDef> College Name </th>
                                        <td mat-cell *matCellDef="let element"> {{element.collegename}} </td>
                                    </ng-container>

                                    <!-- Symbol Column -->
                                    <ng-container matColumnDef="certificate">
                                            <th mat-header-cell *matHeaderCellDef> Certificate </th>
                                            <td mat-cell *matCellDef="let element"> {{element.certificate}} </td>
                                        </ng-container>

                                    <!-- Symbol Column -->
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef> Action </th>
                                        <td mat-cell *matCellDef="let element">
                                            <button class="ag-btn-delete" (click)="deleteFromTable(element)"><i
                                                    class="fa fa-trash"></i></button>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                </table>


                            </div>
                        </div>
                    </div>
                    <form name="personalForm" [formGroup]="specializationform" (ngSubmit)="onSubmitSpecial()" class="">
                    <div class="row g-3 mb-4 align-items-center">
                        <div class="col-md-3">
                            <label class="col-form-label">Preferred Language</label>
                            <input id="pLanguage" type="text" class="form-control" placeholder="Preferred Language"
                                required formControlName="pLanguage">
                            <div class="danger"><span *ngIf="IsSubmit && !specializationform.get('pLanguage')?.valid">Enter your preferred language</span></div>
                        </div>
                        <div class="col-md-3">
                            <label class="col-form-label">IMC ID</label>
                            <input id="imcId" type="text" class="form-control" placeholder="IMC ID" required formControlName="imcId">
                            <div class="danger"><span *ngIf="IsSubmit && !specializationform.get('imcId')?.valid">Enter your IMC ID</span></div>
                        </div>
                        <div class="col-md-3">
                            <label class="col-form-label">Specialities</label>
                            <input id="speciality" type="text" class="form-control" placeholder="Specialities" required formControlName="speciality">
                            <div class="danger"><span *ngIf="IsSubmit && !specializationform.get('speciality')?.valid">Enter your specialities</span></div>
                        </div>
                        <div class="col-md-3">
                            <label class="col-form-label">Years of Clinical Experience</label>
                            <input id="yearofExperience" type="text" class="form-control" placeholder="Years of Clinical Experience"
                                required formControlName="yearofExperience">
                            <div class="danger"><span *ngIf="IsSubmit && !specializationform.get('yearofExperience')?.valid">Enter your years of clinical experience</span></div>
                        </div>
                    </div>
                    </form>
                </div>

                <div class="text-center">
                    <button type="submit" class="Signin"><i
                        [ngClass]="{'fa fa-spinner fa-spin': Loading}"></i>&nbsp;{{finalSavebutton}}</button>
                </div>

            </div>

            <!-- <div class="text-center">
                <span class="haveAccount">Already have an account? <a href="javascript:void(0);">Sign
                        In</a></span>
            </div> -->
        </div>



    </div>

</div>