<div class="center">
    <div class="image">
        <img src="assets\womean_logo_PNG-removebg-preview.png" alt=""height="120" width="120">
    </div>
    <div  class="text-center">
        <button id="Signin" type="submit" class="btn-select" routerLink="/AdminConsole3">Submit Article</button>
        <button id="Signin" type="submit" class="btn-select">Support Queries</button>
        <button id="Signin" type="submit" class="btn-select">Profile Admin</button>
    </div>
    <div class="right float-right">
        <p>Womean Inc. 2022 All rights reserved</p>
    </div>
</div>
