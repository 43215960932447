<div class="ag-success">
    <div class="modal-header justify-content-center">
        <div class="icon-box">
            <i class="material-icons"></i>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCancel(true)">×</button>
    </div>
    <div class="modal-body text-center">
        <h4>Awsome!</h4>	
        <p>{{data}}</p>
        <div class="d-flex justify-content-center">
            <button class="btn btn-confirm" data-dismiss="modal" (click)="onCancel(true)"><span>Start Exploring</span> <i class="fa fa-arrow-right ml-1"></i></button>
        </div>
    </div>
</div>
