<div class="body">
  <div>
    <form #PeriodDetails="ngForm" class="formExtension">
      <h1  [@fadeInOnEnter] [@fadeOutOnLeave]>Are you on your Periods?</h1>
      <div class="input-container text-center">
        <div (click)="pP=true"  [@fadeInOnEnter] [@fadeOutOnLeave]>
          <button type="button" class="input-box icon-select" value="">{{periods}}</button>
          <!-- <span class="icon2 fa-solid fa-caret-down ag-pointer"></span> -->
        </div>
        <div class="radio-tile-group" *ngIf="pP" [@fadeInOnEnter] [@fadeOutOnLeave]>
          <div class="input-container">
            <button class="input-box" type="button" (click)="periodsSaveYes()">Yes</button>
          </div>
          <div class="input-container">
            <button class="input-box" type="button" (click)="periodsSaveNo()">No</button>
          </div>
        </div>
        <!-- if periods is No -->
        <div *ngIf="showPeriodStartedDate"  [@fadeInOnEnter] [@fadeOutOnLeave]>
          <h1>Enter your last period start date</h1>
          <div (click)="handleInputStartDate()">
            <input type="text" value="{{ selectedRangeValue?.start | date: 'dd-MM-yyyy' }}" [(ngModel)]="start_date"
              class="input-box" placeholder="DD-MM-YYYY" name="startDate" #startDate="ngModel" ngModel readonly  ngModel (click)="displayBox()" />
            <span class="ag-date ag-pointer" (click)="displayBox()"></span>
          </div>
          <div *ngIf="showPeriodStartedDate && selectedRangeValue?.start">
            <h1>Enter your last period end date</h1>
            <input type="text" value="{{ selectedRangeValue?.end | date: 'dd-MM-yyyy' }}" [(ngModel)]="end_date"
              class="input-box" placeholder="DD-MM-YYYY" [defaultValue]="99-99-9999" name="endDate" #endDate="ngModel"
              ngModel readonly ngModel (click)="displayBox()" />
            <span class="ag-date ag-pointer" (click)="displayBox()"></span>
          </div>
        </div>
        <!-- if periods is Yes -->
        <!-- <div *ngIf="showPeriodDays"  [@fadeInOnEnter] [@fadeOutOnLeave]>
          <h1>Enter your Day Of Period</h1>
          <input type="text" class="input-box" pattern="[0-9]+" value="" name="dayOfPeriod" [(ngModel)]="day_Period"
            (keyup)="countNumber($event)" (change)="periodDays(dayOfPeriod.valid)" #dayOfPeriod="ngModel" ngModel>
          <div class="text-danger" *ngIf="dayOfPeriod.touched && dayOfPeriod.invalid">
            <i class="fa-sharp fa-solid fa-circle-exclamation"></i>
            <span *ngIf="dayOfPeriod.value==''"> Day of Period is required</span>
            <span *ngIf="dayOfPeriod.hasError('pattern')"> Length of your Menstrual Cycle should be in numerics</span>
          </div>
        </div> -->
        <div *ngIf="showPeriodDays"  [@fadeInOnEnter] [@fadeOutOnLeave]>
          <h1>Enter your Day Of Period</h1>
          <input type="text" class="input-box" appOnlyNumerics value="" name="dayOfPeriod" [(ngModel)]="day_Period"
            (keyup)="countNumber($event)"  #dayOfPeriod="ngModel" ngModel>
          <div class="text-danger" *ngIf="showMsg">
            <i class="fa-sharp fa-solid fa-circle-exclamation"></i>
           Length of your Menstrual Cycle should be 1-7
          </div>
        </div>
        <div class="modal" id="model_" style="display:none;">
            <div class="modal-content">
                <h1>Enter your last period <br> (start date - end date)</h1>
                <div class="mat-calendar-wrapper ag-calendar-wrapper">
                  <womean-material-calendar  [@fadeInOnEnter] [@fadeOutOnLeave] *ngIf="showCalendar && showPeriodStartedDate" [selected]="selectedRangeValue"
                  (selectedChange)="selectedChange($event)" [type]="'Range'"></womean-material-calendar>
                </div>
                <div class="close">
                    <button (click)="closeBox()">Done <i class="fa fa-angle-right ml-1"></i></button>
                </div>
            </div>
        </div>

        <!-- <div class="d-flex justify-content-center">
          <div class="calendar">
            <div class="mat-calendar-wrapper ag-calendar-wrapper">
              <womean-material-calendar *ngIf="showCalendar && showPeriodStartedDate" [selected]="selectedRangeValue"
                (selectedChange)="selectedChange($event)" [type]="'Range'"></womean-material-calendar>
            </div>
          </div>
        </div> -->
        <div *ngIf="end!=null || showMenstual ||Menstual"  >
          <h1>Do you Usually Experience Menstual Cramps?</h1>
          <div class="select">
            <div (click)="pR=true" [@fadeInOnEnter] [@fadeOutOnLeave]>
              <button type="button" class="input-box icon-select" value="">{{Menstual}}</button>
              <!-- <span class="icon2 fa-solid fa-caret-down ag-pointer"></span> -->
            </div>
            <div class="radio-tile-group" *ngIf="pR"  [@fadeInOnEnter] [@fadeOutOnLeave]>
              <div class="input-container">
                <button class="input-box" type="button" (click)="menstualSaveYes()">Yes</button>
              </div>
              <div class="input-container">
                <button class="input-box" type="button" (click)="menstualSaveNo()">No</button>
              </div>
            </div>
            <div *ngIf="showCrampsCount"  [@fadeInOnEnter] [@fadeOutOnLeave]>
              <h1>How painful are your cramps?</h1>
              <div class="d-flex">
                <p class="p-text3">Mild</p>
                <p class="p-text4">Severe</p>
              </div>
              <div class="d-flex justify-content-center" [@fadeInOnEnter] [@fadeOutOnLeave]>
                <p class="p-text">Mild</p>
                <div class="ag-rating">
                  <div class="form-check ag-check" *ngFor="let count of countsOfCramps">
                    <input class="form-check-input ag-check-input" type="radio" [checked]="count == crampsCount"
                      [value]="count" (change)="handleCountCramps($event)">
                  </div>
                </div>
                <p class="p-text2">Severe</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="ag-continue" >
    <!-- <button type="button" class="back"  (click)="goBack()"><i class="icon1 fa-sharp fa-solid fa-play"></i> Go
      Back</button> -->
    <button type="submit" class="Continue" *ngIf="continueHide" [@fadeInOnEnter] [@fadeOutOnLeave] [disabled]="showMsg"
      (click)="onSubmit(PeriodDetails.value)">Continue</button>
  </div>
</div>