<div class="body womean-contact-us">

  <app-shared-header [navigationUrls]="navigationUrls" [showSocialMediaLink]="false"></app-shared-header>


  <div class="contact-top-image">
    <img src="\assets\images\CONTACT US.png" alt="">
  </div>

  <div class="container">
    <div class="contact-top">
      <div class="contact-top-text">
        <div class="page-heading">Contact Us</div>
        <div class="page-heading-text">Let’s chat over a cup of coffee. <br>
          How about that?</div>
      </div>
    </div>

    <div class="support-div">
      <div class="row">
        <div class="col-md-6 d-flex support-content-start">
          <div class="support">
            <h4>Product Support</h4>
            <span><a href="mailto:support@womean.org">E-mail : support@womean.org</a></span>
            <span><a href="tel:+91-8072183936">Phone : +91-8072183936</a></span>
          </div>
        </div>
        <div class="col-md-6 d-flex support-content-end">
          <div class="support">
            <h4>Technical Support</h4>
            <span><a href="mailto:techteam@womean.org">E-mail : techteam@womean.org</a></span>
            <span><a href="tel:+91-6398516123">Phone : +91-6398516123</a></span>
          </div>
        </div>
      </div>

      <div class="row m-t-50">
        <div class="col-md-12 d-flex justify-content-center">
          <div class="support2">
            <h4>Get in touch with our sales and marketing team</h4>
            <span><a href="mailto:support@womean.org">E-mail : support@womean.org</a></span>
            <span><a href="tel:+91-8072183936">Phone : +91-8072183936</a></span>
          </div>
        </div>
      </div>
    </div>

    <div class="contact-bottom">
      <div class="row">
        <div class="col-md-6">
          <div class="contact-bottom-left">
            <h4 class="left-text">What we offer when you ask a question?</h4>

            <p>
              We listen while you speak. <br>
              Less than 24 hrs resolution to your concern/query. <br>
              We believe in customer satisfaction and your happiness makes us happy. <br>
              Plan of action along with the turn-around time summarized in an email. <br>
              Regular follow ups till we deliver and make a smile on your face.
            </p>

            <hr>

            <div class="follow-us">
              Follow Us
            </div>

            <div class="social-icons">
              <a *ngFor="let mediaLink of socialMediaLinksData" href="{{ mediaLink.link }}" target="_blank"><img
                  src="{{ mediaLink.image }}" alt="{{ mediaLink.alt }}" /></a>
              <!-- <a href="javascript:void(0);" target="_blank"><img src="assets/images/fb.png" alt=""></a>
              <a href="javascript:void(0);" target="_blank"><img src="assets/images/tw.png" alt=""></a>
              <a href="javascript:void(0);" target="_blank"><img src="assets/images/insta.png" alt=""></a> -->
            </div>

          </div>
        </div>
        <div class="col-md-6">
          <div class="contact-bottom-right">
            <h4 class="right-text">DROP US A QUERY</h4>
            <form #raiseQuery="ngForm" (ngSubmit)="onSubmit(raiseQuery.value)" class="input-fields">
              <input type="email" required email class="input-box" placeholder="Email*" name="email" #email="ngModel"
                ngModel><br>
              <div class="text-danger" *ngIf="email.touched && email.invalid">
                <i class="fa-sharp fa-solid fa-circle-exclamation"></i>
                <span> Email is required</span>
              </div><br>
              <input appOnlyNumerics type="text" pattern="[0-9]+" required contact class="input-box" minlength="10" maxlength="10"
                placeholder="Contact Number*" name="contact" #contact="ngModel" ngModel>
              <div class="text-danger" *ngIf="contact.touched && contact.invalid">
                <i class="fa-sharp fa-solid fa-circle-exclamation"></i>
                <span> Contact Number is required</span>
              </div>
              <textarea type="text" class="input-text-area" placeholder="Your Query Here" name="query" #query="ngModel"
                ngModel></textarea>
              <button [attr.disabled]="!raiseQuery.valid ? true : null" type="submit" class="btn-send2">SEND</button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>
  
  <div class="footer">
    <app-footer></app-footer>
  </div>
</div>