export class AdminDetails1{


  private id: string;
  private email: string;
  private content: string;
  private parentChain: string;
  private imageURLs: string;
  private createdOn: number;
  private lastUpdatedOn: number;
  private header: string;
  private author: string;


    /**
     * Getter id
     * @return {string}
     */
	public getId(): string {
		return this.id;
	}

    /**
     * Getter email
     * @return {string}
     */
	public getEmail(): string {
		return this.email;
	}

    /**
     * Getter content
     * @return {string}
     */
	public getContent(): string {
		return this.content;
	}

    /**
     * Getter parentChain
     * @return {string}
     */
	public getParentChain(): string {
		return this.parentChain;
	}

    /**
     * Getter imageURLs
     * @return {string}
     */
	public getImageURLs(): string {
		return this.imageURLs;
	}

    /**
     * Getter createdOn
     * @return {number}
     */
	public getCreatedOn(): number {
		return this.createdOn;
	}

    /**
     * Getter lastUpdatedOn
     * @return {number}
     */
	public getLastUpdatedOn(): number {
		return this.lastUpdatedOn;
	}

    /**
     * Getter header
     * @return {string}
     */
	public getHeader(): string {
		return this.header;
	}

    /**
     * Getter author
     * @return {string}
     */
	public getAuthor(): string {
		return this.author;
	}

    /**
     * Setter id
     * @param {string} value
     */
	public setId(value: string) {
		this.id = value;
	}

    /**
     * Setter email
     * @param {string} value
     */
	public setEmail(value: string) {
		this.email = value;
	}

    /**
     * Setter content
     * @param {string} value
     */
	public setContent(value: string) {
		this.content = value;
	}

    /**
     * Setter parentChain
     * @param {string} value
     */
	public setParentChain(value: string) {
		this.parentChain = value;
	}

    /**
     * Setter imageURLs
     * @param {string} value
     */
	public setImageURLs(value: string) {
		this.imageURLs = value;
	}

    /**
     * Setter createdOn
     * @param {number} value
     */
	public setCreatedOn(value: number) {
		this.createdOn = value;
	}

    /**
     * Setter lastUpdatedOn
     * @param {number} value
     */
	public setLastUpdatedOn(value: number) {
		this.lastUpdatedOn = value;
	}

    /**
     * Setter header
     * @param {string} value
     */
	public setHeader(value: string) {
		this.header = value;
	}

    /**
     * Setter author
     * @param {string} value
     */
	public setAuthor(value: string) {
		this.author = value;
	}



}