import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { RestService } from '../rest.service';
import { SigninComponent } from '../signin/signin.component';
import { SigninResponse, User } from '../userCredentials';
import jwt_decode from 'jwt-decode';
@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent implements OnInit {
  private email: any;
  private Password: any;
  adminFailure: boolean;
  static jwtResponse: any;

  constructor(private route: Router, private rest: RestService) { }

  ngOnInit(): any {
  }

  async onSubmit(loginDetails: any) {
    this.email = loginDetails.admin_Email;
    this.Password = loginDetails.admin_Password;
    console.log("emai and password" + this.email + " : " + this.Password);
    const user = new User(this.email, this.Password);
    const res = await this.rest.onSignin(user);
    const res1 = JSON.stringify(res);

    console.log("rsult" + res1);


    let token: string = res.data;
    let jwtResponse = this.getDecodedAccessToken(token);
    console.log(jwtResponse);
    if (jwtResponse != null) {
      console.log("jwtResponse.role" + jwtResponse.role);
      if (jwtResponse.role == "ADMIN") {
        AdminLoginComponent.jwtResponse = jwtResponse;
        this.route.navigate(["/AdminConsole2"]);
      } else {
        this.adminFailure = true;
      }
    }

  }
  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }
}

