<div class="ag-sm-container">

    <div class="left">
        <span class="ag-signin-logo">
            <img src="/assets/images/logo.png" class="logo">
            <h2>Help us set you up</h2>
            <!-- <div class="user-icon">
                <img src="/assets/images/user-icon2.png">
            </div> -->
        </span>

        <div class="ag-stepper">
            <ul>
                <li class="active">
                    <div class="list-icon">
                        <div class="list-img"><img src="/assets/images/personal-details.png" class="logo"></div>
                    </div>
                    <div class="list-stage-name">
                        <h5>Seller Information</h5>
                        <span class="progress-done">Done</span>
                    </div>
                    <div class="list-status-success">
                        <i class="fa fa-check"></i>
                    </div>
                </li>
                <li>
                    <div class="list-icon">
                        <div class="list-img"><img src="/assets/images/tax.png" class="logo"></div>
                    </div>
                    <div class="list-stage-name">
                        <h5>Tax Details</h5>
                        <span class="progress-pending">Pending</span>
                    </div>
                    <div class="list-status-gray">
                        <i class="fa fa-check"></i>
                    </div>
                </li>
                <li>
                    <div class="list-icon">
                        <div class="list-img"><img src="/assets/images/shop-bag.png" class="logo"></div>
                    </div>
                    <div class="list-stage-name">
                        <h5>Seller Interview</h5>
                        <span class="progress-pending">Pending</span>
                    </div>
                    <div class="list-status-gray">
                        <i class="fa fa-check"></i>
                    </div>
                </li>
                <li>
                    <div class="list-icon">
                        <div class="list-img"><img src="/assets/images/tag.png" class="logo"></div>
                    </div>
                    <div class="list-stage-name">
                        <h5>Bank Details</h5>
                        <span class="progress-pending">Pending</span>
                    </div>
                    <div class="list-status-gray">
                        <i class="fa fa-check"></i>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="right">
        <div class="ag-scroll-manage">

            <div class="ag-progress">
                <div class="ag-progress-bar">
                    <div class="ag-progress-text">
                        <span class="status-label"><i class="fa fa-check"></i></span>
                        <span class="persentage">100%</span>
                        <span class="stage">Profile Complete</span>
                    </div>
                    <div class="ag-progress-sec">
                        <mat-progress-bar mode="determinate" value="40"></mat-progress-bar>
                    </div>
                </div>
                <h1>Personal Details</h1>
            </div>
            <!-- <div [@bounceInLeftOnEnter] [@bounceOutRightOnLeave]>
                <form name="signupForm" #signupForm="ngForm" class="">
                    <div class="ag-form">

                        <div class="row g-3 mb-4">
                            <div class="col-md-7">

                                <div class="row g-3 align-items-center">
                                    <div class="col-md-12">
                                        <label class="col-form-label">First Name</label>
                                        <input id="" type="text" class="form-control" placeholder="First Name" name=""
                                            required>
                                    </div>
                                    <div class="col-md-12">
                                        <label class="col-form-label">Middle Name</label>
                                        <input id="" type="text" class="form-control" placeholder="Middle Name" name=""
                                            required>
                                    </div>
                                    <div class="col-md-12">
                                        <label class="col-form-label">Last Name</label>
                                        <input id="" type="text" class="form-control" placeholder="Last Name" name=""
                                            required>
                                    </div>

                                    <div class="row align-items-center justify-content-end">
                                        <div class="col-md-12">
                                            <div class="danger">
                                                <span>Message</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row gx-5 align-items-center">
                                    <div class="col-md-7">
                                        <label class="col-form-label">Date Of Birth</label>
                                        <input id="" type="date" class="form-control" placeholder="Date Of Birth"
                                            name="" required>
                                        <div class="danger"><span>Message</span></div>
                                    </div>
                                    <div class="col-md-5">
                                        <label class="col-form-label w-100">Gender</label>
                                        <span class="image-r-btn">
                                            <label class="img-btn">
                                                <input type="radio" name="gender-flags" checked>
                                                <img src="/assets/images/gender-f.png" alt="Female">
                                            </label>

                                            <span class="r-label">Female</span>
                                        </span>
                                        <span class="image-r-btn">
                                            <label class="img-btn">
                                                <input type="radio" name="gender-flags">
                                                <img src="/assets/images/gender-m.png" alt="Female">
                                            </label>

                                            <span class="r-label">Male</span>
                                        </span>
                                        <div class="danger"><span>Message</span></div>
                                    </div>
                                </div>

                                <div class="row gx-5 mb-4 align-items-end">
                                    <div class="col-md-7">
                                        <label class="col-form-label">State</label>
                                        <select name="" id="" class="form-control">
                                            <option value="">Select State</option>
                                        </select>
                                        <div class="danger"><span>Message</span></div>
                                    </div>
                                    <div class="col-md-5">
                                        <button type="submit" class="Signin" (click)="getOtp()"><i
                                                class="fa fa-spinner"></i>&nbsp;button</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="row g-3 align-items-center">
                                    <div class="row justify-content-center">
                                        <div class="col-md-12 ag-image-upload">
                                            <div class="circle">
                                                <img class="profile-pic" src="/assets/images/user-icon2.png">
                                            </div>
                                            <div class="p-image">
                                                <i class="fa fa-camera upload-button"></i> Upload Profile Image
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div> -->
            <div>
                <form>
                    <div class="ag-form">
                        <div class="row g-3 mb-4">
                            <div class="col-md-7">
                                <div class="row g-3 mb-4">
                                    <div class="col-md-6">
                                        <label class="col-form-label">Store Name</label>
                                        <input id="" type="text" class="form-control" placeholder="Course" name=""
                                            required>
                                        <div class="danger"><span>Message</span></div>
                                    </div>
                                    <div class="col-md-6">
                                        <label class="col-form-label">Select Product Category</label>
                                        <select name="" id="" class="form-control">
                                            <option value="">Select</option>
                                        </select>
                                        <div class="danger"><span>Message</span></div>
                                    </div>
                                    <div class="col-md-12">
                                        <label class="col-form-label">Address</label>
                                        <textarea class="form-control-address" placeholder="Enter your address" name=""
                                            required></textarea>
                                        <div class="danger"><span>Message</span></div>
                                    </div>
                                </div>

                                <div class="row g-3 align-items-end">
                                    <div class="col-md-6">
                                        <label class="col-form-label">State</label>
                                        <select name="" id="" class="form-control">
                                            <option value="">Select State</option>
                                        </select>
                                        <div class="danger"><span>Message</span></div>
                                    </div>
                                    <div class="col-md-6">
                                        <label class="col-form-label">PIN</label>
                                        <input id="" type="text" class="form-control" placeholder="Course" name=""
                                            required>
                                        <div class="danger"><span>Message</span></div>
                                    </div>
                                    <div class="col-md-6">
                                        <button type="submit" class="Signin">Next</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="row g-3 align-items-center">
                                    <div class="row justify-content-center">
                                        <div class="col-md-12 ag-image-upload">
                                            <div class="circle">
                                                <img class="profile-pic" src="/assets/images/user-icon2.png">
                                            </div>
                                            <div class="p-image">
                                                <i class="fa fa-camera upload-button"></i> Upload Profile Image
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="text-center">
                        <button type="submit" class="Signin" (click)="getOtp()"><i
                                class="fa fa-spinner"></i>&nbsp;button</button>
                    </div> -->
                </form>
            </div>

            <div>
                <form>
                    <div class="ag-form">
                        <div class="row g-3 mb-4">
                            <div class="col-md-7">
                                <div class="row g-3 mb-4">
                                    <div class="col-md-6">
                                        <label class="col-form-label">Enter your tax details</label>
                                        <input id="" type="text" class="form-control" placeholder="Course" name=""
                                            required>
                                        <div class="danger"><span>Message</span></div>
                                    </div>
                                    <div class="col-md-6">
                                        <label class="col-form-label">Provisional GSTIN</label>
                                        <input id="" type="text" class="form-control" placeholder="Course" name=""
                                            required>
                                        <div class="danger"><span>Message</span></div>
                                    </div>
                                    <div class="col-md-6">
                                        <label class="col-form-label">PAN number</label>
                                        <input id="" type="text" class="form-control" placeholder="Course" name=""
                                            required>
                                        <div class="danger"><span>Message</span></div>
                                    </div>
                                </div>

                                <div class="row g-3 align-items-end">
                                    <div class="col-md-6">
                                        <button type="submit" class="Signin">Next</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div>
                <form>
                    <div class="ag-form">
                        <div class="sub-heading">Choose categories you wish to sell</div>
                        <div class="row g-3 mb-4">
                            <div class="col-md-4">
                                <section class="example-section">
                                    <span class="example-list-section">
                                        <mat-checkbox class="example-margin" [checked]="allComplete"
                                            [indeterminate]="someComplete()" (change)="setAll($event.checked)">
                                            <strong>{{task.name}}</strong>
                                        </mat-checkbox>
                                    </span>
                                    <span class="example-list-section">
                                        <ul>
                                            <li *ngFor="let subtask of task.subtasks">
                                                <mat-checkbox [(ngModel)]="subtask.completed"
                                                    (ngModelChange)="updateAllComplete()">
                                                    {{subtask.name}}
                                                </mat-checkbox>
                                            </li>
                                        </ul>
                                    </span>
                                </section>
                            </div>
                            <div class="col-md-4">
                                <section class="example-section">
                                    <span class="example-list-section">
                                        <mat-checkbox class="example-margin" [checked]="allComplete"
                                            [indeterminate]="someComplete()" (change)="setAll($event.checked)">
                                            <strong>{{task.name}}</strong>
                                        </mat-checkbox>
                                    </span>
                                    <span class="example-list-section">
                                        <ul>
                                            <li *ngFor="let subtask of task.subtasks">
                                                <mat-checkbox [(ngModel)]="subtask.completed"
                                                    (ngModelChange)="updateAllComplete()">
                                                    {{subtask.name}}
                                                </mat-checkbox>
                                            </li>
                                        </ul>
                                    </span>
                                </section>
                            </div>
                            <div class="col-md-4">
                                <section class="example-section">
                                    <span class="example-list-section">
                                        <mat-checkbox class="example-margin" [checked]="allComplete"
                                            [indeterminate]="someComplete()" (change)="setAll($event.checked)">
                                            <strong>{{task.name}}</strong>
                                        </mat-checkbox>
                                    </span>
                                    <span class="example-list-section">
                                        <ul>
                                            <li *ngFor="let subtask of task.subtasks">
                                                <mat-checkbox [(ngModel)]="subtask.completed"
                                                    (ngModelChange)="updateAllComplete()">
                                                    {{subtask.name}}
                                                </mat-checkbox>
                                            </li>
                                        </ul>
                                    </span>
                                </section>
                            </div>
                            <div class="col-md-4">
                                <section class="example-section">
                                    <span class="example-list-section">
                                        <mat-checkbox class="example-margin" [checked]="allComplete"
                                            [indeterminate]="someComplete()" (change)="setAll($event.checked)">
                                            <strong>{{task.name}}</strong>
                                        </mat-checkbox>
                                    </span>
                                    <span class="example-list-section">
                                        <ul>
                                            <li *ngFor="let subtask of task.subtasks">
                                                <mat-checkbox [(ngModel)]="subtask.completed"
                                                    (ngModelChange)="updateAllComplete()">
                                                    {{subtask.name}}
                                                </mat-checkbox>
                                            </li>
                                        </ul>
                                    </span>
                                </section>
                            </div>
                            <div class="col-md-4">
                                <section class="example-section">
                                    <span class="example-list-section">
                                        <mat-checkbox class="example-margin" [checked]="allComplete"
                                            [indeterminate]="someComplete()" (change)="setAll($event.checked)">
                                            <strong>{{task.name}}</strong>
                                        </mat-checkbox>
                                    </span>
                                    <span class="example-list-section">
                                        <ul>
                                            <li *ngFor="let subtask of task.subtasks">
                                                <mat-checkbox [(ngModel)]="subtask.completed"
                                                    (ngModelChange)="updateAllComplete()">
                                                    {{subtask.name}}
                                                </mat-checkbox>
                                            </li>
                                        </ul>
                                    </span>
                                </section>
                            </div>
                        </div>

                        <div class="row g-3 align-items-end">
                            <div class="col-md-3">
                                <button type="submit" class="Signin">Next</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div>
                <form>
                    <div class="ag-form">
                        <div class="row g-3 mb-4">
                            <div class="col-md-7">
                                <div class="row g-3 mb-4">
                                    <div class="col-md-6">
                                        <label class="col-form-label">Account holder name</label>
                                        <input id="" type="text" class="form-control" placeholder="Course" name=""
                                            required>
                                        <div class="danger"><span>Message</span></div>
                                    </div>
                                    <div class="col-md-6">
                                        <label class="col-form-label">Account type</label>
                                        <select name="" id="" class="form-control">
                                            <option value="">Select</option>
                                        </select>
                                        <div class="danger"><span>Message</span></div>
                                    </div>
                                    <div class="col-md-6">
                                        <label class="col-form-label">Account number</label>
                                        <input id="" type="text" class="form-control" placeholder="Course" name=""
                                            required>
                                        <div class="danger"><span>Message</span></div>
                                    </div>
                                    <div class="col-md-6">
                                        <label class="col-form-label">Re-enter Account number</label>
                                        <input id="" type="text" class="form-control" placeholder="Course" name=""
                                            required>
                                        <div class="danger"><span>Message</span></div>
                                    </div>
                                    <div class="col-md-6">
                                        <label class="col-form-label">IFSC code</label>
                                        <input id="" type="text" class="form-control" placeholder="Course" name=""
                                            required>
                                        <div class="danger"><span>Message</span></div>
                                    </div>
                                </div>

                                <div class="row g-3 align-items-end">
                                    <div class="col-md-6">
                                        <button type="submit" class="Signin">Next</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="row g-3 align-items-center">
                                    <div class="row justify-content-center">
                                        <div class="col-md-12 ag-image-upload">
                                            <div class="sign">
                                                <img class="profile-pic" src="/assets/images/signature.png">
                                            </div>
                                            <div class="p-image">
                                                <i class="fa fa-camera upload-button"></i> Upload Signature
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <!-- <div class="text-center">
                <span class="haveAccount">Already have an account? <a href="javascript:void(0);">Sign
                        In</a></span>
            </div> -->
        </div>



    </div>

</div>