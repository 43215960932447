<div>
    <form #PregnancyDetails="ngForm" class="formExtension">

        <div>
            <h1 class="mt-0">Are you on your Periods?</h1>
            <div class="input-container text-center">
                <div (click)="pP=true"  [@fadeInOnEnter] [@fadeOutOnLeave]>
                    <button type="button" class="input-box icon-select" value="">{{periods}}</button>
                    <!-- <span class="icon2 fa-solid fa-caret-down ag-pointer"></span> -->
                </div>
                <div class="radio-tile-group" *ngIf="pP"  [@fadeInOnEnter] [@fadeOutOnLeave]>
                    <div class="input-container">
                        <button class="input-box" type="button" (click)="periodsSaveYes()">Yes</button>
                    </div>
                    <div class="input-container">
                        <button class="input-box" type="button" (click)="periodsSaveNo()">No</button>
                    </div>
                </div>
                <div *ngIf="showPeriodStartedDate"  [@fadeInOnEnter] [@fadeOutOnLeave]>
                    <h1>Enter your last period start date</h1>
                    <div (click)="handleInputStartDate();displayBox()">
                        <input type="text" value="{{ selectedRangeValue?.start | date: 'dd-MM-yyyy' }}"
                            [(ngModel)]="start_date" class="input-box" placeholder="DD-MM-YYYY" name="startDate"
                            #startDate="ngModel" ngModel readonly (click)="displayBox()" />
                        <span class="ag-date ag-pointer"></span>
                    </div>
                    <div *ngIf="showPeriodStartedDate && selectedRangeValue?.start">
                        <h1>Enter your last period end date</h1>
                        <div (click)="displayBox()">
                            <input type="text" value="{{ selectedRangeValue?.end | date: 'dd-MM-yyyy' }}"
                                [(ngModel)]="end_date" class="input-box" placeholder="DD-MM-YYYY"
                                [defaultValue]="99-99-9999" name="endDate" #endDate="ngModel" ngModel readonly
                                (click)="displayBox()" />
                            <span class="ag-date ag-pointer"></span>
                        </div>
                    </div>
                    <!-- <div class="d-flex justify-content-center">
                                        <div class="calendar">
                                            <div class="mat-calendar-wrapper ag-calendar-wrapper">
                                                <womean-material-calendar *ngIf="showCalendar && showPeriodStartedDate"
                                                    [selected]="selectedRangeValue"
                                                    (selectedChange)="selectedChange($event)"
                                                    [type]="'Range'"></womean-material-calendar>
                                            </div>
                                        </div>
                                    </div> -->
                    <div class="modal" id="model_" style="display:none;">
                        <div class="modal-content">
                            <h1>Enter your last period <br> (start date - end date)</h1>
                            <div class="mat-calendar-wrapper ag-calendar-wrapper">
                                <womean-material-calendar [@fadeInOnEnter] [@fadeOutOnLeave]  *ngIf="showCalendar && showPeriodStartedDate" 
                                    [selected]="selectedRangeValue" (selectedChange)="selectedChange($event)"
                                    [type]="'Range'"></womean-material-calendar>
                            </div>
                            <div class="close">
                                <button (click)="closeBox()">Done <i class="fa fa-angle-right ml-1"></i></button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="showPeriodStartedDate && selectedRangeValue?.start && selectedRangeValue.end!=null"
                    [@fadeInOnEnter] [@fadeOutOnLeave]>
                        <h1>Are you Pregnant?</h1>
                        <div class="select text-center">
                            <div (click)="pR=true">
                                <button type="button" class="input-box icon-select" value="">{{pregnant}}</button>
                                <!-- <span class="icon2 fa-solid fa-caret-down ag-pointer"></span> -->
                            </div>
                            <div class="radio-tile-group" *ngIf="pR"  [@fadeInOnEnter] [@fadeOutOnLeave] >
                                <div class="input-container">
                                    <button class="input-box" type="button" (click)="pregnantSaveYes()">Yes</button>
                                </div>
                                <div class="input-container">
                                    <button class="input-box" type="button" (click)="pregnantSaveNo()">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ag-track-section" *ngIf="showYesPeriod" >
                    <h1 class="text-center">Looks like You Do Not Need to Track Your Trimesters</h1>
                    <div class="text-center">
                        <button type="button"  [@fadeInOnEnter] [@fadeOutOnLeave] class="select-btn" (click)="handleNavigation('/period-steps')">Track
                            Periods</button>
                        <button type="button"   [@fadeInOnEnter] [@fadeOutOnLeave] class="select-btn" routerLink="/read">Explore
                            Articles</button>
                        <button type="button"   [@fadeInOnEnter] [@fadeOutOnLeave] class="select-btn" (click)="handleNavigation('/OvulationSteps')">Track
                            Ovulation</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="ag-continue">
            <!-- <button type="button" class="back" (click)="goBack()"><i class="icon1 fa-sharp fa-solid fa-play"></i> Go
                Previous</button> -->
            <button type="submit" class="Continue" *ngIf="continueHide"  [@fadeInOnEnter] [@fadeOutOnLeave]
                (click)="onSubmit(PregnancyDetails.value)">Continue</button>
        </div>
        <div class="btn-stepper-group d-none">
            <button class="btn-stepper" matStepperNext>Next</button>
        </div>
    </form>
</div>