<div class="modal-title">
    <h2>Big things are on the horizon!
    </h2>
    <span class="close" (click)="onCancel()">
        <img src="assets/images/cross.png" alt="">
    </span>
</div>
<div class="modal-body">
    <div class="modal-img">
        <img src="assets/images/modal-connect-1.png" alt="">
    </div>
    <div class="ag-modal-content">
        Urgent medical assistance needed? Our latest feature enables you to connect with doctors instantly, ensuring
        prompt and reliable healthcare support. Access quality care at your fingertips. Stay Tuned
    </div>
</div>