import { Component, OnInit } from '@angular/core';
import { CacheDataService } from '../core/services/cache-service/cache-data.service';
import { UserPrefernceService } from '../core/services/user-prefernce.service';

@Component({
  selector: 'app-sign-sucess',
  templateUrl: './sign-sucess.component.html',
  styleUrls: ['./sign-sucess.component.scss']
})
export class SignSucessComponent implements OnInit {

  constructor(    private userPrefernceService:UserPrefernceService,
    private cacheDataService: CacheDataService,

    ) { }

  ngOnInit(): void {
  }
  signOut(): void{
  this.userPrefernceService.removeToken();
  this.cacheDataService.removeModule();
  }
}
