import { NgModule } from '@angular/core';
import { MatOptionModule } from '@angular/material/core';
import { WomeanSingleSelectAutocompleteComponent } from './components/womean-single-select-autocomplete/womean-single-select-autocomplete.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { WomeanExploreArticleComponent } from './components/womean-explore-article/womean-explore-article.component';
import { WomeanMaterialCalendarComponent } from './components/womean-material-calendar/womean-material-calendar.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { WomeanProgressBarComponent } from './components/womean-progress-bar/womean-progress-bar.component';

@NgModule({
  declarations: [
    WomeanSingleSelectAutocompleteComponent,
    WomeanExploreArticleComponent,
    WomeanMaterialCalendarComponent,
    WomeanProgressBarComponent
  ],
  imports: [
    CommonModule,
    MatOptionModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
  ],

  providers: [],
  exports: [
    WomeanSingleSelectAutocompleteComponent,
    WomeanExploreArticleComponent,
    WomeanMaterialCalendarComponent,
    WomeanProgressBarComponent
  ],
})
export class SharedModule {}
