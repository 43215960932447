<div class="center">
    <div class="image">
        <img src="assets\womean_logo_PNG-removebg-preview.png" alt=""height="120" width="120">
    </div>
    <div class="text-center">

    <form #saveArticleForm="ngForm" >
        <h1>Please Enter/Edit the Content here</h1>
        <textarea type="text" required #content="ngModel" minlength="10" class="input-box"  name="content" ngModel></textarea><br>
        <div class="text-danger " *ngIf="content.touched && content.value==''">
          <i class="fa-sharp fa-solid fa-circle-exclamation"></i>
          <span> Content is required</span>
        </div>
        <div class="text-danger " *ngIf="content.errors?.['minlength'] && content.invalid">
          <i  class="fa-sharp fa-solid fa-circle-exclamation"></i>
          "minimum 10 characters"
        </div>
        <h2>Select a Category</h2>

        <div class="container">
            <div class="radio-tile-group">
              <div class="input-container">
                <input id="pregnancy" class="radio-button" type="radio" #category="ngModel" name="category" value="Pregnancy" ngModel/>
                <div class="radio-tile">
                  <label for="pregnancy" class="radio-tile-label">Pregnancy</label>
                </div>
              </div>
          
              <div class="input-container">
                <input id="hygiene" class="radio-button" type="radio" #category="ngModel" name="category" value="Hygiene" ngModel/>
                <div class="radio-tile">
                    <label for="hygiene" class="radio-tile-label">Hygiene</label>
                </div>
              </div>
          
              <div class="input-container">
                <input id="ovulation" class="radio-button" type="radio" #category="ngModel" name="category" value="Ovulation" ngModel/>
                <div class="radio-tile">
                  <label for="ovulation" class="radio-tile-label">Ovulation</label>
                </div>
              </div>
          
              <div class="input-container">
                <input id="fitness" class="radio-button" type="radio" #category="ngModel" name="category" value="Fitness" ngModel/>
                <div class="radio-tile">
                  <label for="fitness" class="radio-tile-label">Fitness</label>
                </div>
              </div>

              <div class="input-container">
                <input id="periods" class="radio-button" type="radio" #category="ngModel" name="category" value="Periods" ngModel/>
                <div class="radio-tile">
                  <label for="periods" class="radio-tile-label">Periods</label>
                </div>
              </div>
            </div>
          </div><br>
          <!-- alert messege for catogery selction -->
          <div class="text-danger " *ngIf="Bucket_URL.touched && category.pristine">
            <i class="fa-sharp fa-solid fa-circle-exclamation"></i>
            <span> Select category</span>
          </div>
          
          <h2>Image Source to be Used in the Article</h2>
          <!-- alert messege for catogery selction -->
            
                <div class="container form-inline">
                    <div class="form-group">
                        <label for="bucket_Url"><h2>Bucket URL</h2></label>
                        <input type="text" required #Bucket_URL="ngModel" class="form-control" name="Bucket_URL" ngModel>
                        
                        <label for="line_Number" class="gap"><h2>Line Number</h2></label>
                        <input type="text" required #line_Number="ngModel" class="form-control gap" name="line_Number" ngModel>
                    </div>
                </div>
                <div class="left container">
                  <div class="text-danger col-md" *ngIf="Bucket_URL.touched && Bucket_URL.invalid">
                    <i class="fa-sharp fa-solid fa-circle-exclamation"></i>
                    <span> Bucket URL is required</span>
                  </div>
                  <div style="margin-right: 10%;" class="left text-danger" *ngIf="line_Number.touched && line_Number.invalid">
                    <i  class="fa-sharp fa-solid fa-circle-exclamation"></i>
                    <span colspan="2"> Line Number is required</span>
                  </div>
                </div>
                <div  class="form-inline">
                  <div class="container form-group">
                    <label for="author"><h2>Author</h2></label>
                    <input type="text" required #Author="ngModel" class="form-control input-field" name="Author" ngModel>
                  </div>
                  <div class="text-danger container" *ngIf="Author.touched && Author.invalid">
                    <i class="fa-sharp fa-solid fa-circle-exclamation"></i>
                    <span>Author is required</span>
                  </div>
                  <div class="form-group container">
                    <label for="header"><h2>Header</h2></label>
                    <input type="text" required #Header="ngModel" class="form-control input-field" name="Header" ngModel>
                  </div>
                  <div class="text-danger container" *ngIf="Header.touched && Header.invalid">
                    <i class="fa-sharp fa-solid fa-circle-exclamation"></i>
                    <span>Header is required</span>
                  </div>
                </div>
            </form>
        </div>
        
    <button class="btn-save float-right" type="submit" [disabled]="saveArticleForm.invalid|| category.value==''" (click)="onSave(saveArticleForm.value)">Save</button>
    <p>Womean Inc. 2022 All rights reserved</p>
</div>
