
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective } from '@angular/forms'
import { DateRange } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BaseTrackComponent } from '../base-tracking.component';
import { ContinueComponent } from '../continue/continue.component';
import { UserPrefernceService } from '../core/services/user-prefernce.service';
import { JwtLoginResponse, JwtToken } from '../jwtToken';
import { ErrorModalComponent } from '../modal/error-modal/error-modal.component';
import { RestService } from '../rest.service';
import { SignUpComponent } from '../sign-up/sign-up.component';
import { SigninComponent } from '../signin/signin.component';
import { Track } from '../userCredentials';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { fadeInOnEnterAnimation,fadeInExpandOnEnterAnimation, fadeOutCollapseOnLeaveAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { Location } from '@angular/common';
@Component({
  selector: 'app-ovulation-track',
  templateUrl: './ovulation-track.component.html',
  styleUrls: ['./ovulation-track.component.scss'],
  animations: [
    fadeInOnEnterAnimation(),
    fadeOutOnLeaveAnimation(),
    fadeInExpandOnEnterAnimation({ duration: 400 }),
    fadeOutCollapseOnLeaveAnimation({ duration: 400 }),
  ]
})
export class OvulationTrackComponent extends BaseTrackComponent
  implements OnInit {
    @Output() onClickContinue = new EventEmitter<any>();
  @ViewChild('PregnancyDetails') PregnancyDetails: FormGroupDirective;
  pregnancyTracking = true;
  onPeriod: boolean;
  periods: string;
  pregnant: string;
  static lastPeriodStartDate: any;
  static lastPeriodEndDate: any;
  static trackDetails: Track;
  wantToGetPregnant: boolean = true;
  showlookingToGetPregnant: boolean = false;
  public continueHide: boolean;
  pP = false;
  pR = false;
  showPeriodStartedDate: boolean;
  showYesPeriod: boolean;
  jwtTokenData: JwtLoginResponse = undefined
  start_day: any;
  start_month: any;
  start_year: any;
  end_day: any;
  end_month: any;
  end_year: any;
  start_date: any;
  end_date: any;
  trackData: any
  day_Period: any
  pId: any;
  email: any;
  showMsg: boolean;
  ContinueComponent = ContinueComponent;
  SignUpComponent = SignUpComponent;
  SigninComponent = SigninComponent;
  @Output() selectedRangeValueChange = new EventEmitter<DateRange<Date>>();

  constructor(private route: Router, 
    private rest: RestService, 
    private userPrefernceService: UserPrefernceService, 
    public dialog: MatDialog,
    private router: Router,
    private location: Location) {
    super()
  }

  ngOnInit(): void {
    const jwtToken = new JwtToken();
    this.jwtTokenData = jwtToken.getDecodedAccessToken(this.userPrefernceService.getToken());
    this.getEditData()
  }


  getEditData() {
    this.rest.getovulationTrackEditData().subscribe((res: any) => {
      console.log(res)
      this.trackData = res
      if (Object.keys(this.trackData).length != 0) {
        if (this.trackData?.data?.onPeriod) {
          this.day_Period = this.trackData?.data?.dayOfPeriod 
          this.periodsSaveYes()
          if(!this.day_Period){
            this.showMsg=true
          }
          else{
            this.showMsg=false
          }
        }
        else {
          this.periodsSaveNo()
        }
      }
    })
  }

  editIfnotOnOvulation(res: any) {
    if (Object.keys(res).length != 0) {
    if (res.data?.lastPeriodStartDate && res.data?.lastPeriodEndDate) {
      console.log(this.selectedRangeValue)
      this.start_day = String(new Date(res.data.lastPeriodStartDate).getDate()).padStart(2, '0');
      this.start_month = String(new Date(res.data.lastPeriodStartDate).getMonth() + 1).padStart(2, '0'); //January is 0!
      this.start_year = String(new Date(res.data.lastPeriodStartDate).getFullYear()).padStart(2, '0');
      this.start = this.start_year + '-' + this.start_month + '-' + this.start_day;
      this.start_date = this.start_day + '-' + this.start_month + '-' + this.start_year;

      this.end_day = String(new Date(res.data.lastPeriodEndDate).getDate()).padStart(2, '0');
      this.end_month = String(new Date(res.data.lastPeriodEndDate).getMonth() + 1).padStart(2, '0'); //January is 0!
      this.end_year = String(new Date(res.data.lastPeriodEndDate).getFullYear()).padStart(2, '0');
      this.end = this.end_year + '-' + this.end_month + '-' + this.end_day;
      this.end_date = this.end_day + '-' + this.end_month + '-' + this.end_year;

      this.selectedRangeValue = new DateRange(moment(this.start), moment(this.end));
      this.handleInputStartDate()
    }
    if (res.data?.wantToGetPregnant) {
      this.showlookingToGetPregnant = true
      this.pregnantSaveYes()
    }
    else {
      this.pregnantSaveNo()
    }
  }
  }

  setComponentTrackDates() {
    this.selectedRangeValueChange.emit(this.selectedRangeValue);
  }

  periodsSaveNo() {
    this.pP = false;
    this.onPeriod = false;
    this.periods = "No";
    this.showPeriodStartedDate = true;
    this.showYesPeriod = false;
    this.continueHide = false; 
    this.editIfnotOnOvulation(this.trackData)
  }

  periodsSaveYes() {
    this.pP = false;
    this.onPeriod = true;
    this.periods = "Yes";
    this.showPeriodStartedDate = false;
    this.showYesPeriod = true;
    this.continueHide = false;   
    this.showMsg=true
    this.editIfnotOnOvulation(this.trackData)
  }

  pregnantSaveNo() {
    this.pR = false;
    this.wantToGetPregnant = false;
    this.continueHide = false;
    this.pregnant = "No";
  }

  pregnantSaveYes() {
    this.pR = false;
    this.wantToGetPregnant = true;
    this.continueHide = true;
    this.pregnant = "Yes";
  }

  onSubmit(ovulation: any) {


    const track = new Track();

    track.period_Tracking = false;
    track.on_Period = this.onPeriod;
    track.day_OfPeriod = ovulation.dayOfPeriod;
    track.last_PeriodStartDate = Date.parse(this.start);
    track.last_PeriodEndDate = Date.parse(this.end);
    track.having_Cramps = false;
    track.having_Pms = false;
    track.cramp_Severity = 0;
    track.pms_Severity = 0;
    track.assumed_PeriodCycle = 0;
    track.pregnancy_Tracking = false;
    track.pregnant1 = this.wantToGetPregnant;
    track.method_OfEstimation = "";
    track.date_OfConception = 0;
    track.due_Date = 0;
    track.want_ToGetPregnant = this.wantToGetPregnant;
    track.ovulation_Tracking = true;
    track.to_Notify = false;

    let str: any;

    track.p_Id = this.jwtTokenData.jti;
    track.e_mail = this.jwtTokenData.email;
    OvulationTrackComponent.trackDetails = track;
    console.log("track" + JSON.stringify(track));
    this.onClickContinue.emit('OvulationTrack2')
    //this.route.navigate(['/OvulationTrack2']);
    console.log(ovulation);
  }

  countNumber(event: Event) {
    const target = event.target as HTMLInputElement
    console.log(target.value.length)
    if (Number(target.value) > 0 && Number(target.value) < 8 &&target.value != "") {
      this.showlookingToGetPregnant = true
      this.showMsg=false
    }
    else {
      this.showMsg=true
      this.showlookingToGetPregnant = false
    }
    // if (target.value.length > 0) {
    //   this.showlookingToGetPregnant = true
    // }
    // else {
    //   this.showlookingToGetPregnant = false
    // }
  }

  displayBox(){
    var modal = document.getElementById("model_");
    modal.style.display = 'block';
  }

  closeBox(){
    var modal = document.getElementById("model_");
    modal.style.display = 'none';
  }

  goBack(){
    this.location.back();
  }

  async handleNavigation(module: string) {
    let sector = ''
    let trackName = module.replace("/", "")
    if (trackName == 'period-steps') sector = 'PERIODS'
    else if (trackName == 'PregnancySteps') sector = 'PREGNANCY'
    else sector = 'OVULATION'
    this.email = this.userPrefernceService.getCurrentUserEmailId();
    this.pId = this.userPrefernceService.getCurrentUserJti();
    if (this.pId != null || this.pId != undefined) {
      let check = await this.rest.trackPostCore(this.pId, this.email, sector);
      if (check.status) {
        if (check.data.tracked) {
          if (trackName == 'period-steps') this.router.navigate(['/period-track4']) //track dashboard
          else if (trackName == 'PregnancySteps') this.router.navigate(['/PregnancyTrack4']) //track dashboard
          else this.router.navigate(['/OvulationTrack3']) //track dashboard
        }
        else {
          this.router.navigate([module]);
        }
      }
    }
  }

}