import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ContinueComponent } from '../continue/continue.component';
import { UserPrefernceService } from '../core/services/user-prefernce.service';
import { PregnancyTrackComponent } from '../pregnancy-track/pregnancy-track.component';
import { ResponseData } from '../responsedata-class';
import { RestService } from '../rest.service';
import { SigninComponent } from '../signin/signin.component';

@Component({
  selector: 'app-pregnancy-track4',
  templateUrl: './pregnancy-track4.component.html',
  styleUrls: ['./pregnancy-track4.component.scss'],
})
export class PregnancyTrack4Component implements OnInit {
  static userName: any; 
  start_day: String;
  start_month: String;
  start_year: String;
  end_day: String;
  end_month: String;
  end_year: String;
  estimatedDueDate_day: String;
  estimatedDueDate_month: String;
  estimatedDueDate_year: String;
  nextPeriodDate: any;
  start: string;
  end: string;
  data: ResponseData;
  estimatedDueDate: any;
  estimatedDays: number;
  weeksOfPregnancy: number;
  estimatedWeeksPercent: number;
  selectedRangeValue:  any; 
  cycleCompletedPercentage: number;
  brogressBarImage: string = 'pregnancy_dashboard.png';
  DaysUntilDueDate : string
  trackerRes:any
  weekText : string
  pId: any;
  email: any;
  exceptionPregMsg = ''
  @Output() selectedRangeValueChange = new EventEmitter<DateRange<Date>>();
  priorPeriodDates: any = [];
  constructor(private rest: RestService, private router: Router,private userPrefernceService: UserPrefernceService) {}

  async ngOnInit(): Promise<void> {
    let id =   this.userPrefernceService.getCurrentUserJti();
    PregnancyTrack4Component.userName = id;
    const res = await this.rest.getpregnancytrackerById(id);
    this.trackerRes = res
    if (res.status) {
      this.cycleCompletedPercentage = 100-((res.data?.daysUntilNextPeriod * 100) / res.data?.assumedPeriodCycle)
      this.DaysUntilDueDate = res.data?.daysUntilDueDate
      this.weeksOfPregnancy = parseInt(res.data?.weeksOfPregnant)
      if(this.weeksOfPregnancy > 50)
      this.exceptionPregMsg = 'It appears that you have already delivered your baby. We hope both you and your little one are doing well.'
      this.weekText = res.data?.weekText
      if (res.data?.lastPeriodStartDate && res.data?.lastPeriodEndDate) {
        this.start_day = String(new Date(res.data.lastPeriodStartDate).getDate()).padStart(2, '0');
        this.start_month = String(new Date(res.data.lastPeriodStartDate).getMonth() + 1).padStart(2, '0'); //January is 0!
        this.start_year = String(new Date(res.data.lastPeriodStartDate).getFullYear()).padStart(2, '0');
        this.start = this.start_year + '-' + this.start_month + '-' + this.start_day;

        this.end_day = String(new Date(res.data.lastPeriodEndDate).getDate()).padStart(2, '0');
        this.end_month = String(new Date(res.data.lastPeriodEndDate).getMonth() + 1).padStart(2, '0'); //January is 0!
        this.end_year = String(new Date(res.data.lastPeriodEndDate).getFullYear()).padStart(2, '0');
        this.end = this.end_year + '-' + this.end_month + '-' + this.end_day;

        this.selectedRangeValue = new DateRange(moment(this.start), moment(this.end));
        console.log(this.selectedRangeValue);
      }
      if (res.data?.estimatedDueDate){
        this.estimatedDueDate_day = String(new Date(res.data.estimatedDueDate).getDate()).padStart(2, '0');
        this.estimatedDueDate_month = String(new Date(res.data.estimatedDueDate).getMonth() + 1).padStart(2, '0'); //January is 0!
        this.estimatedDueDate_year = String(new Date(res.data.estimatedDueDate).getFullYear()).padStart(2, '0');
        this.estimatedDueDate = this.estimatedDueDate_year + '-' + this.estimatedDueDate_month + '-' + this.estimatedDueDate_day;
        this.estimatedDueDate = moment(this.estimatedDueDate)
      }
      
    }
 
  }

  navigationUrls = [
    {
      urls: 'Home Page',
      link: '/homepage',
      icons : 'fa fa-home',
      active : false
    },
    {
      urls: 'The Initiative',
      link: '/initiative',
      icons : 'fa fa-file',
      active : false
    },
    {
      urls: 'About Us',
      link: '/aboutus',
      icons : 'fa fa-info-circle',
      active : false
    },
    {
      urls: 'Contact Us',
      link: '/contactus',
      icons : 'fa fa-phone',
      active : false
    },
  ];

  async edit() {
    //this.trackerRes = {"data":{"id":"dff962ba-fda0-4090-8010-0de1e3b06f8d","email":"akashdeepmitra@womean.org","periodTracking":false,"onPeriod":false,"dayOfPeriod":0,"lastPeriodStartDate":1677695400000,"lastPeriodEndDate":1678905000000,"havingCramps":false,"havingPms":false,"crampSeverity":0,"pmsSeverity":0,"assumedPeriodCycle":21,"pregnancyTracking":true,"pregnant":true,"methodOfEstimation":"dueDate","weeksOfPregnant":"40.0","estimatedDueDate":1677776819674,"dateOfConception":0,"dueDate":1677695400000,"weekText":"Pregnancy is a wonderful miracle, and you are in the final phase of it, with you being just a few days away from feeling to literally seeing the kicks of the little. Your baby is snug and content inside but won’t be there for much longer, so watch out for all the signs that may indicate to the start of labor. Additionally, make adequate arrangements so that when the time comes you can easily travel to the hospital without any difficulty.\nWith this, we'd like to send you our utmost best wishes as you embark on a new stage of life","wantToGetPregnant":true,"ovulationTracking":true,"dayOfOvulation":1678300200000,"fertileWindowStart":1678041000000,"fertileWindowEnds":1678386600000,"periodLength":0,"nextPeriodDate":0,"pregnancyTestDate":0,"toNotify":false,"daysUntilNextPeriod":20,"daysRemainingInThisPeriod":0,"daysUntilDueDate":0,"totalPregnancyDuration":280,"daysUntilFertilityWindowStrts":3,"daysUntilFertilityWindowEnds":7,"daysUntilOvulationDay":6},"error":null,"status":true}
     this.rest.setpregnancyTrackEditData(this.trackerRes)
     this.router.navigate(["/PregnancySteps"]);
     }

  async handleNavigation(module: string) {
    let sector = ''
    let trackName = module.replace("/", "")
    if (trackName == 'period-steps') sector = 'PERIODS'
    else if (trackName == 'PregnancySteps') sector = 'PREGNANCY'
    else sector = 'OVULATION'
    this.email = this.userPrefernceService.getCurrentUserEmailId();
    this.pId = this.userPrefernceService.getCurrentUserJti();
    if (this.pId != null || this.pId != undefined) {
      let check = await this.rest.trackPostCore(this.pId, this.email, sector);
      if (check.status) {
        if (check.data.tracked) {
          if (trackName == 'period-steps') this.router.navigate(['/period-track4']) //track dashboard
          else if (trackName == 'PregnancySteps') this.router.navigate(['/PregnancyTrack4']) //track dashboard
          else this.router.navigate(['/OvulationTrack3']) //track dashboard
        }
        else{
          this.router.navigate([module]);
        }
      }
    }
  }
}
