import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { QueryRequestBody } from '../contact-us';
import { SuccessModalComponent } from '../modal/success-modal/success-modal.component';
import { RestService } from '../rest.service';
import { NgForm } from '@angular/forms'
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  @ViewChild('raiseQuery') mytemplateForm : NgForm;
  constructor(
    private rest: RestService,
    public dialog: MatDialog
    ) { }

  socialMediaLinksData: any[] = [
    {
      link: "https://www.facebook.com/wearewomean",
      image: "assets/images/fb.png",
      alt: 'facebook',
    },
    {
      link: "https://www.instagram.com/wearewomean/?igshid=YmMyMTA2M2Y%3D",
      image: "assets/images/insta.png",
      alt: 'instagram',
    },
    {
      link: "https://twitter.com/wearewomean?s=21&t=xGHvNlOyumKy63WmJ1XO-g",
      image: "assets/images/tw.png",
      alt: 'twitter',
    }
  ]

  navigationUrls = [
    {
      urls: 'Home Page',
      link: '/homepage',
      icons: 'fa fa-home',
      active: false
    },
    {
      urls: 'The Initiative',
      link: '/initiative',
      icons: 'fa fa-file',
      active: false
    },
    {
      urls: 'About Us',
      link: '/aboutus',
      icons: 'fa fa-info-circle',
      active: false
    },
    {
      urls: 'Contact Us',
      link: '/contactus',
      icons: 'fa fa-phone',
      active: true
    },
  ];

  ngOnInit(): void {
  }

  async onSubmit(details: any) { 
    const reqBody = new QueryRequestBody();
    reqBody.setEmail(details.email);
    reqBody.setContact(details.contact);
    reqBody.setQuery(details.query);
    let res = await this.rest.raiseQuery(reqBody);
    if (res) {
      let message = "Thank you for reaching out to Womean. We have received your message and will get back to you as soon as possible.";
      const dialogRef = this.dialog.open(SuccessModalComponent, { width: '420px', data: message, disableClose: true });
      dialogRef.afterClosed().subscribe(result => {
        if (result) { 
          this.mytemplateForm.reset(); 
        }
      });
    } 
  }

}
