import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'womean-ovulation-steps',
  templateUrl: './ovulation-steps.component.html',
  styleUrls: ['./ovulation-steps.component.scss']
})
export class OvulationStepsComponent implements OnInit {
  @ViewChild('stepper') private ovulationStepper: MatStepper;
  activeStepper : string
  constructor() { }

  ngOnInit(): void {
  }

  stepperRoute(event: any){ 
    console.log(event)
    if(event == 'back'){
      this.ovulationStepper.previous();
    }
    else{ 
      this.activeStepper = event
      this.ovulationStepper.next();
    }
  }
  
}
