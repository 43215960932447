<footer>
  <div class="container">
    <!-- <hr /> -->
    <!-- <div class="social">
      <h4>Follow Us On Social Media</h4>
      <div class="social-links">
        <a *ngFor="let mediaLink of socialMediaLinksData" href="{{ mediaLink.link }}" target="_blank"
          class="insta-link"><img src="{{ mediaLink.image }}" alt="{{ mediaLink.alt }}" class="social-media-logo" /></a>
      </div>
    </div> -->
    <div class="footermenu">
      <div class="footer-grid">
        CONTACT US
        <ul class="grid-list grid-list-contact">
          <li class="fw-map"><a href="#">Gurugram Haryana-122001</a></li>
          <li class="fw-mail"><a href="#">support@womean.org</a></li>
        </ul>
      </div>
      <div class="footer-grid">
        COMPANY
        <ul class="grid-list">
          <li><a href="#" routerLink="/aboutus" routerLinkActive="router-link-active" >About Womean</a></li>
          <!-- <li><a href="#">Media Center</a></li>
          <li><a href="#">Payment Support</a></li> -->
        </ul>
      </div>
      <div class="footer-grid">
        LEGAL POLICIES
        <ul class="grid-list">
          <li><a href="https://storage.googleapis.com/womean-mail-content/footer-documents/Disclaimer%20policy%20Womean.pdf" target="_blank">Disclaimer Policies</a></li>
          <li><a href="https://storage.googleapis.com/womean-mail-content/footer-documents/Health%20Disclaimer%20policy.pdf" target="_blank">Health Disclaimer Policies</a></li>
          <li><a href="https://storage.googleapis.com/womean-mail-content/footer-documents/TERMS%20OF%20USE.pdf" target="_blank">Terms of Usage</a></li>
          <li><a href="https://storage.googleapis.com/womean-mail-content/footer-documents/Cookie%20Policy.pdf" target="_blank">Cookie Policy</a></li>
          <li><a href="https://storage.googleapis.com/womean-mail-content/footer-documents/Privacy%20Policy.pdf" target="_blank">Privacy Policy</a></li>
        </ul>
      </div>
      <div class="footer-grid">
        PRODUCTS
        <ul class="grid-list">
          <li><a href="javascript:void(0)" (click)="navigateToModule('track')">Track Application</a></li>
          <li><a href="javascript:void(0)" (click)="navigateToModule('read')">Read Module</a></li>
          <li><a href="javascript:void(0)"(click)="modalShop()">E-commerce</a></li>
          <li><a href="javascript:void(0)" (click)="modalConnct()">Connect</a></li> 
          <li><a href="javascript:void(0)"(click)="modalBuild()">Ecosystem</a></li>
        </ul>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="footmarks">
      <div class="row align-items-center">
        <div class="col-sm-6 col-xs-6 nopadding d-flex pr-0">
          <p style="font-size:13px ;">Womean Inc. 2022 All rights reserved</p>
        </div>
        <div class="col-sm-6 col-xs-6 nopadding div-footer-text">
          <div class="social-icons">
            <!-- <img src="assets/images/fb.png" alt="">
            <img src="assets/images/tw.png" alt="">
            <img src="assets/images/insta.png" alt=""> -->
            <a *ngFor="let mediaLink of socialMediaLinksData" href="{{ mediaLink.link }}" target="_blank"
          class="insta-link"><img src="{{ mediaLink.image }}" alt="{{ mediaLink.alt }}" /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>