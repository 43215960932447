import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { PeriodTrackComponent } from '../period-track/period-track.component';
import { PeriodTrack2Component } from '../period-track2/period-track2.component';

@Component({
  selector: 'womean-period-steps',
  templateUrl: './period-steps.component.html',
  styleUrls: ['./period-steps.component.scss']
})
export class PeriodStepsComponent implements OnInit {
  @ViewChild('stepper') private periodStepper: MatStepper;
  periodtrack:any
  periodtrack2:any
  activeStepper : string
  constructor() { }

  ngOnInit(): void {
  }
  
  stepperRoute(event: any){ 
    console.log(event)
    if(event == 'back'){
      this.periodStepper.previous();
    }
    else{ 
      this.activeStepper = event
      this.periodtrack = PeriodTrackComponent.trackDetails
      this.periodtrack2 = PeriodTrack2Component.track
      this.periodStepper.next();
    }
  }
 
}
