<div class="body">
  <app-shared-header [navigationUrls]="navigationUrls" [showSocialMediaLink]="false"></app-shared-header>

  <h1 class="page-heading">Track Your Ovulation Period</h1>
  <div class="calendar-sec">
    <div class="container">
      <div class="float-container d-flex justify-content-center">
        <div class="float-child" *ngIf="selectedRangeValue">
          <h2>Your Previous Period</h2>
          <div class="calendar">
            <div class="mat-calendar-wrapper" style="background:  #feecf6; border-radius: 30px;">
              <womean-material-calendar [selected]="selectedRangeValue" [type]="'Range'"
                [canclick]="false"></womean-material-calendar>
            </div>
          </div>
        </div>

        <div class="cal2 float-child" *ngIf="fertileWindowStart">
          <h2>Your Ovulation Period</h2>
          <div class="calendar">
            <div class="mat-calendar-wrapper" style="background: #feecf6; border-radius: 30px;">
              <womean-material-calendar [selected]="selectedfertileWindowRangeValue" [type]="'Range'" [canclick]="false"
                [dateModel]="dateModel"></womean-material-calendar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="text-center">
      <button type="submit" class="btn-select" (click)="edit()">
        Edit
      </button>
    </div>

    <div class="row align-items-center">
      <div class="col-md-6">
        <div class="progress-bar-circular">
          <div class="progress-bar-width">
            <circle-progress [percent]="cycleCompletedPercentage" [radius]="210" [space]="-20" [outerStrokeWidth]="20"
              [outerStrokeColor]="'#882279'" [innerStrokeColor]="'#eeeeee'" [innerStrokeWidth]="20"
              [imageSrc]="'assets/images/ovalution_dashboard.png'" [imageHeight]="300" [imageWidth]="300"
              [showImage]="true" [showBackground]="false" [animation]="true" [animationDuration]="300"
              [responsive]="true">
            </circle-progress>
          </div>
          <!-- <womean-progress-bar [completed]="cycleCompletedPercentage" [img]="brogressBarImage"></womean-progress-bar> -->
        </div>
      </div>
      <div class="col-md-6">
        <!-- <h1 class="kg-h2">This is what your Cycle looks like</h1> -->
        <div class="priodMonth">
          <div class="glider-contain">
            <!-- <button class="glider-prev"></button> -->
            <div class="glider">
              <div class="glider-track">
                <div class="monthlyPeriodTime" *ngFor='let row of priorPeriodDates; let i=index;'>
                  <div class="date-data">{{row}}</div>
                </div>
                <!-- <div class="monthlyPeriodTime">
                  <div class="date-data">{{priorPeriodDates[1]}}</div>
                </div>
                <div class="monthlyPeriodTime">
                  <div class="date-data">{{priorPeriodDates[0]}}</div>
                </div> -->
              </div>
            </div>
            <!-- <button class="glider-next"></button> -->
          </div>
        </div>
        <div class="fluid-container2">
          <div class="input-container">
            <i style="color: #726F0D" class="icon1 fa-solid fa-circle"></i>
            <div class="content">
              <h2 class="content-heading">{{daysUntilFertilityWindowStrts}} days</h2>
              <h2>Until your Fertility window starts</h2>
            </div>
          </div>
          <div class="input-container">
            <i style="color: #CE31FD" class="icon1 fa-solid fa-circle"></i>
            <div class="content">
              <h2 class="content-heading">{{daysUntilOvulationDay}} days</h2>
              <h2>Until your next Ovulation day</h2>
            </div>
          </div>
          <div class="input-container">
            <i style="color: #726F0D" class="icon1 fa-solid fa-circle"></i>
            <div class="content">
              <h2 class="content-heading">{{daysUntilFertilityWindowEnds}} days</h2>
              <h2>Until your Fertility window ends</h2>
            </div>
          </div>
          <div class="input-container" *ngIf="onPeriod">
            <i style="color: #128876" class="icon1 fa-solid fa-circle"></i>
            <div class="content">
              <h2 class="content-heading">{{daysRemainingInThisPeriod}} days</h2>
              <h2>Until your current Cycle ends</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="container">
    <div class="row">
      <div class="col-sm-4 col-xs-4 d-flex justify-content-center">
        <button type="button" class="select-btn" (click)="handleNavigation('/period-steps')">
          Track <br> Periods
        </button>
      </div>
      <div class="col-sm-4 col-xs-4 d-flex justify-content-center">
        <button type="button" class="select-btn" routerLink="/read">Explore <br> Women-opedia</button>
      </div>
      <div class="col-sm-4 col-xs-4 d-flex justify-content-center">
        <button type="button" class="select-btn" (click)="handleNavigation('/PregnancySteps')">
          Track <br> Pregnancy
        </button>
      </div>
    </div>
  </div>
  <br />
  <app-footer></app-footer>
  <womean-scroll-to-top></womean-scroll-to-top>
</div>