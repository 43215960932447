import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { PregnancyTrackComponent } from '../pregnancy-track/pregnancy-track.component';
import { RestService } from '../rest.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { fadeInOnEnterAnimation,fadeInExpandOnEnterAnimation, fadeOutCollapseOnLeaveAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';

@Component({
  selector: 'app-pregnancy-track2',
  templateUrl: './pregnancy-track2.component.html',
  styleUrls: ['./pregnancy-track2.component.scss'],
  animations: [
    fadeInOnEnterAnimation(),
    fadeOutOnLeaveAnimation(),
    fadeInExpandOnEnterAnimation({ duration: 400 }),
    fadeOutCollapseOnLeaveAnimation({ duration: 400 }),
  ]
})
export class PregnancyTrack2Component implements OnInit {
  public continueHide = false;
  MenstrualCycle: any
  trackData:any
  showMsg : boolean
  @Output() onClickContinue = new EventEmitter<any>();
  
  constructor(private route : Router,private rest: RestService) { }

  ngOnInit(): void {
    this.getEditData()
  }

  getEditData() {
    this.rest.getpregnancyTrackEditData().subscribe((res: any) => {
      console.log(res)
      this.trackData = res
      if (Object.keys(this.trackData).length != 0) {
        this.MenstrualCycle = res.data.assumedPeriodCycle
        this.continueHide = true 
      }
    })

  }

  onSubmit(data:any){ 
    PregnancyTrackComponent.trackDetails.assumed_PeriodCycle = data.Menstrual
    this.onClickContinue.emit('PregnancyTrack3')
    //this.route.navigate(["/PregnancyTrack3"]);
  }

  countNumber(event: Event) {
    const target = event.target as HTMLInputElement
    console.log(target.value.length)
    if (Number(target.value) > 9 && Number(target.value) < 51 && target.value != "" && target.value.length == 2) {
      this.continueHide = true
      this.showMsg=false
    }
    else {
      this.showMsg=true
      this.continueHide = false
    }
    // if (target.value.length == 2) {
    //   this.continueHide = true
    // }
    // else{
    //   this.continueHide = false
    // }
  }
  goBack(){ 
    this.onClickContinue.emit('back')
  }

}
