<div class="body">

  <div class="left">
    <span class="ag-signin-logo">
      <img src="/assets/images/logox.png" class="logo">
      <h2>Period Tracking</h2>
    </span>

    <div class="down">
      <p>Womean Inc. 2022 All rights reserved</p>
    </div>
  </div>

  <!-- <div class="logo">
    <img src="assets\womean_logo_PNG-removebg-preview.png" alt="" height="130" width="130">

  </div> -->
  <div class="right">
    <div class="ag-scroll-manage">
        <mat-stepper labelPosition="bottom" #stepper>
            <mat-step>
              <app-period-track (onClickContinue)="stepperRoute($event)"></app-period-track>
            </mat-step>
            <mat-step>
              <app-period-track2 *ngIf="periodtrack" (onClickContinue)="stepperRoute($event)">
              </app-period-track2>
            </mat-step>
            <mat-step>
              <app-period-track3 *ngIf="periodtrack2" (onClickContinue)="stepperRoute($event)">
              </app-period-track3>
            </mat-step>
          </mat-stepper>
    </div>
    
  </div>

  <div class="down2">
    <p>Womean Inc. 2022 All rights reserved</p>
  </div>
</div>
