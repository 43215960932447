<div class="body">
  <div class="wrapper">
    <app-shared-header [navigationUrls]="navigationUrls" [showSocialMediaLink]="false"></app-shared-header>

    <div class="container">

      <div class="row align-items-center">
        <div class="col-md-6">
          <div _ngcontent-pst-c222="" class="image"><img _ngcontent-pst-c222="" src="\assets\images\SCROLL3.png" alt=""
              class="img-aboutUs"></div>
          <p class="header-title">Hey there! {{userName}}</p>
          <p class="header-title-sub">Let us help you be regular attracking your cycles</p>
        </div>
        <div class="col-md-6">
          <div class="card1 card align-items-center" style="max-width: 430px;" *ngFor="let modules of trackModules">
            <div class="card-img">
              <img src="{{ modules.image }}" alt="{{ modules.alt }}">
            </div>
            <div class="card-body" (click)="handleNavigation(modules.trackUrls)">
              <h5 class="card-title">{{ modules.trackName }}</h5>
              <p class="card-text">{{ modules.subtitle }}<a href="javascript:void(0)" (click)="handleNavigation(modules.trackUrls)"><img src="\assets\images\right-arrow-circle-2.png" alt=""></a></p>
              
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="row m-b-50">
        <div class="col-sm col-md-6 col-lg-6 col-xl-4 p-3 text-center" *ngFor="let modules of trackModules">
          <img src="{{ modules.image }}" alt="{{ modules.alt }}" height="140" width="auto" />
          <div (click)="handleNavigation(modules.trackUrls)" class="text-center mt-5 pt-4 pb-3 mx-auto trackCards">
            <p>
              {{ modules.trackName }}
            </p>
          </div>
        </div>
      </div> -->
    </div>
    <br />
    <app-footer></app-footer>
  </div>
</div>