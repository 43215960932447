<footer>
    <div class="container">
      <hr />
      <!-- <div class="social">
        <h4>Follow Us On Social Media</h4>
        <div class="social-links">
          <a *ngFor="let mediaLink of socialMediaLinksData" href="{{ mediaLink.link }}" target="_blank"
            class="insta-link"><img src="{{ mediaLink.image }}" alt="{{ mediaLink.alt }}" class="social-media-logo" /></a>
        </div>
      </div> -->
      <div class="footermenu">
        <div class="footer-grid">
          COMPANY
          <ul class="grid-list">
            <li><a href="#">About Womean</a></li>
            <li><a href="#">Media Center</a></li>
            <li><a href="#">Payment Support</a></li>
          </ul>
        </div>
        <div class="footer-grid">
          LEGAL POLICIES
          <ul class="grid-list">
            <li><a href="#">General Policies</a></li>
            <li><a href="#">Terms of Usage</a></li>
            <!-- <li><a href="#">Honour Code</a></li> -->
            <li><a href="#">Privacy Policy</a></li>
          </ul>
        </div>
        <div class="footer-grid">
          PRODUCTS
          <ul class="grid-list">
            <li><a href="#">Track Application</a></li>
            <li><a href="#">E-commerce</a></li>
            <li><a href="#">Connect</a></li>
            <li><a href="#">Read Module</a></li>
            <li><a href="#">Ecosystem</a></li>
          </ul>
        </div>
        <div class="footer-grid">
          CONTACT US
          <ul class="grid-list">
            <li><a href="#">SPS Home Sector 30</a></li>
            <li><a href="#">Gurugram Haryana</a></li>
            <li><a href="#">122022</a></li>
            <li><a href="#">+91-9677342253</a></li>
            <li><a href="#">@wearewomean</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="footmarks">
        <div class="row align-items-center">
          <div class="col-sm-6 col-xs-6 nopadding d-flex">
            <!-- <input type="text" id="btn-text" placeholder="" />
            <input type="button" class="btn-send" value="Send" /> -->
          </div>
          <div class="col-sm-6 col-xs-6 nopadding div-footer-text">
            <!-- <button type="button" class="btn1-admin right" value="Admin" routerLink="/AdminLogin">Admin</button> -->
            <p style="font-size:13px ;">Womean Inc. 2022 All rights reserved</p>
          </div>
        </div>
        <!-- <div class="btn-footer">
          <input type="text" id="btn-text" placeholder="Write to us directly" />
          <input type="button" class="btn-send" value="Send" />
        </div>
        <div class="down">
          <p>CreateToCure.Group.2022</p>
          <p>All rights reserved</p>
        </div> -->
      </div>
    </div>
  </footer>