<div>
    <form #PregnancyTrack2="ngForm" (ngSubmit)="onSubmit(PregnancyTrack2.value)" class="formExtension">
        <h1 [@fadeInOnEnter] [@fadeOutOnLeave]>What usually is the Length of your Menstrual Cycle in Days?</h1>
        <p>(The menstrual cycle is the time from the first day of your period to the day before your next period.)</p>
        <div class="input-container text-center" [@fadeInOnEnter] [@fadeOutOnLeave]>
            <input appOnlyNumerics type="text" pattern="[0-9]+" class="input-box" [(ngModel)]="MenstrualCycle" placeholder="e.g. 28"
                maxlength="2" (keyup)="countNumber($event)" name="Menstrual" #Menstrual="ngModel" ngModel>
            <div class="text-danger" *ngIf="showMsg">
                <i class="fa-sharp fa-solid fa-circle-exclamation"></i>
                Length of your Menstrual Cycle should not be more than 50 days
            </div>
        </div>

        <div *ngIf="continueHide" class="ag-continue" [@fadeInOnEnter] [@fadeOutOnLeave]>
            <button type="button" class="back" (click)="goBack()">Previous</button>
            <button type="submit" class="Continue" 
            (click)="onSubmit(PregnancyTrack2.value)">Continue</button>
        </div>
    </form>
</div>