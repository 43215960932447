import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'womean-progress-bar',
  templateUrl: './womean-progress-bar.component.html',
  styleUrls: ['./womean-progress-bar.component.scss']
})
export class WomeanProgressBarComponent implements OnInit {

  constructor() { }
  @Input() public completed:number=50
  @Input() public img:string='facebook.png'

  ngOnInit(): void {
    this.img=`assets/images/${this.img}`
  }

}
