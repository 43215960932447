
// Signin user credentials declaration
export class User {
    email: string; 
    password: string;
    constructor(email: string, password: string) {
      this.email = email
      this.password = password
    }
  }

  // Signin user credentials declaration
export class ValidateOTP {
  id: string; 
  otp: string;
  constructor(id: string, otp: string) {
    this.id = id
    this.otp = otp
  }
}
  // Signin Response declaration
  export interface SigninResponse {
    data: string
    error : string
    status: boolean
    uid:string
    email:string
  }

  // Signup user Details declaration
  export class SignupUser {
    email: string
    phone: string
    password: string
    confirmPassword: string
    roles:string
    constructor(email: string, phone: string, password: string, confirmPassword: string,roles:string) {
      this.email = email
      this.phone = phone
      this.password = password
      this.confirmPassword = confirmPassword
      this.roles = roles
    }
  }
  export class EmailUser {
    field: string

    constructor(field: string,) {
      this.field = field
    }
  }

  export class PasswodResetUser {
    email: string
    password: string
    confirmPassword: string
    
    constructor(email: string,password: string, confirmPassword: string) {
      this.email = email
      this.password = password
      this.confirmPassword = confirmPassword
    }
  }
// Signup user (create user) credentials declaration
  export interface SignupResponse {
    data: string
    error : string
    status: boolean

  }
// Register user Details declaration
  export class UserDetails {
    pId:string
    firstName:string
    middleName:string
    lastName:string
    email:string
    heightInCm:string
    heightInInches:string
    weightInKgs:string
    weightInLbs:string
    gender:string
    dob:number
    constructor(pId: string, firstName:string, middleName:string, lastName:string, email:string, heightInCm:string, heightInInches:string, weightInKgs:string, weightInLbs:string, gender:string, dob:number) {
        this.pId = pId
        this.firstName = firstName
        this.middleName = middleName
        this.lastName = lastName
        this.email = email
        this.heightInCm = heightInCm
        this.heightInInches = heightInInches
        this.weightInKgs = weightInKgs
        this.weightInLbs = weightInLbs
        this.gender = gender
        this.dob = dob
      }
  }
// Register user Response declaration
    export interface RegisterResponse {
    data: string
    error : string
    status: boolean

  }
  export class Track{
    private email: string;
    private id: string;
    private periodTracking: boolean;
    private onPeriod: boolean;
    private dayOfPeriod: number;
    private lastPeriodStartDate: number;
    private lastPeriodEndDate: number;
    private havingCramps: boolean;
    private havingPms: boolean;
    private crampSeverity: number;
    private pmsSeverity: number;
    private assumedPeriodCycle: number;
    private pregnancyTracking: boolean;
    private pregnant: boolean;
    private methodOfEstimation: string;
    private dateOfConception: number;
    private dueDate: number;
    private wantToGetPregnant: boolean;
    private ovulationTracking: boolean;
    private toNotify: boolean;

    public get e_mail(): string {
      return this.email;
    }
    public set e_mail(value: string) {
      this.email = value;
    }

    public get p_Id(): string {
      return this.id;
    }
    public set p_Id(value: string) {
      this.id = value;
    }
    
    public get period_Tracking(): boolean {
      return this.periodTracking;
    }
    public set period_Tracking(value: boolean) {
      this.periodTracking = value;
    }
    public get on_Period(): boolean {
      return this.onPeriod;
    }
    public set on_Period(value: boolean) {
      this.onPeriod = value;
    }
    public get day_OfPeriod(): number {
      return this.dayOfPeriod;
    }
    public set day_OfPeriod(value: number) {
      this.dayOfPeriod = value;
    }
    public get last_PeriodStartDate(): number {
      return this.lastPeriodStartDate;
    }
    public set last_PeriodStartDate(value: number) {
      this.lastPeriodStartDate = value;
    }
    public get last_PeriodEndDate(): number {
      return this.lastPeriodEndDate;
    }
    public set last_PeriodEndDate(value: number) {
      this.lastPeriodEndDate = value;
    }
    public get having_Cramps(): boolean {
      return this.havingCramps;
    }
    public set having_Cramps(value: boolean) {
      this.havingCramps = value;
    }

    public get having_Pms(): boolean {
      return this.havingPms;
    }
    public set having_Pms(value: boolean) {
      this.havingPms = value;
    }
 
    public get cramp_Severity(): number {
      return this.crampSeverity;
    }
    public set cramp_Severity(value: number) {
      this.crampSeverity = value;
    }
    public get pms_Severity(): number {
      return this.pmsSeverity;
    }
    public set pms_Severity(value: number) {
      this.pmsSeverity = value;
    }

    public get assumed_PeriodCycle(): number {
      return this.assumedPeriodCycle;
    }
    public set assumed_PeriodCycle(value: number) {
      this.assumedPeriodCycle = value;
    }

    public get pregnancy_Tracking(): boolean {
      return this.pregnancyTracking;
    }
    public set pregnancy_Tracking(value: boolean) {
      this.pregnancyTracking = value;
    }

    public get pregnant1(): boolean {
      return this.pregnant;
    }
    public set pregnant1(value: boolean) {
      this.pregnant = value;
    }

    public get method_OfEstimation(): string {
      return this.methodOfEstimation;
    }
    public set method_OfEstimation(value: string) {
      this.methodOfEstimation = value;
    }

    public get date_OfConception(): number {
      return this.dateOfConception;
    }
    public set date_OfConception(value: number) {
      this.dateOfConception = value;
    }
 
    public get due_Date(): number {
      return this.dueDate;
    }
    public set due_Date(value: number) {
      this.dueDate = value;
    }

    public get want_ToGetPregnant(): boolean {
      return this.wantToGetPregnant;
    }
    public set want_ToGetPregnant(value: boolean) {
      this.wantToGetPregnant = value;
    }
 
    public get ovulation_Tracking(): boolean {
      return this.ovulationTracking;
    }
    public set ovulation_Tracking(value: boolean) {
      this.ovulationTracking = value;
    }
    public get to_Notify(): boolean {
      return this.toNotify;
    }
    public set to_Notify(value: boolean) {
      this.toNotify = value;
    }
  }

  export class AdminDetails{
    id: string
    email: string
    createdOn: number
    parentChain: string
    header: string

    constructor(id:string , email: string, createdOn: number, parentChain: string ,header:string){
      this.id=id
      this.email=email
      this.createdOn=createdOn
      this.parentChain=parentChain
      this.header=header
    }
  }