import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { UserPrefernceService } from '../core/services/user-prefernce.service';
import { PeriodTrack3Component } from '../period-track3/period-track3.component';
import { RestService } from '../rest.service';

@Component({
  selector: 'app-period-track4',
  templateUrl: './period-track4.component.html',
  styleUrls: ['./period-track4.component.scss'],
})
export class PeriodTrack4Component implements OnInit {
  PeriodTrack3Component = PeriodTrack3Component;
  static userName: any;
  start_day: String;
  start_month: String;
  start_year: String;
  end_day: String;
  end_month: String;
  end_year: String;
  nextPeriodDate_day: String;
  nextPeriodDate_month: String;
  nextPeriodDate_year: String;
  nextPeriodDate: any;
  start: string;
  end: string;
  estimatedDays: number;
  weeksOfPregnancy: any;
  estimatedWeeksPercent: number;
  cycleCompletedPercentage: number;
  daysRemainingInThisPeriod: number;
  daysUntilNextPeriod: number;
  brogressBarImage: string = 'period_dashboard.png';
  selectedRangeValue: any;
  trackerRes: any
  pId: any;
  email: any;
  onPeriod: boolean
  dateModel: any = [];
  @Output() selectedRangeValueChange = new EventEmitter<DateRange<Date>>();
  priorPeriodDates: any = [];
  constructor(private rest: RestService, private router: Router, private userPreferenceService: UserPrefernceService) { }

  async ngOnInit(): Promise<void> {
    this.pId = this.userPreferenceService.getCurrentUserJti();
    PeriodTrack4Component.userName = this.pId;
    const res = await this.rest.getperiodstrackerById(this.pId);
    console.log('response Data : ' + JSON.stringify(res.data));
    this.trackerRes = res
    if (res.status) {
      this.cycleCompletedPercentage = 100 - ((res.data.daysUntilNextPeriod * 100) / res.data.assumedPeriodCycle)
      this.daysUntilNextPeriod = res.data.daysUntilNextPeriod
      this.daysRemainingInThisPeriod = res.data.daysRemainingInThisPeriod
      this.onPeriod = res.data?.onPeriod
      if (res.data.lastPeriodStartDate && res.data.lastPeriodEndDate) {
        this.start_day = String(new Date(res.data.lastPeriodStartDate).getDate()).padStart(2, '0');
        this.start_month = String(new Date(res.data.lastPeriodStartDate).getMonth() + 1).padStart(2, '0'); //January is 0!
        this.start_year = String(new Date(res.data.lastPeriodStartDate).getFullYear()).padStart(2, '0');
        this.start = this.start_year + '-' + this.start_month + '-' + this.start_day;

        this.end_day = String(new Date(res.data.lastPeriodEndDate).getDate()).padStart(2, '0');
        this.end_month = String(new Date(res.data.lastPeriodEndDate).getMonth() + 1).padStart(2, '0'); //January is 0!
        this.end_year = String(new Date(res.data.lastPeriodEndDate).getFullYear()).padStart(2, '0');
        this.end = this.end_year + '-' + this.end_month + '-' + this.end_day;

        this.selectedRangeValue = new DateRange(moment(this.start), moment(this.end));
        console.log(this.selectedRangeValue);
      }
      if (res.data.nextPeriodDate) {
        res.data.nextPeriodDate.forEach((element: any, index: any) => {
          console.log(index)
          this.nextPeriodDate_day = String(new Date(element).getDate()).padStart(2, '0');
          this.nextPeriodDate_month = String(new Date(element).getMonth() + 1).padStart(2, '0'); //January is 0!
          this.nextPeriodDate_year = String(new Date(element).getFullYear()).padStart(2, '0');
          this.nextPeriodDate = this.nextPeriodDate_month + '-' + this.nextPeriodDate_day + '-' + this.nextPeriodDate_year;
          this.dateModel.push(new Date(this.nextPeriodDate))
          if (res.data.nextPeriodDate.length > 1) {
            if (index == res.data.nextPeriodDate.length - 2 || index == res.data.nextPeriodDate.length - 3 || index == res.data.nextPeriodDate.length - 4)
              this.priorPeriodDates.push(this.nextPeriodDate_day + '-' + this.nextPeriodDate_month + '-' + this.nextPeriodDate_year)
          }
        });
        // this.priorPeriodDates.push({
        //   date1 : 
        // })
        // this.nextPeriodDate_day = String(new Date(res.data.nextPeriodDate[res.data.nextPeriodDate.length - 1]).getDate()).padStart(2, '0');
        // this.nextPeriodDate_month = String(new Date(res.data.nextPeriodDate[res.data.nextPeriodDate.length - 1]).getMonth() + 1).padStart(2, '0'); //January is 0!
        // this.nextPeriodDate_year = String(new Date(res.data.nextPeriodDate[res.data.nextPeriodDate.length - 1]).getFullYear()).padStart(2, '0');
        // this.nextPeriodDate = this.nextPeriodDate_year + '-' + this.nextPeriodDate_month + '-' + this.nextPeriodDate_day;
        // this.nextPeriodDate = moment(this.nextPeriodDate)
      }

    }
  }

  navigationUrls = [
    {
      urls: 'Home Page',
      link: '/homepage',
      icons: 'fa fa-home',
      active: false
    },
    {
      urls: 'The Initiative',
      link: '/initiative',
      icons: 'fa fa-file',
      active: false
    },
    {
      urls: 'About Us',
      link: '/aboutus',
      icons: 'fa fa-info-circle',
      active: false
    },
    {
      urls: 'Contact Us',
      link: '/contactus',
      icons: 'fa fa-phone',
      active: false
    },
  ];

  async edit() {
    //this.trackerRes = {"data":{"id":"dff962ba-fda0-4090-8010-0de1e3b06f8d","email":"akashdeepmitra@womean.org","periodTracking":true,"onPeriod":true,"dayOfPeriod":54,"lastPeriodStartDate":1673119598538,"lastPeriodEndDate":0,"havingCramps":true,"havingPms":true,"crampSeverity":3,"pmsSeverity":6,"assumedPeriodCycle":21,"pregnancyTracking":false,"pregnant":false,"methodOfEstimation":null,"weeksOfPregnant":null,"estimatedDueDate":0,"dateOfConception":0,"dueDate":0,"weekText":null,"wantToGetPregnant":false,"ovulationTracking":false,"dayOfOvulation":0,"fertileWindowStart":0,"fertileWindowEnds":0,"periodLength":0,"nextPeriodDate":1674933998538,"pregnancyTestDate":0,"toNotify":false,"daysUntilNextPeriod":32,"daysRemainingInThisPeriod":-49,"daysUntilDueDate":0,"totalPregnancyDuration":0,"daysUntilFertilityWindowStrts":0,"daysUntilFertilityWindowEnds":0,"daysUntilOvulationDay":0},"error":null,"status":true}
    this.rest.setPeriodTrackEditData(this.trackerRes)
    this.router.navigate(["/period-steps"]);
  }

  async handleNavigation(module: string) {
    let sector = ''
    let trackName = module.replace("/", "")
    if (trackName == 'period-steps') sector = 'PERIODS'
    else if (trackName == 'PregnancySteps') sector = 'PREGNANCY'
    else sector = 'OVULATION'
    this.email = this.userPreferenceService.getCurrentUserEmailId();
    this.pId = this.userPreferenceService.getCurrentUserJti();
    if (this.pId != null || this.pId != undefined) {
      let check = await this.rest.trackPostCore(this.pId, this.email, sector);
      if (check.status) {
        if (check.data.tracked) {
          if (trackName == 'period-steps') this.router.navigate(['/period-track4']) //track dashboard
          else if (trackName == 'PregnancySteps') this.router.navigate(['/PregnancyTrack4']) //track dashboard
          else this.router.navigate(['/OvulationTrack3']) //track dashboard
        }
        else {
          this.router.navigate([module]);
        }
      }
    }

  }
}
