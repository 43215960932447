import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ContinueComponent } from '../continue/continue.component';
import { UserPrefernceService } from '../core/services/user-prefernce.service';
import { OvulationTrackComponent } from '../ovulation-track/ovulation-track.component';
import { ResponseData } from '../responsedata-class';
import { RestService } from '../rest.service';
import { SigninComponent } from '../signin/signin.component';

@Component({
  selector: 'app-ovulation-track3',
  templateUrl: './ovulation-track3.component.html',
  styleUrls: ['./ovulation-track3.component.scss']
})
export class OvulationTrack3Component implements OnInit {
  static userName: any;
  start_day: String;
  start_month: String;
  start_year: String;
  end_day: String;
  end_month: String;
  end_year: String;
  fertileWindowStart_day: String;
  fertileWindowStart_month: String;
  fertileWindowStart_year: String;
  fertileWindowEnd_day: String;
  fertileWindowEnd_month: String;
  fertileWindowEnd_year: String;
  dayOfOvulation_day: String;
  dayOfOvulation_month: String;
  dayOfOvulation_year: String;
  nextPeriodDate_day: String;
  nextPeriodDate_month: String;
  nextPeriodDate_year: String;
  dayOfOvulation: any;
  start: any;
  end: any;
  data: ResponseData;
  dueDate: any;
  estimatedDays: number;
  assumedPeriodCycle: any;
  estimatedWeeksPercent: number;
  fertileWindowStart: any;
  fertileWindowEnd: any;
  fertility: any;
  selectedRangeValue: any;
  selectedfertileWindowRangeValue: any;
  cycleCompletedPercentage: number
  brogressBarImage: string = 'ovalution_dashboard.png';
  daysUntilFertilityWindowStrts: number;
  daysUntilFertilityWindowEnds: number;
  daysUntilOvulationDay: number;
  daysRemainingInThisPeriod: number;
  onPeriod: boolean
  trackerRes: any
  pId: any;
  email: any;
  dateModel: any = [];
  priorPeriodDates: any = [];
  @Output() selectedRangeValueChange = new EventEmitter<DateRange<Date>>();
  constructor(private router: Router, private rest: RestService, private userPrefernceService: UserPrefernceService) { }

  async ngOnInit(): Promise<void> {
    let id = this.userPrefernceService.getCurrentUserJti();
    OvulationTrack3Component.userName = id;
    const res = await this.rest.getovulationtrackerById(id);
    this.trackerRes = res
    if (res.status) {
      this.cycleCompletedPercentage = 100 - ((res.data.daysUntilNextPeriod * 100) / res.data.assumedPeriodCycle)
      this.daysUntilFertilityWindowStrts = res.data.daysUntilFertilityWindowStarts
      console.log(res.data)
      this.daysUntilFertilityWindowEnds = res.data.daysUntilFertilityWindowEnds
      this.daysUntilOvulationDay = res.data.daysUntilOvulationDay
      this.daysRemainingInThisPeriod = res.data.daysRemainingInThisPeriod
      this.onPeriod = res.data?.onPeriod
      if (res.data.lastPeriodStartDate && res.data.lastPeriodEndDate) {
        this.start_day = String(new Date(res.data.lastPeriodStartDate).getDate()).padStart(2, '0');
        this.start_month = String(new Date(res.data.lastPeriodStartDate).getMonth() + 1).padStart(2, '0'); //January is 0!
        this.start_year = String(new Date(res.data.lastPeriodStartDate).getFullYear()).padStart(2, '0');
        this.start = this.start_year + '-' + this.start_month + '-' + this.start_day;

        this.end_day = String(new Date(res.data.lastPeriodEndDate).getDate()).padStart(2, '0');
        this.end_month = String(new Date(res.data.lastPeriodEndDate).getMonth() + 1).padStart(2, '0'); //January is 0!
        this.end_year = String(new Date(res.data.lastPeriodEndDate).getFullYear()).padStart(2, '0');
        this.end = this.end_year + '-' + this.end_month + '-' + this.end_day;

        this.selectedRangeValue = new DateRange(moment(this.start), moment(this.end));
      }
      if (res.data.fertileWindowStart) {
        this.fertileWindowStart_day = String(new Date(res.data.fertileWindowStart[res.data.fertileWindowStart.length - 1]).getDate()).padStart(2, '0');
        this.fertileWindowStart_month = String(new Date(res.data.fertileWindowStart[res.data.fertileWindowStart.length - 1]).getMonth() + 1).padStart(2, '0'); //January is 0!
        this.fertileWindowStart_year = String(new Date(res.data.fertileWindowStart[res.data.fertileWindowStart.length - 1]).getFullYear()).padStart(2, '0');
        this.fertileWindowStart = this.fertileWindowStart_year + '-' + this.fertileWindowStart_month + '-' + this.fertileWindowStart_day;

        this.fertileWindowEnd_day = String(new Date(res.data.fertileWindowEnds[res.data.fertileWindowEnds.length - 1]).getDate()).padStart(2, '0');
        this.fertileWindowEnd_month = String(new Date(res.data.fertileWindowEnds[res.data.fertileWindowEnds.length - 1]).getMonth() + 1).padStart(2, '0'); //January is 0!
        this.fertileWindowEnd_year = String(new Date(res.data.fertileWindowEnds[res.data.fertileWindowEnds.length - 1]).getFullYear()).padStart(2, '0');
        this.fertileWindowEnd = this.fertileWindowEnd_year + '-' + this.fertileWindowEnd_month + '-' + this.fertileWindowEnd_day;

        this.selectedfertileWindowRangeValue = new DateRange(moment(this.fertileWindowStart), moment(this.fertileWindowEnd));
        console.log(this.selectedfertileWindowRangeValue)
      }
      if (res.data.dayOfOvulation) {
        res.data.dayOfOvulation.forEach((element: any, index: any) => {
          this.dayOfOvulation_day = String(new Date(element).getDate()).padStart(2, '0');
          this.dayOfOvulation_month = String(new Date(element).getMonth() + 1).padStart(2, '0'); //January is 0!
          this.dayOfOvulation_year = String(new Date(element).getFullYear()).padStart(2, '0');
          this.dayOfOvulation = this.dayOfOvulation_month + '-' + this.dayOfOvulation_day + '-' + this.dayOfOvulation_year;
          
          this.dateModel.push(new Date(this.dayOfOvulation))
          if (res.data.dayOfOvulation.length > 1) {
            if (index == res.data.dayOfOvulation.length - 2 || index == res.data.dayOfOvulation.length - 3 || index == res.data.dayOfOvulation.length - 4){
              this.priorPeriodDates.push(this.dayOfOvulation_day + '-' + this.dayOfOvulation_month + '-' + this.dayOfOvulation_year)
              console.log(element)
              console.log(this.dayOfOvulation_day + '-' + this.dayOfOvulation_month + '-' + this.dayOfOvulation_year)
            }
            }
        });
      } 
    }

  }

  navigationUrls = [
    {
      urls: 'Home Page',
      link: '/homepage',
      icons: 'fa fa-home',
      active: false
    },
    {
      urls: 'The Initiative',
      link: '/initiative',
      icons: 'fa fa-file',
      active: false
    },
    {
      urls: 'About Us',
      link: '/aboutus',
      icons: 'fa fa-info-circle',
      active: false
    },
    {
      urls: 'Contact Us',
      link: '/contactus',
      icons: 'fa fa-phone',
      active: false
    },
  ];

  async edit() {
    //this.trackerRes = {"data":{"id":"dff962ba-fda0-4090-8010-0de1e3b06f8d","email":"akashdeepmitra@womean.org","periodTracking":false,"onPeriod":false,"dayOfPeriod":0,"lastPeriodStartDate":1677695400000,"lastPeriodEndDate":1679077800000,"havingCramps":false,"havingPms":false,"crampSeverity":0,"pmsSeverity":0,"assumedPeriodCycle":45,"pregnancyTracking":false,"pregnant":true,"methodOfEstimation":"","weeksOfPregnant":null,"estimatedDueDate":0,"dateOfConception":0,"dueDate":0,"weekText":null,"wantToGetPregnant":true,"ovulationTracking":true,"dayOfOvulation":1680373800000,"fertileWindowStart":1680114600000,"fertileWindowEnds":1680460200000,"periodLength":0,"nextPeriodDate":0,"pregnancyTestDate":0,"toNotify":false,"daysUntilNextPeriod":44,"daysRemainingInThisPeriod":0,"daysUntilDueDate":0,"totalPregnancyDuration":0,"daysUntilFertilityWindowStrts":27,"daysUntilFertilityWindowEnds":31,"daysUntilOvulationDay":30},"error":null,"status":true}
    this.rest.setovulationTrackEditData(this.trackerRes)
    this.router.navigate(["/OvulationSteps"]);
  }

  async handleNavigation(module: string) {
    let sector = ''
    let trackName = module.replace("/", "")
    if (trackName == 'period-steps') sector = 'PERIODS'
    else if (trackName == 'PregnancySteps') sector = 'PREGNANCY'
    else sector = 'OVULATION'
    this.email = this.userPrefernceService.getCurrentUserEmailId();
    this.pId = this.userPrefernceService.getCurrentUserJti();
    if (this.pId != null || this.pId != undefined) {
      let check = await this.rest.trackPostCore(this.pId, this.email, sector);
      if (check.status) {
        if (check.data.tracked) {
          if (trackName == 'period-steps') this.router.navigate(['/period-track4']) //track dashboard
          else if (trackName == 'PregnancySteps') this.router.navigate(['/PregnancyTrack4']) //track dashboard
          else this.router.navigate(['/OvulationTrack3']) //track dashboard
        }
        else {
          this.router.navigate([module]);
        }
      }
    }
  }


}
