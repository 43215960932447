import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DateRange, MatCalendar, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import * as moment from 'moment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

class CustomDateAdapter extends MomentDateAdapter {
  override getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
    return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  }
}

export const MATERIAL_DATEPICKER_FORMATS = {
  parse: {
    dateInput: 'DD/MMM/YYYY',
  },
  display: {
    dateInput: 'DD/MMM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'DD/MMM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'womean-material-calendar',
  templateUrl: './womean-material-calendar.component.html',
  styleUrls: ['./womean-material-calendar.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script. 
    { provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MATERIAL_DATEPICKER_FORMATS },
  ],
})
export class WomeanMaterialCalendarComponent implements OnInit {
  @Input() type: 'Range' | 'Date' = 'Date'
  @ViewChild(MatCalendar, { static: false }) calendar: MatCalendar<Date>;
  @Input() selected: any
  @Input() dateModel: any = []
  @Output() selectedChange = new EventEmitter<any>();
  public selectedMonth: Date;
  public start: Date
  public end: Date
  day: number
  month: number
  year: number
  @Input() canclick: boolean = true
  constructor() { }

  ngOnInit(): void {
    if (this.selected?.start) {
      this.month = Number(moment(this.selected?.start).format('MM'));
      this.year = Number(moment(this.selected?.start).format('YYYY'));
      this.selectedMonth = new Date(this.year, (this.month-1), this.month)//January is 0!
    }
    else if (this.selected) {
      this.month = Number(moment(this.selected).format('MM'));
      this.year = Number(moment(this.selected).format('YYYY'));
      this.selectedMonth = new Date(this.year, (this.month-1), this.month)//January is 0!
    }
    //console.log(this.selectedMonth)
  }
  public model = [
    new Date('3/15/2023'),
    new Date('3/23/2023') 
  ];
  private _findDate(date: Date): number { 
    return this.dateModel.map((m: string | number) => +m).indexOf(+date);
  }

  public dateClass = (date: Date) => {
    if (this._findDate(date) !== -1) {
      return [ 'selected' ];
    }
    return [];
  }

  valueChange(m: any) {
    if (this.canclick) {
      if (this.type == 'Range') {
        if (!this.selected?.start || this.selected?.end) {
          this.selected = new DateRange<Date>(m, null);
        } else {
          this.start = this.selected.start;
          this.end = m;
          if (this.end < this.start) {
            this.selected = new DateRange<Date>(this.end, this.start);
          } else {
            this.selected = new DateRange<Date>(this.start, this.end);
          }
        }
      } else this.selected = m;
      console.log(this.start)
      this.selectedChange.emit(this.selected);
    }
  }
}
