<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please Wait... </p>
</ngx-spinner>
<form #PregnancyDetails="ngForm" class="formExtension" (ngSubmit)="onSubmit(PregnancyDetails.value)">
    <h1  [@fadeInOnEnter] [@fadeOutOnLeave] >Please Select How You Want to Track your Pregnancy?</h1>
    <div class="">
        <div class="input-container text-center">
            <div (click)="pP=true"  [@fadeInOnEnter] [@fadeOutOnLeave] >
                <button type="button" class="input-box icon-select" value="">{{trackPregnancy}}</button>
                <!-- <span class="icon2 fa-solid fa-caret-down ag-pointer"></span> -->
            </div>

            <div class="radio-tile-group" *ngIf="pP" [@fadeInOnEnter] [@fadeOutOnLeave] >
                <div class="input-container">
                    <button class="input-box" type="button" (click)="lastPeriodDate()">Last Period Date</button>
                </div>
                <div class="input-container">
                    <button class="input-box" type="button" (click)="dateOfConception()">Date of Conception</button>
                </div>
                <div class="input-container">
                    <button class="input-box" type="button" (click)="dueDate()">Due Date</button>
                </div>
            </div>
            <div class="" *ngIf="trackPregnancy=='Date of Conception'" [@fadeInOnEnter] [@fadeOutOnLeave] >
                <h1>Please Enter the Date of Conception</h1>

                <div (click)="handleInputStartDate('due');displayBox()">
                    <input type="text" value="{{ selectedValue | date: 'dd-MM-yyyy' }}" class="input-box"
                        [(ngModel)]="Conception_Date" placeholder="DD-MM-YYYY" name="dateOfConception"
                        #dateOfConception="ngModel" ngModel readonly (click)="displayBox()" />
                    <span class="ag-date ag-pointer" (click)="displayBox()" ></span>
                </div>
                <div class="modal" id="model_1" style="display:none;">
                    <div class="modal-content">
                        <h1>Enter your Date of Conception</h1>
                        <div class="mat-calendar-wrapper ag-calendar-wrapper">
                            <womean-material-calendar *ngIf="showCalendar" [@fadeInOnEnter] [@fadeOutOnLeave]  (selectedChange)="selectedChange($event)"
                                [(selected)]="selectedValue"></womean-material-calendar>
                        </div>
                        <div class="close">
                            <button type="button" (click)="closeBox()">Done <i class="fa fa-angle-right ml-1"></i></button>
                        </div>
                    </div>
                </div>
                <!-- <div class="d-flex justify-content-center">
                    <div class="calendar">
                        <div class="mat-calendar-wrapper ag-calendar-wrapper">
                            <womean-material-calendar *ngIf="showCalendar" (selectedChange)="selectedChange($event)"
                                [(selected)]="selectedValue"></womean-material-calendar>
                        </div>
                    </div>
                </div> -->
                <div *ngIf="(dateOfConception.touched && dateOfConception.valid) || Conception_Date"
                    class="ag-continue"  [@fadeInOnEnter] [@fadeOutOnLeave] >
                    <button type="button" class="back"  (click)="goBack()">Previous</button>
                    <button type="submit" class="Continue2" [disabled]="isLoading"><i
                            [ngClass]="{'fa fa-spinner fa-spin': isLoading}"  (click)="onSubmit(PregnancyDetails.value)"></i>&nbsp;{{button}}</button>
                </div>
            </div>
            <div class="" *ngIf="trackPregnancy=='Due Date'" [@fadeInOnEnter] [@fadeOutOnLeave] >
                <h1>Please Enter the Due Date</h1>

                <div (click)="handleInputStartDate('conception');displayBox2()">
                    <input type="text" value="{{ selectedValue | date: 'dd-MM-yyyy' }}" [(ngModel)]="due_Date"
                        class="input-box" placeholder="DD-MM-YYYY" name="dueDate" #dueDate="ngModel" ngModel readonly (click)="displayBox2()" />
                    <span class="ag-date ag-pointer"></span>
                </div>
                <div class="modal" id="model2_" style="display:none;">
                    <div class="modal-content">
                        <h1>Enter your Due Date</h1>
                        <div class="mat-calendar-wrapper ag-calendar-wrapper">
                            <womean-material-calendar *ngIf="showCalendar" [@fadeInOnEnter] [@fadeOutOnLeave]  (selectedChange)="selectedChange($event)"
                                [selected]="selectedValue"></womean-material-calendar>
                        </div>
                        <div class="close">
                            <button  type="button" (click)="closeBox2()">Done <i class="fa fa-angle-right ml-1"></i></button>
                        </div>
                    </div>
                </div>
                <!-- <div class="d-flex justify-content-center">
                    <div class="calendar">
                        <div class="mat-calendar-wrapper ag-calendar-wrapper">
                            <womean-material-calendar *ngIf="showCalendar" (selectedChange)="selectedChange($event)"
                                [selected]="selectedValue"></womean-material-calendar>
                        </div>
                    </div>
                </div> -->
                <div  [@fadeInOnEnter] [@fadeOutOnLeave]  *ngIf="(dueDate.touched && dueDate.valid) || due_Date" class="ag-continue">
                    <button type="button" class="back"  (click)="goBack()">Previous</button>
                    <button type="submit" class="Continue2" [disabled]="isLoading"  [@fadeInOnEnter] [@fadeOutOnLeave] ><i
                            [ngClass]="{'fa fa-spinner fa-spin': isLoading}" (click)="onSubmit(PregnancyDetails.value)"></i>&nbsp;{{button}}</button>
                </div>
            </div>
        </div>
    </div>
</form>

<div *ngIf="trackPregnancy=='Last Period Date'" class="ag-continue" [@fadeInOnEnter] [@fadeOutOnLeave] >
    <button type="button" class="back"  (click)="goBack()">Previous</button>
    <button type="submit" class="Continue"  [@fadeInOnEnter] [@fadeOutOnLeave] 
        (click)="onSubmit(PregnancyDetails.value)"> {{button}}</button>
</div>
