import { Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AdminDetails, EmailUser, PasswodResetUser, RegisterResponse, SigninResponse, SignupResponse, SignupUser, Track, User, ValidateOTP } from './userCredentials';
import { AdminDetails1 } from './admin';
import { CacheDataService } from './core/services/cache-service/cache-data.service';
import { UserPrefernceService } from './core/services/user-prefernce.service'
import { QueryRequestBody } from './contact-us';
import { environment } from "src/environments/environment";
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

/*
*[03/09/2022]  Change Log :- Intial Create of api call backend services   Author :- Dilip Kumar Pottigari
*[01/10/2022]  Change Log :- Update of adding api call backend services for Track modules  Author :- Dilip Kumar Pottigari
*/

export class RestService {
  protected loginUrl: string = environment.loginUrl;
  protected trackUrl: string = environment.trackUrl;
  protected readUrl: string = environment.readUrl;
  private periodTrackEditData = new BehaviorSubject<any>({});
  private pregnancyTrackEditData = new BehaviorSubject<any>({});
  private ovulationTrackEditData = new BehaviorSubject<any>({});
  constructor(private http: HttpClient, private route: Router,
    private cacheDataService: CacheDataService,
    private userPrefernceService: UserPrefernceService) { }

  setPeriodTrackEditData(data: any) {
    return this.periodTrackEditData.next(data);
  }

  getpregnancyTrackEditData() {
    return this.pregnancyTrackEditData.asObservable();
  }

  setpregnancyTrackEditData(data: any) {
    return this.pregnancyTrackEditData.next(data);
  }

  getPeriodTrackEditData() {
    return this.periodTrackEditData.asObservable();
  }

  setovulationTrackEditData(data: any) {
    return this.ovulationTrackEditData.next(data);
  }

  getovulationTrackEditData() {
    return this.ovulationTrackEditData.asObservable();
  }
  //Calling API for signin User

  async onSignin(user: User): Promise<any> {
    //Creating body for signin user API
    const body = JSON.stringify(user);

    try {
      const response = await fetch(`${this.loginUrl}/login`,
        {
          method: 'POST',
          body: body,
          headers: {
            'Content-Type': 'application/json',
            'accept': '*/*'
          }
        });
      if (response.ok) {
        const result = (await response.json()) as SigninResponse;
        if (result.data) {
          //this.userPrefernceService.setToken(result.data);
          // this.route.navigate([
          //   'read'
          //   //this.cacheDataService.get(FeatureConstants.cacheKeys.module),
          // ]);
        }
        return result;
      } else {
        throw new Error(`Error! status: ${response.status}`);
      }
    }
    catch (error) {
      if (error instanceof Error) {
        console.log('error message: ', error.message);
        return error;
      } else {
        console.log('unexpected error: ', error);
        return 'An unexpected error occurred';
      }
    }
  }

  //Calling API for Create User
  async onSignUp(user: SignupUser): Promise<any> {

    //Creating body for create user API
    const body = JSON.stringify(user);
console.log(body)

    try {
      const response = await fetch(`${this.loginUrl}/create`,
        {
          method: 'POST',
          body: body,
          headers: {
            'Content-Type': 'application/json',
            'accept': '*/*'
          }
        });

      // Response ok 
      if (response.ok) {
        const result = (await response.json()) as SignupResponse;
        return result;
      } else {
        throw new Error(`Error! status: ${response.status}`);
      }
    }
    catch (error) {
      if (error instanceof Error) {
        console.log('error message: ', error.message);
        return error;
      } else {
        console.log('unexpected error: ', error);
        return 'An unexpected error occurred';
      }
    }
  }


  async onValidateEmail(user: EmailUser): Promise<any> {

    //Creating body for create user API
    const body = JSON.stringify(user);
    console.log(body)
    try {
      const response = await fetch(`${this.loginUrl}/validate-email`,
        {
          method: 'POST',
          body: body,
          headers: {
            'Content-Type': 'application/json',
            'accept': '*/*'
          }
        });

      // Response ok 
      if (response.ok) {
        const result = (await response.json()) as SignupResponse;
        return result;
      } else {
        throw new Error(`Error! status: ${response.status}`);
      }
    }
    catch (error) {
      if (error instanceof Error) {
        console.log('error message: ', error.message);
        return error;
      } else {
        console.log('unexpected error: ', error);
        return 'An unexpected error occurred';
      }
    }
  }

  async onResetPassword(user: PasswodResetUser): Promise<any> {

    //Creating body for create user API
    const body = JSON.stringify(user);
    console.log(body)
    try {
      const response = await fetch(`${this.loginUrl}/resetPassword`,
        {
          method: 'POST',
          body: body,
          headers: {
            'Content-Type': 'application/json',
            'accept': '*/*'
          }
        });

      // Response ok 
      if (response.ok) {
        const result = (await response.json()) as SignupResponse;
        return result;
      } else {
        throw new Error(`Error! status: ${response.status}`);
      }
    }
    catch (error) {
      if (error instanceof Error) {
        console.log('error message: ', error.message);
        return error;
      } else {
        console.log('unexpected error: ', error);
        return 'An unexpected error occurred';
      }
    }
  }


  async onValidateOtp(user: ValidateOTP): Promise<any> {

    //Creating body for create user API
    const body = JSON.stringify(user);
    console.log(body)
    try {
      const response = await fetch(`${this.loginUrl}/validate-otp`,
        {
          method: 'POST',
          body: body,
          headers: {
            'Content-Type': 'application/json',
            'accept': '*/*'
          }
        });

      // Response ok 
      if (response.ok) {
        const result = (await response.json()) as SignupResponse;
        return result;
      } else {
        throw new Error(`Error! status: ${response.status}`);
      }
    }
    catch (error) {
      if (error instanceof Error) {
        console.log('error message: ', error.message);
        return error;
      } else {
        console.log('unexpected error: ', error);
        return 'An unexpected error occurred';
      }
    }
  }
  async onResendOtp(user: ValidateOTP): Promise<any> {

    //Creating body for create user API
    const body = JSON.stringify(user);
    console.log(body)
    try {
      const response = await fetch(`${this.loginUrl}/resend-otp`,
        {
          method: 'POST',
          body: body,
          headers: {
            'Content-Type': 'application/json',
            'accept': '*/*'
          }
        });

      // Response ok 
      if (response.ok) {
        const result = (await response.json()) as SignupResponse;
        return result;
      } else {
        throw new Error(`Error! status: ${response.status}`);
      }
    }
    catch (error) {
      if (error instanceof Error) {
        console.log('error message: ', error.message);
        return error;
      } else {
        console.log('unexpected error: ', error);
        return 'An unexpected error occurred';
      }
    }
  }



  //Calling API for register User
  async onRegister(persistenceId: any, firstName: any, middleName: any, lastName: any, email: any, heightInCm: any, heightInInches: any, weightInKgs: any, weightInLbs: any, gender: any, dob: any, location: any, imageUrl: any): Promise<any> {
    //Creating body for register user API
    const body = JSON.stringify({
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      email: email,
      heightInCm: heightInCm,
      heightInInches: heightInInches,
      weightInKgs: weightInKgs,
      weightInLbs: weightInLbs,
      gender: gender,
      dob: dob,
      location: location, imageUrl: imageUrl
    });
    try {
      const response = await fetch(`${this.loginUrl}/profile/register`,
        {
          method: 'POST',
          body: body,
          headers: {
            'Content-Type': 'application/json', 'userName': persistenceId,
            'accept': '*/*'
          }
        });
      if (response.ok) {
        const result = (await response.json()) as RegisterResponse;
        return result;
      } else {
        throw new Error(`Error! status: ${response.status}`);
      }
    }
    catch (error) {
      if (error instanceof Error) {
        console.log('error message: ', error.message);
        return error;
      } else {
        console.log('unexpected error: ', error);
        return 'An unexpected error occurred';
      }
    }
  }


  async getByUserName(userName: String): Promise<any> {

    //Creating body for create user API

    try {
      const token = this.userPrefernceService.getToken();
      const response = await fetch(`${this.loginUrl}/profile/` + userName,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'token': token
          }
        });

      // Response ok 
      if (response.ok) {
        const result = (await response.json()) as SignupResponse;
        return result;
      } else {
        throw new Error(`Error! status: ${response.status}`);
      }
    }
    catch (error) {
      if (error instanceof Error) {
        console.log('error message: ', error.message);
        return error;
      } else {
        console.log('unexpected error: ', error);
        return 'An unexpected error occurred';
      }
    }
  }



  async onModify(pId: any, firstName: any, middleName: any, lastName: any, email: any, heightInCm: any, heightInInches: any, weightInKgs: any, weightInLbs: any, gender: any, dob: any, location: any, imageUrl: any): Promise<any> {
    //Creating body for register user API
    const body = JSON.stringify({
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      email: email,
      heightInCm: heightInCm,
      heightInInches: heightInInches,
      weightInKgs: weightInKgs,
      weightInLbs: weightInLbs,
      gender: gender,
      dob: dob,
      location: location, imageUrl: imageUrl
    });
    try {
      const token = this.userPrefernceService.getToken();
      const response = await fetch(`${this.loginUrl}/profile/modify/` + pId,
        {
          method: 'PUT',
          body: body,
          headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'token': token
          }
        });
      if (response.ok) {
        const result = (await response.json()) as RegisterResponse;
        return result;
      } else {
        throw new Error(`Error! status: ${response.status}`);
      }
    }
    catch (error) {
      if (error instanceof Error) {
        console.log('error message: ', error.message);
        return error;
      } else {
        console.log('unexpected error: ', error);
        return 'An unexpected error occurred';
      }
    }
  }






  async track(track: Track): Promise<any> {

    //Creating body for create user API
    const body = JSON.stringify(track);

    try {
      const token = this.userPrefernceService.getToken();
      const response = await fetch(`${this.trackUrl}/core`,
        {
          method: 'PUT',
          body: body,
          headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'token': token
          }
        });
      // Response ok 
      if (response.ok) {
        const result = (await response.json()) as SignupResponse;
        return result;

      } else {
        throw new Error(`Error! status: ${response.status}`);
      }
    }
    catch (error) {
      if (error instanceof Error) {
        console.log('error message: ', error.message);
        return error;
      } else {
        console.log('unexpected error: ', error);
        return 'An unexpected error occurred';
      }
    }
  }


  async getById(id: string): Promise<any> {
    try {
      const token = this.userPrefernceService.getToken();
      const response = await fetch(`${this.trackUrl}/core/get-trackers/` + id,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'token': token
          }
        });

      // Response ok 
      if (response.ok) {
        const result = (await response.json()) as SignupResponse;
        return result;

      } else {
        throw new Error(`Error! status: ${response.status}`);
      }
    }
    catch (error) {
      if (error instanceof Error) {
        console.log('error message: ', error.message);
        return error;
      } else {
        console.log('unexpected error: ', error);
        return 'An unexpected error occurred';
      }
    }
  }

  /*postcore Api to generate data of Trtack Module*/
  async trackPostCore(pId: string, email: string, sector: string): Promise<any> {
    try {
      const body = JSON.stringify({ email: email, id: pId, sector: sector });
      const token = this.userPrefernceService.getToken();
      const response = await fetch(`${this.trackUrl}/core`,
        {
          method: 'POST',
          body: body,
          headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'token': token
          }
        });

      // Response ok 
      if (response.ok) {
        const result = (await response.json()) as SignupResponse;
        return result;
      } else {
        throw new Error(`Error! status: ${response.status}`);
      }
    }
    catch (error) {
      if (error instanceof Error) {
        console.log('error message: ', error.message);
        return error;
      } else {
        console.log('unexpected error: ', error);
        return 'An unexpected error occurred';
      }
    }
  }
  async postCore(adminDetails: AdminDetails): Promise<any> {

    //Creating body for create user API
    const body = JSON.stringify(adminDetails);

    try {
      const response = await fetch(this.readUrl,
        {
          method: 'POST',
          body: body,
          headers: {
            'Content-Type': 'application/json',
            'accept': '*/*'
          }
        });

      // Response ok 
      if (response.ok) {

        const result = (await response.json()) as SignupResponse;
        return result;
      } else {
        throw new Error(`Error! status: ${response.status}`);
      }
    }
    catch (error) {
      if (error instanceof Error) {
        console.log('error message: ', error.message);
        return error;
      } else {
        console.log('unexpected error: ', error);
        return 'An unexpected error occurred';
      }
    }
  }
  async putCore(AdminDetails: AdminDetails1): Promise<any> {

    //
    const body = JSON.stringify(AdminDetails);

    try {
      const response = await fetch(this.readUrl,
        {
          method: 'PUT',
          body: body,
          headers: {
            'Content-Type': 'application/json',
            'accept': '*/*'
          }
        });

      // Response ok 
      if (response.ok) {

        const result = (await response.json()) as SignupResponse;
        return result;
      } else {
        throw new Error(`Error! status: ${response.status}`);
      }
    }
    catch (error) {
      if (error instanceof Error) {
        console.log('error message: ', error.message);
        return error;
      } else {
        console.log('unexpected error: ', error);
        return 'An unexpected error occurred';
      }
    }

  }
  async raiseQuery(raiseQuery: QueryRequestBody): Promise<any> {

    //
    const body = JSON.stringify(raiseQuery);

    try {
      const response = await fetch(`${this.loginUrl}/raise-query`,
        {
          method: 'POST',
          body: body,
          headers: {
            'Content-Type': 'application/json',
            'accept': '*/*'
          }
        });

      // Response ok 
      if (response.ok) {

        const result = (await response.json()) as SignupResponse;
        return result;
      } else {
        throw new Error(`Error! status: ${response.status}`);
      }
    }
    catch (error) {
      if (error instanceof Error) {
        // console.log('error message: ', error.message);
        return error;
      } else {
        // console.log('unexpected error: ', error);
        return 'An unexpected error occurred';
      }
    }

  }

  async profileImageSave(imageData: any): Promise<any> {

    //

    try {
      const response = await fetch(`${this.loginUrl}/profile/save-image`,
        {
          method: 'POST',
          body: imageData,
          headers: { "Accept": "application/json" }
        });

      // Response ok 
      if (response.ok) {

        const result = (await response.json()) as SignupResponse;
        return result;
      } else {
        throw new Error(`Error! status: ${response.status}`);
      }
    }
    catch (error) {
      if (error instanceof Error) {
        // console.log('error message: ', error.message);
        return error;
      } else {
        // console.log('unexpected error: ', error);
        return 'An unexpected error occurred';
      }
    }

  }

  // periods-tracker
  async getperiodstrackerById(id: string): Promise<any> {
    try {
      const token = this.userPrefernceService.getToken();
      const response = await fetch(`${this.trackUrl}/core/periods/` + id,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'token': token
          }
        });

      // Response ok 
      if (response.ok) {
        const result = (await response.json()) as SignupResponse;
        return result;

      } else {
        throw new Error(`Error! status: ${response.status}`);
      }
    }
    catch (error) {
      if (error instanceof Error) {
        console.log('error message: ', error.message);
        return error;
      } else {
        console.log('unexpected error: ', error);
        return 'An unexpected error occurred';
      }
    }
  }

  async periodsUpdate(track: Track): Promise<any> {

    //Creating body for create user API
    const body = JSON.stringify(track);

    try {
      const token = this.userPrefernceService.getToken();
      const response = await fetch(`${this.trackUrl}/core/periods`,
        {
          method: 'PUT',
          body: body,
          headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'token': token
          }
        });
      // Response ok 
      if (response.ok) {
        const result = (await response.json()) as SignupResponse;
        return result;

      } else {
        throw new Error(`Error! status: ${response.status}`);
      }
    }
    catch (error) {
      if (error instanceof Error) {
        console.log('error message: ', error.message);
        return error;
      } else {
        console.log('unexpected error: ', error);
        return 'An unexpected error occurred';
      }
    }
  }

  // pregnancy-tracker
  async getpregnancytrackerById(id: string): Promise<any> {
    try {
      const token = this.userPrefernceService.getToken();
      const response = await fetch(`${this.trackUrl}/core/pregnancy/` + id,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'token': token
          }
        });

      // Response ok 
      if (response.ok) {
        const result = (await response.json()) as SignupResponse;
        return result;

      } else {
        throw new Error(`Error! status: ${response.status}`);
      }
    }
    catch (error) {
      if (error instanceof Error) {
        console.log('error message: ', error.message);
        return error;
      } else {
        console.log('unexpected error: ', error);
        return 'An unexpected error occurred';
      }
    }
  }

  async pregnancyUpdate(track: Track): Promise<any> {

    //Creating body for create user API
    const body = JSON.stringify(track);

    try {
      const token = this.userPrefernceService.getToken();
      const response = await fetch(`${this.trackUrl}/core/pregnancy`,
        {
          method: 'PUT',
          body: body,
          headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'token': token
          }
        });
      // Response ok 
      if (response.ok) {
        const result = (await response.json()) as SignupResponse;
        return result;

      } else {
        throw new Error(`Error! status: ${response.status}`);
      }
    }
    catch (error) {
      if (error instanceof Error) {
        console.log('error message: ', error.message);
        return error;
      } else {
        console.log('unexpected error: ', error);
        return 'An unexpected error occurred';
      }
    }
  }

  // ovulation-tracker
  async getovulationtrackerById(id: string): Promise<any> {
    try {
      const token = this.userPrefernceService.getToken();
      const response = await fetch(`${this.trackUrl}/core/ovulation/` + id,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'token': token
          }
        });

      // Response ok 
      if (response.ok) {
        const result = (await response.json()) as SignupResponse;
        return result;

      } else {
        throw new Error(`Error! status: ${response.status}`);
      }
    }
    catch (error) {
      if (error instanceof Error) {
        console.log('error message: ', error.message);
        return error;
      } else {
        console.log('unexpected error: ', error);
        return 'An unexpected error occurred';
      }
    }
  }

  async ovulationUpdate(track: Track): Promise<any> {

    //Creating body for create user API
    const body = JSON.stringify(track);

    try {
      const token = this.userPrefernceService.getToken();
      const response = await fetch(`${this.trackUrl}/core/ovulation`,
        {
          method: 'PUT',
          body: body,
          headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'token': token
          }
        });
      // Response ok 
      if (response.ok) {
        const result = (await response.json()) as SignupResponse;
        return result;

      } else {
        throw new Error(`Error! status: ${response.status}`);
      }
    }
    catch (error) {
      if (error instanceof Error) {
        console.log('error message: ', error.message);
        return error;
      } else {
        console.log('unexpected error: ', error);
        return 'An unexpected error occurred';
      }
    }
  }
}