<div class="example-button-row">
  <div class="example-flex-container">
    <div class="example-button-container">
      <button mat-fab class="button-scroll-to-top" (click)="scrollToTop()"
        [ngClass]="{'button-scroll-to-top--show': showScroll}" color="warn"
        aria-label="Example icon button with a home icon">
        <mat-icon>keyboard_arrow_up</mat-icon>
      </button>
    </div>
  </div>
</div>
