import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { OvulationTrackComponent } from '../ovulation-track/ovulation-track.component';
import { RestService } from '../rest.service';

@Component({
  selector: 'app-ovulation-track2',
  templateUrl: './ovulation-track2.component.html',
  styleUrls: ['./ovulation-track2.component.scss']
})
export class OvulationTrack2Component implements OnInit {
  OvulationTrackComponent=OvulationTrackComponent;
  public continueHide = false;
  button = 'Continue';
  isLoading = false;
  MenstrualCycle: any
  trackData: any
  showMsg : boolean
  @Output() onClickContinue = new EventEmitter<any>();
  constructor(private rest: RestService, private route : Router,private spinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getEditData()
  }

  getEditData() {
    this.rest.getovulationTrackEditData().subscribe((res: any) => {
      console.log(res)
      this.trackData = res
      if (Object.keys(this.trackData).length != 0) {
        this.MenstrualCycle = res.data.assumedPeriodCycle
        this.continueHide = true 
      }
    })

  }

  async submit(data:any){
  this.spinnerService.show();
  this.button = 'Processing';
  OvulationTrackComponent.trackDetails.assumed_PeriodCycle = data.Menstrual
  console.log("OvulationTrack2 details : "+data);
  console.log("OvulationTrackComponent.trackDetails"+JSON.stringify(OvulationTrackComponent.trackDetails));
  let res =await this.rest.ovulationUpdate( OvulationTrackComponent.trackDetails);
  if(res.status){
    this.spinnerService.hide();
      this.route.navigate(["/OvulationTrack3"]);
  }else{
    this.spinnerService.hide();
    this.button = 'Continue';
  }
  
  console.log("JSON.stringify(res1) : "+JSON.stringify(res));
  
}

countNumber(event: Event) {
  const target = event.target as HTMLInputElement
  console.log(target.value.length)
  if (Number(target.value) > 9 && Number(target.value) < 51 && target.value != "" && target.value.length == 2) {
    this.continueHide = true
    this.showMsg=false
  }
  else {
    this.showMsg=true
    this.continueHide = false
  }
  // if (target.value.length == 2) {
  //   this.continueHide = true
  // }
  // else{
  //   this.continueHide = false
  // }
}
goBack(){
  this.onClickContinue.emit('back')
}
}
