import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { CacheDataService } from 'src/app/core/services/cache-service/cache-data.service';
import { UserPrefernceService } from 'src/app/core/services/user-prefernce.service'; 
import { HomepageComponent } from 'src/app/homepage/homepage.component'; 
import { JwtLoginResponse, JwtToken } from 'src/app/jwtToken';
import { ConfirmationModalComponent } from 'src/app/modal/confirmation-modal/confirmation-modal.component';
import { RestService } from 'src/app/rest.service';
import { CommonDataSharingService } from 'src/app/core/services/data-sharing-services/common-data-sharing.service';
import { ShopModalComponent } from 'src/app/modal/shop-modal/shop-modal.component';
import { ConnectModalComponent } from 'src/app/modal/connect-modal/connect-modal.component';
@Component({
  selector: 'app-shared-header',
  templateUrl: './shared-header.component.html',
  styleUrls: ['./shared-header.component.scss']
})

export class SharedHeaderComponent implements OnInit { 
  navbarOpen = false;
  userloggedIn : boolean = false
  jwtTokenData: JwtLoginResponse = undefined
  username : string = ''
  email : string = ''
  pId: any;
  phone : string = ''
  imageUrl : any
  @ViewChild(HomepageComponent) _HomepageComponent:any;
  constructor(
    private userPrefernceService: UserPrefernceService,
    private cacheDataService: CacheDataService,
    private route : Router,
    public dialog: MatDialog,
    private rest: RestService,private commonSrv: CommonDataSharingService,
  ) { }

  @Input() navigationUrls: any;
  @Input() showSocialMediaLink: boolean | undefined;

  socialMediaLinksData = [
    {
      link: "https://twitter.com/wearewomean?s=21&t=xGHvNlOyumKy63WmJ1XO-g",
      image: "assets/images/twitter.png",
      alt: 'twitter',
    },
    {
      link: "https://www.instagram.com/wearewomean/?igshid=YmMyMTA2M2Y%3D",
      image: "assets/images/instagram.png",
      alt: 'instagram',
    },
    {
      link: "https://www.facebook.com/wearewomean",
      image: "assets/images/facebook.png",
      alt: 'facebook',
    }

  ]

 async ngOnInit() {
      if(this.userPrefernceService.getToken()){
        this.userloggedIn = true;
        const jwtToken = new JwtToken();
        this.jwtTokenData = jwtToken.getDecodedAccessToken(this.userPrefernceService.getToken());
        this.username = this.jwtTokenData.name
        this.email = this.jwtTokenData.email
        this.phone = this.jwtTokenData.phone
        this.pId = this.jwtTokenData.jti;
        let res = await this.rest.getByUserName(this.pId);
        if (res.status) {
          this.imageUrl = res.data.imageUrl
        }
      } 
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  signOut(): void {
    let message = "Do you want to log out?";
    const dialogRef = this.dialog.open(ConfirmationModalComponent, { width: '420px', data: message, disableClose: true });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.username = "";
        this.userPrefernceService.removeToken();
        this.cacheDataService.removeModule();
        this.userloggedIn = false;
        this.route.navigate(["/homepage"]);
      }
    });




    // if(confirm('Do you want to log out?')){
    // this.userPrefernceService.removeToken();
    // this.cacheDataService.removeModule();
    // this.userloggedIn = false;
    // //this.route.navigate(["/homepage"]);
    // }
  }

  navigateToModule(moduleName: modules): void { 
      this.cacheDataService.setModule(moduleName);
      this.route.navigate([
        this.cacheDataService.getModule(),
      ]);
  }

  navigateToSignIn(): void {
    //this.commonSrv.ROLE = role;  
    let routeURL = this.route.url.replace('/','')
    if(routeURL == 'homepage')
    this.cacheDataService.setModule('homepage');
    else if(routeURL == 'initiative')
    this.cacheDataService.setModule('initiative');
    else if(routeURL == 'aboutus')
    this.cacheDataService.setModule('aboutus');
    else if(routeURL == 'contactus')
    this.cacheDataService.setModule('contactus');
    this.route.navigate(["/SignIn"]);
}
navigateToSignUp(role:string): void { 
  this.commonSrv.ROLE = role; 
  this.cacheDataService.setModule('homepage');
  this.route.navigate(['/SignUp']);
}
  clickOnlogo(){ 
    this.route.navigate(["/homepage"]);
  }

  modalShop() {
    this.dialog.open(ShopModalComponent, { width: '1104px', disableClose: true });
  }

  modalConnct() {
    this.dialog.open(ConnectModalComponent, { width: '1104px', disableClose: true });
  }
}
