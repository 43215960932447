import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import { RestService } from '../rest.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { SigninResponse, User, ValidateOTP } from '../userCredentials';
import { CacheDataService } from '../core/services/cache-service/cache-data.service';
// import { FeatureConstants, modules } from '../core/constants/feature-constants';
// import { UserPrefernceService } from '../core/services/user-prefernce.service';
import { JwtLoginResponse, JwtToken } from '../jwtToken';
import { FeatureConstants } from '../core/constants/feature-constants';
import { UserPrefernceService } from '../core/services/user-prefernce.service';
import { UserInfoModel } from '../core/models/common/user-info.model';
import { MatDialog } from '@angular/material/dialog';
import { ErrorModalComponent } from '../modal/error-modal/error-modal.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { SuccessModalComponent } from '../modal/success-modal/success-modal.component';
import { OtpVerificationComponent } from '../otp-verification/otp-verification.component';
import { CommonDataSharingService } from '../core/services/data-sharing-services/common-data-sharing.service';
import { ShopModalComponent } from '../modal/shop-modal/shop-modal.component';
import { ConnectModalComponent } from '../modal/connect-modal/connect-modal.component';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
/*
 *[03/09/2022]  Change Log :- Intial Create of signin page for the functionality develpoment added backend services   Author :- Dilip Kumar Pottigari
 */
export class SigninComponent implements OnInit {
  SigninResponse!: { data: string; error: string; status: boolean };
  SigninErrorResponse:
    | { code: any; message: string; status: number }
    | undefined;
  // form declaration
  public signinForm: FormGroup;
  @ViewChild('otpForm') otpForm: NgForm;
  //public otpForm: FormGroup;
  email: any = '';
  password: any = '';
  loginFailure: boolean;
  validateOtp: boolean;
  static result: any;
  static Email: any;
  public user: string
  hide = true;
  button = 'Sign in';
  Otpbutton = 'Validate OTP';
  isLoading = false;
  public uid: any;
  otp: any = '';
  signindetails = true;
  static uid: any;
  static email: any;
  constructor(
    private route: Router,
    private rest: RestService,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private cacheDataService: CacheDataService,
    private userPrefernceService: UserPrefernceService,
    private spinnerService: NgxSpinnerService,
    public dialog: MatDialog,private commonSrv: CommonDataSharingService,
  ) {
    // Building Form Group variables of user inputs
    this.signinForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }
  // on click Signin button for Login Post page
  onSubmit(id: String) {
    if (id == 'Signin') {
      //signin user
      this.Signinuser();
    }
    //if (id == 'SignUp') {
      //redirect to signup page
     // this.commonSrv.ROLE = role; 
     // this.route.navigate(['/SignUp']);
   // }
  }
  navigateToUserSignUp(role:string): void { 
    this.commonSrv.ROLE = role; 
    this.cacheDataService.setModule('homepage');
    this.route.navigate(['/SignUp']);
  }
  navigateToDoctorSignUp(role:string): void { 
    this.commonSrv.ROLE = role; 
   // this.route.navigate(['/doctor-signup']);
  }
  navigateToRetailerSignUp(role:string): void { 
    this.commonSrv.ROLE = role; 
    //this.route.navigate(['/retailer-signup']);
  }
  ngOnInit(
  ): any {
    console.log(this.cacheDataService.getModule());
  }

  //validation user inputs and signin user
  async Signinuser() {
    this.email = this.signinForm.get('email')?.value;
    this.password = this.signinForm.get('password')?.value;
    const emailPattern =
      /^[a-z0-9._%+-]+[^@]+@[a-z0-9.-]+\.[a-z]{2,4}/;
    const emailPatternCheck = emailPattern.test(this.email);
    if (this.email == '' || this.password == '') {
      let message = "Invalid credentials";
      const dialogRef = this.dialog.open(ErrorModalComponent, { width: '420px', data: message, disableClose: true });
      dialogRef.afterClosed().subscribe(result => {
      })
    } else if (!emailPatternCheck) {
      let message = "Invalid email";
      const dialogRef = this.dialog.open(ErrorModalComponent, { width: '420px', data: message, disableClose: true });
      dialogRef.afterClosed().subscribe(result => {
      })
    } else {
      this.isLoading = true;
      this.button = 'Processing';
      const user = new User(this.email, this.password);
      // Function call to hit the signin user API
      let res = await this.rest.onSignin(user);
      console.log(res);
      this.uid = res.uid
      SigninComponent.uid = res.uid
      SigninComponent.email = res.email
      if (res.data == "Error : Invalid Request : Invalidated OTP" && res.status == false) {
        let message = "Validate Your OTP";
        const dialogRef = this.dialog.open(ErrorModalComponent, { width: '420px', data: message, disableClose: true });
        dialogRef.afterClosed().subscribe(result => {
          // this.validateOtp = true;
         // this.signindetails = true
          this.isLoading = false;
          this.button = 'Sign in';
          this.dialog.open(OtpVerificationComponent, {
            disableClose: true,
            width: '420px',
            autoFocus: false,
            data:this.uid
          }).afterClosed().subscribe((res: any) => {
            if (res) {
              this.onValidate(res)
            }
          });

        })
      }
      else if (res.data == "Error : Profile not Complete" && res.status == true) {
        if(res.roles[0]=="DOCTOR"){
          this.cacheDataService.setModule('homepage')
          this.route.navigate(["/doctor-connect"]);
        }
        if(res.roles[0]=="RETAILER"){
          this.cacheDataService.setModule('homepage')
          this.route.navigate(["/retailer-connect"]);
        }
        if(res.roles[0]=="USER"){
          this.cacheDataService.setModule('homepage')
          this.route.navigate(["/Continue"]);
        }
       
      }

      else {
        if (res.status) {
          //if(res.uid==null){
          this.userPrefernceService.setToken(res.data)
          const jwtToken = new JwtToken();
          let data: JwtLoginResponse = jwtToken.getDecodedAccessToken(res.data);
          console.log(this.cacheDataService.getModule())
          if (data != null) {
            localStorage.setItem("JwtLoginResponse", JSON.stringify(data));
            //if(data.role=="USER"){ 
            if (this.cacheDataService.getModule()) {
              this.route.navigate([
                this.cacheDataService.getModule(),
              ]);
            }
            else {
              this.route.navigate(['/homepage'])
            }
            //else this.route.navigate(['/GETSTARTED']);
            //}
            // else{
            //   alert('invalid credentials');
            // }
          }

          // }
          // else{
          //   this.route.navigate(['/Continue']);
          // }
        }
        else {
          let message = "Invalid Username or Password";
          const dialogRef = this.dialog.open(ErrorModalComponent, { width: '420px', data: message, disableClose: true });
          dialogRef.afterClosed().subscribe(result => {
            this.isLoading = false;
            this.button = 'Sign in';
          })
        }
        SigninComponent.result = res;
        SigninComponent.Email = this.email;
        console.log("SigninComponent.result; " + JSON.stringify(SigninComponent.result));
      }
    }
  }
  clickOnlogo() {
    this.route.navigate(["/homepage"]);
  }

  forgotPwd() {
    this.route.navigate(["/ForgotPassword"]);
  }
  async onValidate(otpDetails: any) {
    this.otp = otpDetails;
    // if (this.otpForm.form.status == "INVALID") {
    //   return
    // }
    this.isLoading = true;
    this.button = 'Processing';
    const user = new ValidateOTP(this.uid, this.otp);
    // Function call to hit the signin user API
    let res = await this.rest.onValidateOtp(user);
    console.log(res)
    if (res.data) {
      this.route.navigate(["/Continue"]);
    }
    else {
      this.isLoading = false;
      this.button = 'Validate OTP';
      let message = "Invalid OTP";
      const dialogRef = this.dialog.open(ErrorModalComponent, { width: '420px', data: message, disableClose: true });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialog.open(OtpVerificationComponent, {
            disableClose: true,
            width: '420px',
            autoFocus: false,
            data:this.uid
          }).afterClosed().subscribe((res: any) => {
            if (res) {
              this.onValidate(res)
            }
          });
        }
      })
    }


  }
  // async resendOtp() {
  //   this.spinnerService.show();
  //   this.otp = "";
  //   const user = new ValidateOTP(this.uid, this.otp);
  //   // Function call to hit the signin user API
  //   let res = await this.rest.onResendOtp(user);
  //   console.log(res)
  //   this.spinnerService.hide();
  //   if (res.status) {
  //     let message = "OTP sent successfully";
  //     const dialogRef = this.dialog.open(SuccessModalComponent, { width: '420px', data: message, disableClose: true });
  //     dialogRef.afterClosed().subscribe(result => {

  //     })
  //   }
  // }
  modalShop() {
    this.dialog.open(ShopModalComponent, { width: '1104px', disableClose: true });
  }

  modalConnct() {
    this.dialog.open(ConnectModalComponent, { width: '1104px', disableClose: true });
  }
  
}
