<div class="text-center">
    <div class="center">
        <img src="assets\womean_logo_PNG-removebg-preview.png" alt=""height="100" width="100">
    </div>
    <h1>Login to Your Account</h1>
    <h2>Admin Console</h2>
    
    <!-- Admin credentials input form -->
    <form class="form" #signinForm="ngForm" (ngSubmit)="onSubmit(signinForm.value)">
        <input type="email" required email #admin_Email="ngModel" class="input-box" name="admin_Email" placeholder="  Email" ngModel><br>
        <div class="text-danger" *ngIf="admin_Email.touched && admin_Email.invalid">
            <i class="fa-sharp fa-solid fa-circle-exclamation"></i>
            <span> Email is required</span>
        </div>

        <input type="password" required #admin_Password="ngModel" class="input-box" name="admin_Password" placeholder="  Password" ngModel>
        <div class="text-danger" *ngIf="admin_Password.touched && admin_Password.invalid">
            <i class="fa-sharp fa-solid fa-circle-exclamation"></i> 
            <span > Password is required</span>
        </div>
        <div class="text-danger" *ngIf="adminFailure">
            <i class="fa-sharp fa-solid fa-circle-exclamation"></i>
            <span> UnAuthorized Access</span>
        </div>
        <div class="text-center">
            <button id="Signin" type="submit" class="Signin" [disabled]="signinForm.invalid">Signin</button>
        </div>
    </form>
    
    <div class="down">
        <p>Womean Inc. 2022 All rights reserved</p>
   </div>
</div>


   


