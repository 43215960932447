import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { distinctUntilChanged, filter, finalize, Observable, of, startWith, switchMap, tap } from 'rxjs';

@Component({
  selector: 'womean-single-select-autocomplete',
  templateUrl: './womean-single-select-autocomplete.component.html',
  styleUrls: ['./womean-single-select-autocomplete.component.scss'],
})
export class WomeanSingleSelectAutocompleteComponent implements OnInit {
  options: string[] = [];
  isLoading = false;
  errorMsg!: string;
  filteredOptions: any;
  
  @Output() selectedItemChange=new EventEmitter<any>();
  @Output() enterPressed=new EventEmitter<void>();
  @Input() selectedItem: any;
  @Input() myControl = new FormControl();
  @Input() minLengthTerm:number = 0;
  @Input() valuePrimitive:boolean;
  @Input() placeHolder:string="search here";
  @Input() source:(input:string)=>Observable<any[]>=()=>of()
;
  ngOnInit() {
    //this.loadData();
    this.myControl.valueChanges.pipe(
      filter(res => {
        return res !== null && res.length >= this.minLengthTerm
      }),
      distinctUntilChanged(),
      //debounceTime(1000),
      tap((res) => {
        this.errorMsg = "";
        this.filteredOptions = [];
        this.isLoading = true;
        this.selectedItem=false;
        this.selectedItemChange.emit(this.selectedItem);
      }),
      switchMap(value => this.source(value)
        .pipe(
          finalize(() => {
            this.isLoading = false
          }),
        )
       )
    )
    .subscribe((data: any) => {
        this.filteredOptions = data;
    });
  }

  displayFn(user: string): string {
    return user;
  }

  loadData(input: string=''):void{
    this.source(input).subscribe(data=>{
      console.log(data);
    })
  }
  onSelected(selected:any) {
    this.selectedItem = selected;
    this.isLoading=false;
    if(this.valuePrimitive) this.selectedItemChange.emit(this.selectedItem.fieldName);
    this.selectedItemChange.emit(this.selectedItem);
  }
  clearSelection() {
    this.selectedItem = null;
    this.myControl.setValue(null);
    this.filteredOptions = [];
  }
  pressEnter():boolean{
    this.enterPressed.emit();
    return false;
  }
}
