
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
    <p style="color: white">Please Wait... </p>  
    </ngx-spinner>
<div class="ag-sm-container">
    <div class="left">
        <div class="text-center pt-4">
            <img src="/assets/images/logox.png" alt="" height="130" width="130"  (click)="clickOnlogo()">

            <h1>Login to your account</h1>
            <!-- <div class="Social-Icons">
            <ul>
                <li><i class='fab fa-facebook display-6'></i></li>
                <li><i class='fab fa-google-plus display-6'>fa-google-plus</i></li>
                <li><i class='fab fa-instagram  display-6'>fa-instagram</i></li>
            </ul>
        </div> -->
            <div class="b-lf">
                <ul>
                    <li><img src="/assets/images/Icon awesome-clock.svg" alt=""></li>
                    <li><img src="/assets/images/Icon awesome-book.svg" alt=""></li>
                    <li><img src="/assets/images/Icon map-doctor.svg" alt=""></li>
                    <li><img src="/assets/images/Icon awesome-shopping-basket.svg" alt=""></li>
                    <li><img src="/assets/images/Icon material-group.svg" alt=""></li>
                    <li><img src="/assets/images/Icon map-gym.svg" alt=""></li>
                </ul>
            </div>
            <h4 class="pt-2 mb-3">And Explore Our Products</h4>

        </div>

        <div class="text-center" *ngIf="signindetails">

            <!-- user credentials inpout form -->

            <form [formGroup]="signinForm" (ngSubmit)="onSubmit('id')" class="kg-form">
                <input id="email" type="text" class="input-box" name="email" placeholder="  Email"
                    formControlName="email" required>
                <input id="password" [type]="hide ? 'password' : 'text'" class="input-box" placeholder="  Password"
                    formControlName="password" required>
                <a href="javascript:void:(0);" class="kg-eye" (click)="hide = !hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </a>
                <span class="forgotPw"><a href="javascript:void(0);" (click)="forgotPwd()">Forgot Password?</a></span>
                <div class="text-center w-100">
                    <button id="Signin" type="submit" class="Signin" [disabled]="isLoading" (click)="onSubmit('Signin')"><i [ngClass]="{'fa fa-spinner fa-spin': isLoading}"></i>&nbsp;{{button}}</button>
                </div>
            </form>

          
        </div>
        <!-- <div *ngIf="validateOtp">
            <form name="otpForm" #otpForm="ngForm" (ngSubmit)="onValidate(otpForm.value)" class="kg-form">
                <input id="otp" type="tel" appOnlyNumerics class="form-control" placeholder="OTP" minlength="6" maxlength="6"
                name="otp" #otp="ngModel" ngModel required>
                <span class="resenOTP"><a href="javascript:void(0);" (click)="resendOtp()">Resend OTP</a></span>
                <div class="text-center w-100">
                    <button type="submit" class="Signin" [attr.disabled]="!otpForm.valid ? true : null" ><i [ngClass]="{'fa fa-spinner fa-spin': isLoading}"></i>&nbsp;{{Otpbutton}}</button>
                </div>
            </form>
            
        </div> -->
       
        
    </div>

    <div class="right">

        <div class="text-center">
            <h2 style="padding-top:0;text-align:center">New to our website?</h2>
        </div>
        <div class="eleven">
            <h1>SIGN UP AS</h1>
          </div>
        <div class="text-center">
           <div class="ag-form">
                <button  type="submit" class="SignUp" (click)="modalConnct()">Doctor</button>
                <button  type="submit" class="SignUp" (click)="modalShop()">Retailer</button>
                <button  type="submit" class="SignUp" (click)="navigateToUserSignUp('USER')">User</button>
            </div>
            <p>Start your journey towards Women Health and Hygiene</p>
        </div>
        <div class="down">
            <b>Women Inc.2022 All rights reserved</b>
        </div>

    </div>
</div>