import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorModalComponent } from '../modal/error-modal/error-modal.component';
import { SuccessModalComponent } from '../modal/success-modal/success-modal.component';
// import { map } from 'rxjs';
import { RestService } from '../rest.service';
import { SignupUser, ValidateOTP } from '../userCredentials';
import { OtpVerificationComponent } from '../otp-verification/otp-verification.component';
import { CommonDataSharingService } from '../core/services/data-sharing-services/common-data-sharing.service';
@Component({
  selector: 'womean-retailer-signup',
  templateUrl: './retailer-signup.component.html',
  styleUrls: ['./retailer-signup.component.scss']
})
export class RetailerSignupComponent implements OnInit {
  @ViewChild('signupForm') signupForm: NgForm;
  // form declaration
  // public signupForm:FormGroup;
  email: any = '';
  phone: any = '';
  password: any = '';
  confirm_password: any = '';
  passwordCheckStatus = false;
  passwordCheck: any = '';
  // Global variables declaration
  static Email: any;
  static result: any;
  static phone: any;
  hide = true;
  confirm_hide = true;
  button = 'Sign Up';
  isLoading = false;
  validateOtp: boolean;
  signupdetails = true;
  public uid: any;
  otp: any = '';
  Otpbutton = 'Validate & Continue';
  Roles: any = [];
  roles: any
  constructor(
    public route: Router,
    // private http : HttpClient,
    private rest: RestService,
    public dialog: MatDialog,
    private spinnerService: NgxSpinnerService, private commonSrv: CommonDataSharingService,
    // private dialogModel: MatDialog,
  ) // private formBuilder : FormBuilder
  {
    // Building Form Group variables of user inputs
    // this.signupForm = this.formBuilder.group({
    //   email: ['', [Validators.required]],
    //   phone: ['', [Validators.required]],
    //   password: ['', [Validators.required]],
    //   confirm_password: ['', [Validators.required]]
    // });
  }

  ngOnInit(): void {
    this.Roles.push(this.commonSrv.ROLE);
  }
  // on click Signin button for create account

  async formSubmit(signUpDetails: any) {
    this.email = signUpDetails.email;
    this.phone = '+91' + signUpDetails.phone;
    this.password = signUpDetails.password;
    this.confirm_password = signUpDetails.confirm_password;
    this.roles = this.Roles
    RetailerSignupComponent.Email = this.email;
    RetailerSignupComponent.phone = this.phone;

    //Creating new signup user object

    const user = new SignupUser(
      this.email,
      this.phone,
      this.password,
      this.confirm_password,
      this.roles
    );
    if (this.signupForm.form.status == 'INVALID') {
      return;
    }
    if (this.passwordCheckStatus) {
      this.isLoading = true;
      this.button = 'Processing';
      // Function call to hit the create user API
      let res = await this.rest.onSignUp(user);
      this.uid = res.data;
      if (res.status) {
        this.isLoading = false;
        //this.validateOtp = true;
        this.signupdetails = false;
        // Response from Create api stores in Global variable(result)
        RetailerSignupComponent.result = res;
        this.dialog.open(OtpVerificationComponent, {
          disableClose: true,
          width: '420px',
          autoFocus: false,
          data:this.uid
        }).afterClosed().subscribe((res: any) => {
          if (res) {
            console.log(res)
            this.onValidate(res)
          }
        });
        // this.route.navigate(["/Continue"]);
      } else {
        let message = 'User Already Exists';
        const dialogRef = this.dialog.open(ErrorModalComponent, {
          width: '420px',
          data: message,
          disableClose: true,
        });
        dialogRef.afterClosed().subscribe((result) => { });
        this.isLoading = false;
        this.button = 'Sign Up';
      }
    }
  }
  async onValidate(otpDetails: any) {
    this.otp = otpDetails;
    if (this.otp == '') {
      return;
    }
    this.isLoading = true;
    this.button = 'Processing';
    const user = new ValidateOTP(this.uid, this.otp);
    console.log(this.otp)
    let res = await this.rest.onValidateOtp(user);
    console.log(res);
    if (res.data) {
      this.route.navigate(["/retailer-connect"]);
    }
    else {
      this.isLoading = false;
      this.button = 'Validate OTP';
      let message = "Invalid OTP";
      const dialogRef = this.dialog.open(ErrorModalComponent, { width: '420px', data: message, disableClose: true });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialog.open(OtpVerificationComponent, {
            disableClose: true,
            width: '420px',
            autoFocus: false,
            data:this.uid
          }).afterClosed().subscribe((res: any) => {
            if (res) {
              console.log(res)
              this.onValidate(res)
            }
          });
        }
      })
  }
}
  // async resendOtp() {
  //   this.spinnerService.show();
  //   this.otp = '';
  //   const user = new ValidateOTP(this.uid, this.otp);
  //   // Function call to hit the signin user API
  //   let res = await this.rest.onResendOtp(user);
  //   console.log(res);
  //   this.spinnerService.hide();
  //   if (res.status) {
  //     let message = 'OTP sent successfully';
  //     const dialogRef = this.dialog.open(SuccessModalComponent, {
  //       width: '420px',
  //       data: message,
  //       disableClose: true,
  //     });
  //     dialogRef.afterClosed().subscribe((result) => { });
  //   }
  // }
  // password input validations
  validateInputs(password: string) {
    const containsNumber = /\d/;
    const doesItHaveNumber = containsNumber.test(password);
    if (checkSpaces(password)) {
      this.passwordCheckStatus = false;
      this.passwordCheck = 'password should not contain spaces!';
    } else if (!checkSymbol(password)) {
      this.passwordCheckStatus = false;
      this.passwordCheck = 'password should contains atleast 1 symbol!';
    } else if (!doesItHaveNumber) {
      this.passwordCheckStatus = false;
      this.passwordCheck = 'password should contains atleast 1 number!';
    } else if (!checkUppercase(password) && checkLowercase(password)) {
      this.passwordCheckStatus = false;
      this.passwordCheck =
        'password should contains atleast 1 Uppercase & 1 Lowercase!';
    } else if (password.length < 8) {
      this.passwordCheckStatus = false;
      this.passwordCheck = 'password should contain atleast 8 characters!';
    } else {
      this.passwordCheckStatus = true;
      this.passwordCheck = '';
    }
  }

  clickOnlogo() {
    this.route.navigate(['/homepage']);
  }
  onNavigateSignIn() {
    this.route.navigate(["/SignIn"]);
  }
  //   async onFormSubmit()
  //   {
  //     this.email = this.signupForm.get('email')?.value;
  //     this.phone = this.signupForm.get('phone')?.value;
  //     this.password = this.signupForm.get('password')?.value;
  //     this.confirm_password = this.signupForm.get('confirm_password')?.value;
  // // Email store in Global variable
  //    SignUpComponent.Email = this.email;

  //     let validate : string = this.validateInputs(this.email,this.phone,this.password,this.confirm_password);

  //     let input_phone = "+91"+this.phone;

  //     if(validate=="sucess")
  //    {
  //     //Creating new signup user object
  //     const user = new SignupUser(this.email,input_phone,this.password, this.confirm_password);

  //     // Function call to hit the create user API
  //      let res = await this.rest.onSignUp(user);
  //     if(res.status){
  //       this.route.navigate(["/Continue"]);
  //       // Response from Create api stores in Global variable(result)
  //       SignUpComponent.result = res;
  //     }

  //    } else{
  //       alert(validate);
  //     }
  //   }

  displayBox() {
    var modal = document.getElementById("model_");
    modal.style.display = 'block';
  }

  closeBox() {
    var modal = document.getElementById("model_");
    modal.style.display = 'none';
  }

  getOtp() {
    this.dialog.open(OtpVerificationComponent, {
      disableClose: true,
      width: '420px',
      autoFocus: false
    })
  }
}
// Check for uppercase in the string
function checkUppercase(password: any) {
  for (var i = 0; i < password.length; i++) {
    if (
      password.charAt(i) == password.charAt(i).toUpperCase() &&
      password.charAt(i).match(/[a-z]/i)
    ) {
      return true;
    }
  }
  return false;
}
// Check for lowercase in the string
function checkLowercase(password: any) {
  for (var i = 0; i < password.length; i++) {
    if (
      password.charAt(i) == password.charAt(i).toLowerCase() &&
      password.charAt(i).match(/[a-z]/i)
    ) {
      return true;
    }
  }
  return false;
}
// Check for Symbol in the string
function checkSymbol(password: any) {
  var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  if (format.test(password)) {
    return true;
  } else {
    return false;
  }
}
// Check for Spaces in the string
function checkSpaces(password: any) {
  for (var i = 0; i < password.length; i++) {
    if (password.charAt(i) == ' ') {
      return true;
    }
  }
  return false;
}


 

