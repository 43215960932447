import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-build-module',
  templateUrl: './build-module.component.html',
  styleUrls: ['./build-module.component.scss']
})
export class BuildModuleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  navigationUrls = [
    {
      urls: 'Home Page',
      link: '/homepage',
      icons: 'fa fa-home',
      active: true
    },
    {
      urls: 'The Initiative',
      link: '/initiative',
      icons: 'fa fa-file',
      active: false
    },
    {
      urls: 'About Us',
      link: '/aboutus',
      icons: 'fa fa-info-circle',
      active: false
    },
    {
      urls: 'Contact Us',
      link: '/contactus',
      icons: 'fa fa-phone',
      active: false
    },
  ];

}
