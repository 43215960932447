import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminLoginComponent } from '../admin-login/admin-login.component';
import { RestService } from '../rest.service';
import { AdminDetails } from '../userCredentials';
@Component({
  selector: 'app-admin-console3',
  templateUrl: './admin-console3.component.html',
  styleUrls: ['./admin-console3.component.scss']
})
export class AdminConsole3Component implements OnInit {

  AdminLoginComponent = AdminLoginComponent ;
  
  
  static adminDetails:any;
  constructor(private rest : RestService, private route : Router){}

 ngOnInit(): any {
  
 }
  async onSubmit(details:any){
    console.log(details);
    console.log(details.category);
    console.log(details.header);
   let id = AdminLoginComponent.jwtResponse.jti;
   let email =AdminLoginComponent.jwtResponse.email;
   let category ="home."+details.category;
   let header=details.header;
   const createdOn = new Date().valueOf();
   console.log(createdOn);
  
   AdminConsole3Component.adminDetails = new AdminDetails(id,email,createdOn,category,header);
    
    let res =await this.rest.postCore(AdminConsole3Component.adminDetails);
    if(res.status){
      this.route.navigate(["/AdminConsole4"]);
    }
  }
}



