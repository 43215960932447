import { Component, Input, OnInit } from '@angular/core';
import { CacheDataService } from 'src/app/core/services/cache-service/cache-data.service';
import { UserPrefernceService } from 'src/app/core/services/user-prefernce.service';
import { JwtLoginResponse, JwtToken } from 'src/app/jwtToken';
import { RestService } from 'src/app/rest.service';
import { navData } from 'src/app/utils/constants';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})


export class HeaderComponent implements OnInit {
  navbarOpen = false;
  navData = navData;
  jwtTokenData: JwtLoginResponse = undefined
  username : string = ''
  email : string = ''
  pId: any;
  phone : string = ''
  imageUrl : any
  @Input() navigationUrls: any;
  @Input() showSocialMediaLink: boolean | undefined;

  socialMediaLinksData = [
    {
      link: "https://twitter.com/wearewomean?s=21&t=xGHvNlOyumKy63WmJ1XO-g",
      image: "assets/images/twitter.png",
      alt: 'twitter',
    },
    {
      link: "https://www.instagram.com/wearewomean/?igshid=YmMyMTA2M2Y%3D",
      image: "assets/images/instagram.png",
      alt: 'instagram',
    },
    {
      link: "https://www.facebook.com/wearewomean",
      image: "assets/images/facebook.png",
      alt: 'facebook',
    }
    
  ]

  constructor(
    private userPrefernceService: UserPrefernceService,
    private cacheDataService: CacheDataService,
    private rest: RestService,
  ) { } 
  
  async ngOnInit() {
    if(this.userPrefernceService.getToken()){
      const jwtToken = new JwtToken();
      this.jwtTokenData = jwtToken.getDecodedAccessToken(this.userPrefernceService.getToken());
      this.username = this.jwtTokenData.name
      this.email = this.jwtTokenData.email
      this.phone = this.jwtTokenData.phone
      this.pId = this.jwtTokenData.jti;
      let res = await this.rest.getByUserName(this.pId);
      if (res.status) {
        this.imageUrl = res.data.imageUrl
      }
    }
  }
  
  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }
}
