import { JsonPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContinueComponent } from '../continue/continue.component';
import { UserPrefernceService } from '../core/services/user-prefernce.service';
import { JwtLoginResponse, JwtToken } from '../jwtToken';
import { PregnancyTrackComponent } from '../pregnancy-track/pregnancy-track.component';
import { RestService } from '../rest.service';
import { SignUpComponent } from '../sign-up/sign-up.component';
import { SigninComponent } from '../signin/signin.component';
import { Track } from '../userCredentials';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { fadeInOnEnterAnimation,fadeInExpandOnEnterAnimation, fadeOutCollapseOnLeaveAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';

@Component({
  selector: 'app-pregnancy-track3',
  templateUrl: './pregnancy-track3.component.html',
  styleUrls: ['./pregnancy-track3.component.scss'],
  animations: [
    fadeInOnEnterAnimation(),
    fadeOutOnLeaveAnimation(),
    fadeInExpandOnEnterAnimation({ duration: 400 }),
    fadeOutCollapseOnLeaveAnimation({ duration: 400 }),
  ]
})
export class PregnancyTrack3Component implements OnInit {
  constructor(private rest: RestService, private route: Router,private userPrefernceService: UserPrefernceService,private spinnerService: NgxSpinnerService) {}
  @Output() onClickContinue = new EventEmitter<any>();
  public pP = false;
  public trackPregnancy = '';
  showCalendar = false;
  selectedValue: any;
  methodOfEstimation: string;
  dateOfConceptionEpoch: number = 0;
  dueDateEpoch: number = 0;
  jwtTokenData: JwtLoginResponse = undefined
  button = 'Continue';
  isLoading = false;
  trackData:any
  day: String;
  month: String;
  year: String;
  dueDate_val : any
  due_Date : any
  ConceptionDate_val : any
  Conception_Date : any
  continueHide = false;
  lastPeriodDate() {
    this.pP = false;
    this.trackPregnancy = 'Last Period Date';
    this.methodOfEstimation = 'lastPeriodDate';
    this.showCalendar = false;
  }

  dateOfConception() {
    this.pP = false;
    this.trackPregnancy = 'Date of Conception';
    this.methodOfEstimation = 'dateOfConception';
    this.showCalendar = true;
  }

  dueDate() {
    this.pP = false;
    this.trackPregnancy = 'Due Date';
    this.methodOfEstimation = 'dueDate';
    this.showCalendar = true;
  }

  handleInputStartDate(_input : any) {
    if(_input == 'due')
    this.selectedValue = moment(this.dueDate_val)
    else if (_input == 'conception')
    this.selectedValue = moment(this.ConceptionDate_val)
    this.showCalendar = true;
  }

  ngOnInit(): void {
    const jwtToken = new JwtToken();
    this.jwtTokenData = jwtToken.getDecodedAccessToken(this.userPrefernceService.getToken());
    this.getEditData()
  }

  getEditData() {
    this.rest.getpregnancyTrackEditData().subscribe((res: any) => {
      console.log(res)
      this.trackData = res
      if (Object.keys(this.trackData).length != 0) {
        if(this.trackData?.data?.methodOfEstimation ==  'dueDate'){
          this.dueDate()
          if (this.trackData?.data?.dueDate){
            this.day = String(new Date(res.data.dueDate).getDate()).padStart(2, '0');
            this.month = String(new Date(res.data.dueDate).getMonth() + 1).padStart(2, '0'); //January is 0!
            this.year = String(new Date(res.data.dueDate).getFullYear()).padStart(2, '0');
            this.dueDate_val = this.year + '-' + this.month + '-' + this.day;
            this.due_Date = this.day + '-' + this.month + '-' + this.year;
            this.selectedValue = moment(this.dueDate_val)
          }
          }
          else if(this.trackData?.data?.methodOfEstimation ==  'dateOfConception'){
            this.dateOfConception()
            this.day = String(new Date(res.data.dateOfConception).getDate()).padStart(2, '0');
            this.month = String(new Date(res.data.dateOfConception).getMonth() + 1).padStart(2, '0'); //January is 0!
            this.year = String(new Date(res.data.dateOfConception).getFullYear()).padStart(2, '0');
            this.ConceptionDate_val = this.year + '-' + this.month + '-' + this.day;
            this.Conception_Date = this.day + '-' + this.month + '-' + this.year;
            this.selectedValue = moment(this.ConceptionDate_val)
          }
          else if(this.trackData?.data?.methodOfEstimation ==  'lastPeriodDate'){
            this.lastPeriodDate()
          }
      }
    })

  }

  selectedChange(event : any){
    console.log(this.methodOfEstimation)
    let date = Date.parse(event)
    if (this.methodOfEstimation == 'dueDate') {
    this.day = String(new Date(date).getDate()).padStart(2, '0');
    this.month = String(new Date(date).getMonth() + 1).padStart(2, '0'); //January is 0!
    this.year = String(new Date(date).getFullYear()).padStart(2, '0');
    this.dueDate_val = this.year + '-' + this.month + '-' + this.day;
    this.due_Date = this.day + '-' + this.month + '-' + this.year;
    }
    if (this.methodOfEstimation == 'dateOfConception') {
      this.day = String(new Date(date).getDate()).padStart(2, '0');
      this.month = String(new Date(date).getMonth() + 1).padStart(2, '0'); //January is 0!
      this.year = String(new Date(date).getFullYear()).padStart(2, '0');
      this.ConceptionDate_val = this.year + '-' + this.month + '-' + this.day;
      this.Conception_Date = this.day + '-' + this.month + '-' + this.year;
      }
    console.log(date)
  }

  onSubmit(data: any) {
    this.spinnerService.show();
    console.log('details : ' + this.selectedValue);
    this.button = 'Processing';
    if (this.methodOfEstimation == 'dateOfConception') {
      this.dateOfConceptionEpoch =   this.selectedValue.valueOf();
      this.dueDateEpoch = 0;
    }
    if (this.methodOfEstimation == 'dueDate') {
      this.dateOfConceptionEpoch = 0;
      this.dueDateEpoch = this.selectedValue.valueOf();
    }

    console.log('details.dateOfConception : ' + this.dateOfConceptionEpoch);
    console.log('details.dueDate : ' + this.dueDateEpoch);
    const track: Track = PregnancyTrackComponent.trackDetails;

    track.method_OfEstimation = this.methodOfEstimation;
    track.date_OfConception = this.dateOfConceptionEpoch;
    track.due_Date =  this.dueDateEpoch;
    let str: any;

    track.e_mail = this.jwtTokenData.email;
    track.p_Id = this.jwtTokenData.jti;
    console.log('track : ' + JSON.stringify(track));

    let res = this.rest.pregnancyUpdate(track);
    const res1 = Promise.resolve(res).then((res) => {
      if (res.status) {
        this.spinnerService.hide();
        this.route.navigate(['/PregnancyTrack4']);
      }
    else{
      this.spinnerService.hide();
      this.isLoading = false;
      this.button = 'Continue';
    }
    });
    console.log('JSON.stringify(res1) : ' + JSON.stringify(res1));
  }

  displayBox(){
    let modal = document.getElementById("model_1");
    modal.style.display = 'block';
    console.log(modal.style.display)
  }

  displayBox2(){
    let modal = document.getElementById("model2_");
    modal.style.display = 'block';
  }

  closeBox(){
    let modal = document.getElementById("model_1");
    modal.style.display = 'none';
  }

  closeBox2(){
    let modal = document.getElementById("model2_");
    modal.style.display = 'none';
  }
  goBack(){
    this.onClickContinue.emit('PregnancyTrack2')
    this.onClickContinue.emit('back')
  }
}
