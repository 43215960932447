<div>
    <form #PeriodtrackDetails="ngForm" class="formExtension" >
        <h1 [@fadeInOnEnter] [@fadeOutOnLeave]>Do you Experience Mood Swings / Harmonal Imbalance? </h1>
        <div class="input-container text-center">
            <div (click)="showOptions=true" [@fadeInOnEnter] [@fadeOutOnLeave]>
                <button type="button" class="input-box icon-select" value="">{{mood}}</button>
                <!-- <span class="icon2 fa-solid fa-caret-down ag-pointer"></span> -->
            </div>
            <div class="radio-tile-group" *ngIf="showOptions" [@fadeInOnEnter] [@fadeOutOnLeave]>
                <div class="input-container">
                    <button class="input-box" type="button" (click)="moodYes()">Yes</button>
                </div>
                <div class="input-container">
                    <button class="input-box" type="button" (click)="moodNo()">No</button>
                </div>
            </div>
            <div *ngIf="showIntenseMood" [@fadeInOnEnter] [@fadeOutOnLeave]>
                <h1>How Intense are your Mood Swings?</h1>
                <div class="d-flex">
                    <p class="p-text3">Mild</p>
                    <p class="p-text4">Severe</p>
                </div>
                <div class="d-flex justify-content-center" [@fadeInOnEnter] [@fadeOutOnLeave]>
                    <p class="p-text">Mild</p>
                    <div class="ag-rating">
                        <div class="form-check ag-check" *ngFor="let count of countsOfCramps">
                            <input class="form-check-input ag-check-input" type="radio" [checked]="count == pmsCount"
                                [value]="count" (change)="handleCountCramps($event)">
                        </div>
                        <p class="p-text2">Severe</p>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="ag-continue">
    <button type="button" class="back"  (click)="goBack()">Previous</button>
    <button type="submit" class="Continue" *ngIf="continueHide" [@fadeInOnEnter] [@fadeOutOnLeave]
        (click)="onSubmit(PeriodtrackDetails.value)">Continue</button>
</div>