import { DateRange } from "@angular/material/datepicker";

export abstract class  BaseTrackComponent {
    selectedRangeValue: any
    selectedDate: Date
    showCalendar = false; 
    end: any;
    start : any;
    constructor(){}
    handleInputStartDate() {
        this.showCalendar = true;
      }
    selectedChange(m: any) {
      console.log(m)
        this.selectedRangeValue=m;
        this.start = this.selectedRangeValue.start;
        this.end = this.selectedRangeValue.end;
        this.setComponentTrackDates();
      }
    abstract setComponentTrackDates():void
}

