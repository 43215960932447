import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Data, Router } from '@angular/router';
import { RestService } from '../rest.service';
import { Location } from '@angular/common';
import { SignUpComponent } from '../sign-up/sign-up.component';
import { SigninComponent } from '../signin/signin.component';
import { UserDetails } from '../userCredentials';
import { JwtLoginResponse, JwtToken } from '../jwtToken';
import { GETSTARTEDComponent } from '../getstarted/getstarted.component';
import { OvulationTrack3Component } from '../ovulation-track3/ovulation-track3.component';
import { PregnancyTrack4Component } from '../pregnancy-track4/pregnancy-track4.component';
import { PeriodTrack4Component } from '../period-track4/period-track4.component';
import { UserInfoModel } from '../core/models/common/user-info.model';
import { UserPrefernceService } from '../core/services/user-prefernce.service';
import { CacheDataService } from '../core/services/cache-service/cache-data.service';
import { MatDialog } from '@angular/material/dialog';
import { ContinueModalComponent } from '../modal/continue-modal/continue-modal.component';
import { ErrorModalComponent } from '../modal/error-modal/error-modal.component';
import { SuccessModalComponent } from '../modal/success-modal/success-modal.component'; 
@Component({
  selector: 'app-continue',
  templateUrl: './continue.component.html',
  styleUrls: ['./continue.component.scss']
})

/*
 *[03/09/2022]  Change Log :- Intial Create of Register page for the functionality develpoment added backend services   Author :- Dilip Kumar Pottigari
 */

export class ContinueComponent implements OnInit {
  // public JwtLoginResponse : JwtLoginResponse = JSON.parse(localStorage.getItem('JwtLoginResponse') !);
  // form declaration
  public registerForm: FormGroup;
  Firstname: any = "";
  Middlename: any = "";
  Lastname: any = "";
  Gender: any = "Gender";
  Location: any = "Location";
  DateOfBirth: any = "";
  Weight: any = "";
  WeightType: any = "";
  Height: any = "";
  HeightType: any = "";
  static pId: any;
  static email: any;
  static FirstName: any;
  userName: any;
  phone: any = "";
  pId: any;
  e_mail: any;
  jwtTokenData: JwtLoginResponse = undefined
  button = 'Continue';
  isLoading = false;
  imageUrl : any
  imageUrldownloading :boolean = false
  defaultimage = true
  //USe Global Variables for component
  SignUpComponent = SignUpComponent;
  SigninComponent = SigninComponent;
  GETSTARTEDComponent = GETSTARTEDComponent;
  OvulationTrack3Component = OvulationTrack3Component;
  PregnancyTrack4Component = PregnancyTrack4Component;
  PeriodTrack4Component = PeriodTrack4Component;
  constructor(public route: Router,
    private http: HttpClient,
    private rest: RestService,
    private formBuilder: FormBuilder,
    private cacheDataService: CacheDataService,
    private userPrefernceService: UserPrefernceService,
    private location: Location,
    public dialog: MatDialog) {
    // Building Form Group variables of user inputs
    this.registerForm = this.formBuilder.group({
      Firstname: ['', [Validators.required]],
      Middlename: [''],
      Lastname: ['', [Validators.required]],
      Gender: ['Gender', [Validators.required]],
      DateOfBirth: ['', [Validators.required]],
      Location: ['Location', [Validators.required]],
      Weight: ['', [Validators.required]],
      WeightType: ['', [Validators.required]],
      Height: ['', [Validators.required]],
      HeightType: ['', [Validators.required]],
    });
  }
  // on click Continue button for Register User account
  async onContinue() {
    this.Firstname = this.registerForm.get('Firstname')?.value;
    ContinueComponent.FirstName = this.Firstname;
    this.Middlename = this.registerForm.get('Middlename')?.value;
    this.Lastname = this.registerForm.get('Lastname')?.value;
    this.Gender = this.registerForm.get('Gender')?.value;
    this.DateOfBirth = this.registerForm.get('DateOfBirth')?.value;
    this.Location = this.registerForm.get('Location')?.value;
    this.Weight = isNaN(parseFloat(this.registerForm.get('Weight')?.value))?'': parseFloat(this.registerForm.get('Weight')?.value);
    this.WeightType = this.registerForm.get('WeightType')?.value;
    this.Height = isNaN(parseFloat(this.registerForm.get('Height')?.value))?'':parseFloat(this.registerForm.get('Height')?.value);
    this.HeightType = this.registerForm.get('HeightType')?.value;
    const validateCheck = this.validateInputs(this.Firstname, this.Middlename, this.Lastname, this.Gender, this.DateOfBirth, this.Location, this.Weight, this.WeightType, this.Height, this.HeightType,this.imageUrl);
    // Email store in Global variable
    //date of birth convert to epoch format

    let dob: number = Date.parse(this.DateOfBirth);

    if (validateCheck == "sucess") {
      let weightInKgs = 0, weightInLbs = 0;
      if (this.WeightType == "kgs") {
        weightInKgs = this.Weight;
      } else {
        weightInLbs = this.Weight;
      }
      let heightInCm = 0, heightInInches = 0;
      if (this.HeightType == "cm") {
        heightInCm = this.Height;
      }
      else {
        heightInInches = this.Height;
      }

      if(SigninComponent.uid)this.pId = SigninComponent.uid
      else if(SignUpComponent?.result?.data)this.pId = SignUpComponent.result.data;
      console.log(87)
      ContinueComponent.pId = this.pId;
      ContinueComponent.email = this.e_mail;

      this.isLoading = true;
      this.button = 'Processing';
      // Function call to hit the register user API 
      let response = await this.rest.onRegister(this.pId, this.Firstname, this.Middlename, this.Lastname, this.e_mail, heightInCm, heightInInches, weightInKgs, weightInLbs, this.Gender, dob, this.Location,this.imageUrl);
      if (response.status) {
        let message = "Thanks for registering!";
        const dialogRef = this.dialog.open(ContinueModalComponent, { width: '420px', data: message, disableClose: true });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.userPrefernceService.setToken(response.data)
            const jwtToken = new JwtToken();
            let data: JwtLoginResponse = jwtToken.getDecodedAccessToken(response.data);
            if (data) {
              this.isLoading = false;
              this.button = 'Continue';
              localStorage.setItem("JwtLoginResponse", JSON.stringify(data));
              this.route.navigate([this.cacheDataService.getModule()])
            };
          }
        }); 
      }
      else{
        this.isLoading = false;
        this.button = 'Continue';
      }
    }
    else {
      console.log(validateCheck)
      let message = validateCheck;
        const dialogRef = this.dialog.open(ErrorModalComponent, { width: '420px', data: message, disableClose: true });
        dialogRef.afterClosed().subscribe(result => {})
      //alert(validateCheck);
    }
  }


  //validations of user inputs
  validateInputs(Firstname: string, Middlename: string, Lastname: string, Gender: string, DateOfBirth: string, Location: string, Weight: string, WeightType: string, Height: string, HeightType: string,imageUrl:string): string {
console.log(DateOfBirth)
    //regular expression for contains number
    const containsNumber = /\d/;

    if (Firstname == "" && Lastname == "" && Location == "" && Weight == "" && Height == "" && DateOfBirth == "") {
      return "enter all required fields";
    } else if (containsNumber.test(Firstname) || containsNumber.test(Middlename) || containsNumber.test(Lastname)) {
      return "name should not contain neumerics";
    } else if (this.checkSymbol(Firstname) || this.checkSymbol(Middlename) || this.checkSymbol(Lastname)) {
      return "name should not contain symbols";
    } 
       else if (!Firstname) {
      return "Enter Your First Name";
    } 
    else if (!Lastname) {
      return "Enter Your Last Name";
    } 
    else if (!DateOfBirth) {
      return "Enter Your Date Of Birth";
    }    else if (!Firstname) {
      return "Enter Your First Name";
    } 
    else if (!Lastname) {
      return "Enter Your Last Name";
    } 
     else if (Gender == "Gender") {
      return "please select the Gender";
    }
    else if (!DateOfBirth) {
      return "Enter Your Date Of Birth";
    } else if (!this.isNumber(Weight)) {
      return "enter the weight in neumerics";
    } else if (!this.isNumber(Height)) {
      return "enter the Height in neumerics";
    }  
 
    else if (Location == "Location") {
      return "Enter Your Location";
    }
    else if (!Weight) {
      return "Enter Your Weight";
    } 
    else if (!WeightType) {
      return "Enter Your Weight-type";
    } 
    else if (!Height) {
      return "Enter Your Height";
    } 
    else if (!HeightType) {
      return "Enter Your Height-type";
    } 
    else {
      return "sucess";
    }
  }
  // check the string is a type of number
  isNumber(str: string): boolean {
    return !Number.isNaN(Number(str));
  }
  // check string consist of symbols
  checkSymbol(string: any) {
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (format.test(string)) {
      return true;
    } else {
      return false;
    }
  }

  async ngOnInit(): Promise<void> {
    // console.log(this.JwtLoginResponse)
    //     this.userName = this?.JwtLoginResponse?.name;

    const jwtToken = new JwtToken();
    this.jwtTokenData = jwtToken.getDecodedAccessToken(this.userPrefernceService.getToken());
    if (this.jwtTokenData) {
      this.button = 'Save'; 
      this.pId = this.jwtTokenData.jti;
      this.userName = this.jwtTokenData.name;
      this.e_mail = this.jwtTokenData.email;
      this.phone = this.jwtTokenData.phone;
    }
    if (!this.jwtTokenData){
      this.e_mail =   SignUpComponent.Email||SigninComponent.email;
      this.phone =  SignUpComponent.phone;
    }
    // if(GETSTARTEDComponent.userName!=null || GETSTARTEDComponent.userName!=undefined){
    //   this.userName = GETSTARTEDComponent.userName;
    // }else if(OvulationTrack3Component.userName!=null || OvulationTrack3Component.userName!=undefined){
    //   this.userName = OvulationTrack3Component.userName;
    // }else if(PregnancyTrack4Component.userName!=null || PregnancyTrack4Component.userName!=undefined){
    //   this.userName = PregnancyTrack4Component.userName;
    // }else if(PeriodTrack4Component.userName!=null || PeriodTrack4Component.userName!=undefined){
    //   this.userName = PeriodTrack4Component.userName;
    // }
    if (this.pId != null) {
      let res = await this.rest.getByUserName(this.pId);
      console.log(res)
      if (res.status) {
        let data = res.data;
        this.imageUrl = res.data.imageUrl
        if(this.imageUrl) this.defaultimage = false
        this.e_mail = data.email;
        this.Firstname = data.firstName;
        ContinueComponent.FirstName = this.Firstname;
        this.Middlename = data.middleName;
        this.Lastname = data.lastName;
        this.Gender = data.gender;
        let dB = new Date(data.dob);
        var dd = String(dB.getDate()).padStart(2, '0');
        var mm = String(dB.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = dB.getFullYear();
        //this.DateOfBirth = mm + '/' + dd + '/' + yyyy;
        this.DateOfBirth = yyyy + '-' + mm + '-' + dd;
        this.Location = data.location;
        if (data.weightInKgs != 0) {
          this.Weight = data.weightInKgs;
          this.WeightType = "kgs";
        } else if (data.weightInLbs != 0) {
          this.Weight = data.weightInLbs;
          this.WeightType = "lbs";
        }
        if (data.heightInCm != 0) {
          this.Height = data.heightInCm;
          this.HeightType = "cm";
        } else if (data.heightInInches != 0) {
          this.Height = data.heightInInches;
          this.HeightType = "in";
        }
        this.registerForm.patchValue({
          Firstname: this.Firstname,
          Middlename: this.Middlename,
          Lastname: this.Lastname,
          Gender: this.Gender,
          DateOfBirth: this.DateOfBirth,
          Location: this.Location,
          Weight: this.Weight,
          WeightType: this.WeightType,
          Height: this.Height,
          HeightType: this.HeightType,
        })

      }
    }
    // else{
    //   this.route.navigate(["/SignIn"]);
    // }
    // PIck persistenceId in Response data of create Api
    // console.log(this.SignUpComponent.result)

    // if(this.SignUpComponent.result!=null || this.SignUpComponent.result!=undefined){
    //   console.log(this.SignUpComponent.result)
    //   this.pId = this.SignUpComponent.result.data;
    //   this.e_mail = this.SignUpComponent.Email;
    //   this.phone = this.SignUpComponent.phone;
    // }else if(this.SigninComponent.result!=null || this.SigninComponent.result!=undefined){
    //   console.log(this.SigninComponent.result)
    //   this.pId = this.SigninComponent.result.uid;
    //   this.e_mail = this.SigninComponent.result.email;
    // }else{
    //   // this.pId = this.userName;
    //   const jwtToken =new JwtToken();
    //   let data : JwtLoginResponse = jwtToken.getDecodedAccessToken(this.userPrefernceService.getToken());
    //   if(data){
    //     this.pId = data.jti;
    //   }
    // }
  }

  async onSave() {
    this.Firstname = this.registerForm.get('Firstname')?.value;
    this.Middlename = this.registerForm.get('Middlename')?.value;
    this.Lastname = this.registerForm.get('Lastname')?.value;
    this.Gender = this.registerForm.get('Gender')?.value;
    this.DateOfBirth = this.registerForm.get('DateOfBirth')?.value;
    this.Location = this.registerForm.get('Location')?.value;
    this.Weight =isNaN(parseFloat(this.registerForm.get('Weight')?.value))?'': parseFloat(this.registerForm.get('Weight')?.value);
    this.WeightType = this.registerForm.get('WeightType')?.value;
    this.Height = isNaN(parseFloat(this.registerForm.get('Height')?.value))?'':parseFloat(this.registerForm.get('Height')?.value);
    this.HeightType = this.registerForm.get('HeightType')?.value;

    const validateCheck = this.validateInputs(this.Firstname, this.Middlename, this.Lastname, this.Gender, this.DateOfBirth, this.Location, this.Weight, this.WeightType, this.Height, this.HeightType, this.imageUrl);
    // Email store in Global variable
    console.log(validateCheck)
    //date of birth convert to epoch format
    let dob: number = Date.parse(this.DateOfBirth);

    if (validateCheck == "sucess") {
      let weightInKgs = 0, weightInLbs = 0;
      if (this.WeightType == "kgs") {
        weightInKgs = this.Weight;
      } else {
        weightInLbs = this.Weight;
      }
      let heightInCm = 0, heightInInches = 0;
      if (this.HeightType == "cm") {
        heightInCm = this.Height;
      }
      else {
        heightInInches = this.Height;
      }
      this.isLoading = true;
      this.button = 'Processing'; 
      let response = await this.rest.onModify(this.pId, this.Firstname, this.Middlename, this.Lastname, this.e_mail, heightInCm, heightInInches, weightInKgs, weightInLbs, this.Gender, dob, this.Location,this.imageUrl);
      console.log(response)
      if (response.status) {
        let message = "Your profile has been updated!";
        const dialogRef = this.dialog.open(SuccessModalComponent, { width: '420px', data: message, disableClose: true });
        dialogRef.afterClosed().subscribe(result => {
          if (result) { 
            this.location.back();
          }
        }); 
      }
      else{
        this.isLoading = false;
      this.button = 'Save';
      }
    }
    
    else {
      let message = validateCheck;
        const dialogRef = this.dialog.open(ErrorModalComponent, { width: '420px', data: message, disableClose: true });
        dialogRef.afterClosed().subscribe(result => {})
      //alert(validateCheck);
    }

  }
  clickOnlogo() {
    this.route.navigate(["/homepage"]);
  }
  openInput() {
    document.getElementById("fileInput")?.click();
  }
  async fileChange(event: any) { 
        this.imageUrl=''
        this.imageUrldownloading = true
        this.defaultimage = false
        if (event.target.files[0].type){
          let image = event.target.files[0]
          const formData = new FormData();
          formData.append('image', image); ;
          let response = await this.rest.profileImageSave(formData);
          console.log(response)
          if(response.status){
            this.defaultimage = false
           this.imageUrldownloading = false
            this.imageUrl = response.data.fileUrl
          }
          else{
            this.defaultimage = true
           this.imageUrldownloading = false
          }
        }
  }

  clickOnHeadPhone(){ 
    this.route.navigate(["/contactus"]);
  }

  onChangeWeightType(){
     if(this.WeightType){
      this.registerForm.patchValue({Weight:''})
     }
  }

  onChangeHeightType(){
    if(this.HeightType){
     this.registerForm.patchValue({Height:''})
    }
 }

}


