<div class="ag-success">
    <div class="modal-body text-center">
        <h4>Confirm!</h4>	
        <p>{{data}}</p>
        <!-- <div class="d-flex justify-content-center">
            <button class="btn btn-confirm" data-dismiss="modal" (click)="onCancel()"><span>Close</span></button>
        </div> -->
    </div>

    <div class="modal-footer">
        <button class="btn" (click)="onCancel(false)">No</button>
        <button class="btn btn-yes" (click)="onCancel(true)">Yes</button>
    </div>
</div>
