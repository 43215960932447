<div class="ag-sm-container">

    <div class="left">
        <span class="ag-signin-logo">
            <img src="/assets/images/logo.png" class="logo">
            <h2>RETAILER'S SIGN UP</h2>
            <!-- <div class="user-icon">
                <img src="/assets/images/user-icon2.png">
            </div> -->
        </span>
    </div>
    <div class="right">
        <div class="ag-scroll-manage">
            <div  class="ag-form">
                <form name="signupForm" #signupForm="ngForm" (ngSubmit)="formSubmit(signupForm.value)" class="">
                    <div class="row g-3 mb-4 align-items-center">
                        <div class="col-md-4">
                            <label class="col-form-label">Email</label>
                        </div>
                        <div class="col-md-8">
                            <input type="email" class="form-control" id="email" placeholder="Email" value=""
                                pattern="[a-z0-9._%+-]+[^@]+@[a-z0-9.-]+\.[a-z]{2,4}" name="email" #email="ngModel" ngModel
                                required>
                        </div>
    
                        <div class="row align-items-center justify-content-end" *ngIf="email.invalid && email.touched">
                            <div class="col-md-8">
                                <div class="danger">
                                    <span *ngIf="email.hasError('pattern')">Invalid Email!</span>
                                    <span *ngIf="email.value==''">Email is required!</span>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="row g-3 mb-4 align-items-center">
                        <div class="col-md-4">
                            <label class="col-form-label">Phone</label>
                        </div>
                        <div class="col-md-8">
                            <input id="phone" type="tel" appOnlyNumerics class="form-control" placeholder="Phone"
                                minlength="10" maxlength="10" name="phone" #phone="ngModel" ngModel required>
                        </div>
    
                        <div class="row align-items-center justify-content-end" *ngIf="phone.invalid && phone.touched">
                            <div class="col-md-8">
                                <div class="danger">
                                    <span>Phone number is required and should be of 10 digits!</span>
                                </div>
                            </div>
                        </div>
                    </div>
    
    
                    <div class="row g-3 mb-4 align-items-center">
                        <div class="col-md-4">
                            <label class="col-form-label">Password</label>
                        </div>
                        <div class="col-md-8">
                            <input id="password" [type]="hide ? 'password' : 'text'" class="form-control" minlength="8"
                                placeholder="Password" (change)="validateInputs(password.value)" title="Password Should contains: &#10;
                        Minimum 8 characters &#10;
                        Atleast 1 numeric &#10;
                        Atleast 1 uppercase &#10;
                        Atleast 1 lowercase" data-html="false" rel="tooltip" required name="password" #password="ngModel"
                                ngModel>
                            <a href="javascript:void:(0);" class="kg-eye" (click)="hide = !hide">
                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </a>
                        </div>
    
                        <div class="row align-items-center justify-content-end" *ngIf="password.touched">
                            <div class="col-md-8">
                                <div class="danger">
                                    <div class="kg-float-balance">
                                        <span
                                            *ngIf="!passwordCheckStatus && password.value!=''">{{this.passwordCheck}}</span>
                                        <span *ngIf="password.value==''">Password is required!</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="row g-3 mb-4 align-items-center">
                        <div class="col-md-4">
                            <label class="col-form-label">Confirm Password</label>
                        </div>
                        <div class="col-md-8">
                            <input id="confirm_password" [type]="confirm_hide ? 'password' : 'text'"
                                class="form-control agkg-mt" placeholder="Confirm Password" title="Password Should contains:&#10;
                                Minimum 8 characters&#10;
                                Atleast 1 numeric&#10; 
                                Atleast 1 uppercase&#10; 
                                Atleast 1 lowercase" data-html="false" rel="tooltip" required name="confirm_password"
                                #confirm_password="ngModel" ngModel>
                            <a href="javascript:void:(0);" class="kg-eye" (click)="confirm_hide = !confirm_hide">
                                <mat-icon>{{confirm_hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </a>
                        </div>
    
                        <div class="row align-items-center justify-content-end"
                            *ngIf="confirm_password.invalid && confirm_password.touched">
                            <div class="col-md-8">
                                <div class="danger">
                                    <div class="kg-float-balance">
                                        <span>Confirm Password is required!</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="row g-3 align-items-center justify-content-end"
                        *ngIf="(confirm_password.value != password.value) && confirm_password.valid">
                        <div class="col-md-8">
                            <div class="danger">
                                <div class="kg-float-balance">
                                    <span>Password and Confirm Password should be same!</span>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <!-- <div class="row g-3 mb-4 align-items-center">
                        <div class="col-md-4">
                            <label class="col-form-label">Date Of Birth</label>
                        </div>
                        <div class="col-md-8">
                            <input id="" type="date" class="form-control" placeholder="Date Of Birth" name="" ngModel
                                required>
                        </div>
                    </div>
    
                    <div class="row g-3 mb-4 align-items-center">
                        <div class="col-md-4">
                            <label class="col-form-label">Gender</label>
                        </div>
                        <div class="col-md-8">
                            <span class="image-r-btn">
                                <label class="img-btn">
                                    <input type="radio" name="gender-flags" checked>
                                    <img src="/assets/images/gender-f.png"
                                        alt="Female">
                                </label>
    
                                <span class="r-label">Female</span>
                            </span>
                            <span class="image-r-btn">
                                <label class="img-btn">
                                    <input type="radio" name="gender-flags">
                                    <img src="/assets/images/gender-m.png"
                                        alt="Female">
                                </label>
    
                                <span class="r-label">Male</span>
                            </span>
                        </div>
                    </div> -->
    
                    <div class="row g-3 align-items-center justify-content-end">
                        <div class="col-md-8 text-center">
                            <button type="submit" class="Signin" [disabled]="isLoading"><i [ngClass]="{'fa fa-spinner fa-spin': isLoading}"></i>&nbsp;{{button}}</button>
                        </div>
                    </div>
    
                    <div class="row g-3 align-items-center justify-content-end">
                        <div class="col-md-8 text-center">
                            <span class="haveAccount">Already have an account? <a href="javascript:void(0);" (click)="onNavigateSignIn()">Sign
                                    In</a></span>
                        </div>
                    </div>
    
                    <!-- <div class="kg-signin">
                        
                    </div> -->
                </form>
            </div>
        </div>
    </div>

</div>