<!-- <app-homepage></app-homepage> -->
<!-- <router-outlet></router-outlet> -->
<!-- <a routerLink="/Signin" routerLinkActive="Active">Signin</a>
<a routerLink="/SignUp" routerLinkActive="Active">SignUp</a>
<a routerLink="/GETSTARTED" routerLinkActive="Active">GETSTARTED</a>
<a routerLink="/Continue" routerLinkActive="Active">Continue</a>

<app-signin></app-signin>
<app-sign-up></app-sign-up>
<app-period-tracking></app-period-tracking> -->

<div class="selected-theme">
  <section>
    <main class="main-body">
      <router-outlet></router-outlet>
    </main>
  </section>
</div>
