<form (keyup.enter)="pressEnter()" class="container-header">
  <input [matAutocomplete]="auto" class="autoComplete" [formControl]="myControl" [placeholder]="placeHolder" />
  <span class="ag-ac-icon">
    <mat-icon *ngIf="selectedItem" matSuffix (click)="clearSelection()">clear</mat-icon>
    <mat-icon *ngIf="!isLoading && !selectedItem" matSuffix>search</mat-icon>
    <mat-spinner *ngIf="isLoading" matSuffix diameter="25"></mat-spinner>
  </span>
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" aria-expanded="true" (optionSelected)="onSelected(myControl.value)">
    <mat-option *ngFor="let option of filteredOptions" [value]="option" >
      {{ option }}
    </mat-option>
  </mat-autocomplete>
</form>
<br />
<ng-container *ngIf="errorMsg">
  {{ errorMsg }}
</ng-container>