<div class="ag-otp">
    <div class="modal-body text-center">
        <h4>OTP Verification!</h4>
        <p>Enter the 6 digit verification code recieved on your Phone/Mail</p>
        <div class="vcode-rotp">
            <div class="left">
                <span>Verification code </span>
                <!-- <span><i class="fa fa-exclamation-circle" aria-hidden="true"></i></span>
                <span>2:30</span> -->
            </div>
            <div class="right">
                <a href="javascript:void(0);" (click)="resendOtp()">Resend OTP</a>
            </div>
        </div>

        <div class="otp-input-fields">
            <ng-otp-input  class="otp__digit otp__field__1" (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
            <!-- <input type="number" class="otp__digit otp__field__1" maxlength="1">
            <input type="number" class="otp__digit otp__field__2" maxlength="1">
            <input type="number" class="otp__digit otp__field__3" maxlength="1">
            <input type="number" class="otp__digit otp__field__4" maxlength="1">
            <input type="number" class="otp__digit otp__field__5" maxlength="1">
            <input type="number" class="otp__digit otp__field__6" maxlength="1"> -->
        </div>
        <div class="text-center">
            <!-- <button class="verify" (click)="onValidate()">
                Verify
            </button> -->
            <button type="submit" button class="verify" [attr.disabled]="!showBtn? true : null" (click)="onValidate()"><i [ngClass]="{'fa fa-spinner fa-spin': isLoading}"></i>&nbsp;{{Otpbutton}}</button>
        </div>
    </div>

    <!-- <div class="modal-footer">
        <button class="btn" (click)="onCancel(false)">No</button>
        <button class="btn btn-yes" (click)="onCancel(true)">Yes</button>
    </div> -->
</div>