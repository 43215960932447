import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminDetails1 } from '../admin';
import { AdminConsole3Component } from '../admin-console3/admin-console3.component';
import { RestService } from '../rest.service';

@Component({
  selector: 'app-admin-console4',
  templateUrl: './admin-console4.component.html',
  styleUrls: ['./admin-console4.component.scss']
})
export class AdminConsole4Component implements OnInit {
  AdminConsole3Component=AdminConsole3Component;
  AdminDetails1: any;
  
  constructor( private rest : RestService,private route : Router ) {

   }

  ngOnInit(): void {
  }

 async  onSave(details:any){


   
  
  const admin=new AdminDetails1();
  
  admin.setCreatedOn(AdminConsole3Component.adminDetails.createdOn);
   admin.setParentChain("home."+details.category);
   admin.setAuthor(details.Author);
   admin.setContent(details.content);
   admin.setHeader(details.Header);
   admin.setEmail(AdminConsole3Component.adminDetails.email);
   admin.setId(AdminConsole3Component.adminDetails.id);
   admin.setImageURLs(details.Bucket_URL);
   
   const now = new Date().valueOf();
   console.log(now);
   admin.setLastUpdatedOn(now);
   console.log("admin : "+JSON.stringify(admin));
   
  let res =await this.rest.putCore(admin);
  if(res.status){
    alert("Data Saved Successfully");
    this.route.navigate(["/AdminConsole2"]);
  }
 }
 
  
  
}
