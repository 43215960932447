<!-- NAVBAR -->
<div class="body">
  <!-- header -->
  <app-shared-header [navigationUrls]="navigationUrls" [showSocialMediaLink]="true"></app-shared-header>

  <!-- WOMEAN LANDING PAGE DESCRIPTION -->

  <div class="container">
    <section class="main">
      <div class="womean-join">
        <p>Let Us Make This World A Better Place</p>
        <div class="womean-info">
          <h4 class="header1">
            Welcome to
            <!-- <p class="header2">Womean</p> -->
          </h4>
        </div>
        <div class="ag-carousel">
          <ngb-carousel>
            <ng-template ngbSlide>
              <div class="wrapper">
                <img src="/assets/images/home-3.png" alt="Random first slide">
              </div>
            </ng-template>
            <!-- <ng-template ngbSlide>
              <div class="wrapper">
                <img src="/assets/images/1.png" alt="Random second slide">
              </div>
            </ng-template> -->
            <!-- <ng-template ngbSlide>
              <div class="wrapper">
                <img src="/assets/images/3.png" alt="Random third slide">
              </div>
            </ng-template>
            <ng-template ngbSlide>
              <div class="wrapper">
                <img src="/assets/images/4.png" alt="Random third slide">
              </div>
            </ng-template> -->
          </ngb-carousel>
        </div>
        <button class="join-us" *ngIf="!userloggedIn" (click)="navigateToSignIn()">Join Us</button>
      </div>

      <div class="womean-info">
        <p class="info">
          We are trying to build a safer and better world for all Women by lending
          them a helping hand during the tougher times of their lives.
        </p>
      </div>

    </section>

    <section class="productsection">
      <p class="prod-header">Explore our wide variety of services</p>
      <div class="products">

        <!-- TRACK MODULE -->

        <div class="row justify-content-end">
          <div class="col-md-9">
            <div class="Track_">
              <div class="trackModule">
                <b>Wondering how to keep track of all your cycles?</b>
                <p>We got your back with our Period, Ovulation and Pregnancy tracker</p>
                <button class="btn-track" (click)="navigateToModule('GETSTARTED')">
                  Track
                </button>
              </div>
              <div class="ag-m-display">
                <img class="track-icon" src="assets/images/TRACK.png" alt="" />
              </div>
            </div>
          </div>
        </div>


        <!-- READ MODULE -->
        <div class="row">
          <div class="col-md-10">
            <div class="Read_">
              <div class="ag-m-display">
                <img class="read-icon" src="assets/images/READ.png" alt="" />
              </div>
              <div class="readModule">
                <b>Tired of scrolling through Internet for one simple question?</b>
                <p> Get all your questions answered on Womean-opedia!</p>
                <button class="btn-read" (click)="navigateToModule('read')">
                  Read
                </button>
              </div>
            </div>
          </div>
        </div>


        <!-- SHOP MODULE -->
        <div class="row justify-content-end">
          <div class="col-md-9">

            <div class="Shop_">
              <div class="shopModule">
                <b>
                  Missed out on stocking up your period supplies? </b>
                <p>Jump right into our store and get them at your doorstep instantly.</p>

                <button class="btn-shop" (click)="modalShop()">Shop</button>
              </div>
              <div class="ag-m-display">
                <img class="shop-icon" src="assets\images\SHOP.png" alt="" />
              </div>
            </div>

          </div>
        </div>

        <!-- CONNECT MODULE -->
        <div class="row">
          <div class="col-md-10">
            <div class="Connect_">
              <div class="ag-m-display">
                <img class="connect-icon" src="assets\images\CONNECT.png" alt="" />
              </div>

              <div class="connectModule">
                <b>Need to see a doctor immediately?</b>
                <p>Get virtual consultations from our experts for all your problems.</p>
                <button class="btn-connect" (click)="modalConnct()">Connect</button>
              </div>
            </div>
          </div>
        </div>



        <!-- BUILD MODULE -->
        <div class="row justify-content-end">
          <div class="col-md-9">
            <div class="Build_">
              <div class="buildModule">
                <b>Want to make an impact? </b>
                <p>Lend a helping hand in empowering every Woman.</p>
                <button class="btn-build" (click)="modalBuild()">Build</button>
              </div>
              <div class="ag-m-display">
                <img class="build-icon" src="assets\images\BUILD.png" alt="">
              </div>
            </div>
          </div>
        </div>


      </div>
    </section>

    <section class="esteemed-partners">
      <div class="text-center">
        <h1>Our Esteemed Partners</h1>
      </div>
      <div class="ag-likhithas">
        <span>
          <img src="assets\images\female-doc.png" alt="">
        </span>
        <div>
          <img src="assets\images\likhithas.png" alt="">
          <p>
            Get prompt and dependable healthcare and <br>
            diagnostic solutions right where you are.
          </p>
        </div>        
        <span>
          <img src="assets\images\male-doc.png" alt="">
        </span>
      </div>
      <div class="ag-container">

        <div class="input-container">
          <img src="assets\images\IMG_20211002_101553 (1) (1).png" alt="Avatar">
          <div class="doc-dtl">
            <b>Dr. Sushobhan Roy</b>
            <!-- <p>M.B.B.S,MS <br> (ENT & Head and Neck Surgery)</p> -->
          </div>
        </div>
        <div class="input-container">
          <img src="assets\images\Group 7.png" alt="Avatar">
          <div class="doc-dtl">
            <b>Dr. R Z Shahnaz</b>
            <!-- <p>B.D.S</p> -->
          </div>
        </div>
        <div class="input-container">
          <img src="assets\images\doc01.png" alt="Avatar">
          <div class="doc-dtl">
            <b>Dr. Pooja Guwalani</b>
            <!-- <p>B.D.S</p> -->
          </div>
        </div>
        <div class="input-container">
          <img src="assets\images\doc02.png" alt="Avatar">
          <div class="doc-dtl">
            <b>Dr. Arham</b>
            <!-- <p>B.D.S</p> -->
          </div>
        </div>

        <!-- <div class="input-container">
          <img src="assets\images\Group 8.png" class="input-con-img" alt="Avatar">
        </div> -->

        <!-- <div class="input-container">
          <img src="assets\images\IMG_20211002_101553 (1) (1).png" alt="Avatar">
          <div class="doc-dtl">
            <b>Dr. Sushobhan Roy</b>
            <p>M.B.B.S,MS <br> (ENT & Head and Neck Surgery)</p>
          </div>
        </div> -->

      </div>
      <womean-scroll-to-top></womean-scroll-to-top>
    </section>
  </div>

  <!-- FOOTER -->
  <app-footer></app-footer>
</div>