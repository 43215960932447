import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'womean-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  constructor() { }


  
  navigationUrls = [
    {
      urls: 'Home Page',
      link: '/homepage',
      icons: 'fa fa-home',
      active: false
    },
    {
      urls: 'The Initiative',
      link: '/initiative',
      icons: 'fa fa-file',
      active: false
    },
    {
      urls: 'About Us',
      link: '/aboutus',
      icons: 'fa fa-info-circle',
      active: true
    },
    {
      urls: 'Contact Us',
      link: '/contactus',
      icons: 'fa fa-phone',
      active: false
    },
  ];

  ngOnInit(): void {
  }

}
