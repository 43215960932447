import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CacheDataService } from '../core/services/cache-service/cache-data.service';
import { UserPrefernceService } from '../core/services/user-prefernce.service';
import { SigninComponent } from '../signin/signin.component';
import { MatDialog } from '@angular/material/dialog';
import { ShopModalComponent } from '../modal/shop-modal/shop-modal.component';
import { BuildModalComponent } from '../modal/build-modal/build-modal.component';
import { ConnectModalComponent } from '../modal/connect-modal/connect-modal.component';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit {
  userloggedIn: boolean = false;
  SigninComponent = SigninComponent;

  constructor(
    private router: Router,
    private cacheDataService: CacheDataService,
    private userPrefernceService: UserPrefernceService,
    public dialog: MatDialog
  ) {
    console.log(this.userPrefernceService.getToken());
  }

  ngOnInit(): void {
    console.log(this.userPrefernceService.getToken());
    if (this.userPrefernceService.getToken()) {
      this.userloggedIn = true;
    } else {
      this.userloggedIn = false;
    }
  }

  navigationUrls = [
    {
      urls: 'Home Page',
      link: '/homepage',
      icons: 'fa fa-home',
      active: true,
    },
    {
      urls: 'The Initiative',
      link: '/initiative',
      icons: 'fa fa-file',
      active: false,
    },
    {
      urls: 'About Us',
      link: '/aboutus',
      icons: 'fa fa-info-circle',
      active: false,
    },
    {
      urls: 'Contact Us',
      link: '/contactus',
      icons: 'fa fa-phone',
      active: false,
    },
  ];

  handleNavigation(module: string) {
    this.router.navigate([module]);
  }

  navigateToModule(moduleName: modules): void {
    //if (this.userPrefernceService.getToken()) {
    this.cacheDataService.setModule(moduleName);
    this.router.navigate([this.cacheDataService.getModule()]);
    console.log(this.cacheDataService.getModule());
    //}
    // else {
    //   this.router.navigate(["/SignIn"]);
    // }
  }
  navigateToSignIn(): void {
    this.cacheDataService.setModule('homepage');
    this.router.navigate(['/SignIn']);
  }

  modalShop() {
    this.dialog.open(ShopModalComponent, { width: '1104px', disableClose: true });
  }

  modalConnct() {
    this.dialog.open(ConnectModalComponent, { width: '1104px', disableClose: true });
  }

  modalBuild() {
    this.dialog.open(BuildModalComponent, { width: '1104px', disableClose: true });
  }
}
