export class QueryRequestBody {
    private  email : any;
    private  query : any;
    private  contact : any;

    /**
     * Getter email
     * @return {any}
     */
	public getEmail(): any {
		return this.email;
	}

    /**
     * Getter query
     * @return {any}
     */
	public getQuery(): any {
		return this.query;
	}
/**
     * Getter email
     * @return {any}
     */
    public getContact(): any {
		return this.contact;
	}
    /**
     * Setter email
     * @param {any} value
     */
	public setEmail(value: any) {
		this.email = value;
	}

    /**
     * Setter query
     * @param {any} value
     */
	public setQuery(value: any) {
		this.query = value;
	}

     /**
     * Setter query
     * @param {any} value
     */
	public setContact(value: any) {
		this.contact = value;
	}
}