import { DatePipe } from '@angular/common';
import { Component, OnInit, EventEmitter, Input, Output, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { DateRange } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BaseTrackComponent } from '../base-tracking.component';
import { RestService } from '../rest.service';
import { Track } from '../userCredentials';
import { fadeInOnEnterAnimation,fadeInExpandOnEnterAnimation, fadeOutCollapseOnLeaveAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';

@Component({
  selector: 'app-period-track',
  templateUrl: './period-track.component.html',
  styleUrls: ['./period-track.component.scss'],
  animations: [
    fadeInOnEnterAnimation(),
    fadeOutOnLeaveAnimation(),
    fadeInExpandOnEnterAnimation({ duration: 400 }),
    fadeOutCollapseOnLeaveAnimation({ duration: 400 }),
  ]
})
export class PeriodTrackComponent
  extends BaseTrackComponent
  implements OnInit {
  @ViewChild('PeriodDetails') f: NgForm;
  @Output() onClickContinue = new EventEmitter<any>();
  @ViewChild('PregnancyDetails') PregnancyDetails: FormGroupDirective;
  pregnancyTracking = true;
  onPeriod: boolean;
  periods: string;
  Menstual: string;
  dayOfPeriod: number;
  static lastPeriodStartDate: any;
  static lastPeriodEndDate: any;
  static trackDetails: Track;
  wantToGetPregnant: boolean;
  showMenstual: boolean;
  showMsg: boolean;
  pP = false;
  pR = false;
  showPeriodStartedDate: boolean;
  showYesPeriod: boolean;
  showCrampsCount: boolean;
  havingCramps: boolean;
  crampsCount = 0;
  showPeriodDays: boolean;
  countsOfCramps = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  isEditable: any
  public continueHide = false;
  start_day: any;
  start_month: any;
  start_year: any;
  end_day: any;
  end_month: any;
  end_year: any;
  start_date: any;
  end_date: any;
  trackData: any
  day_Period: any
  @Output() selectedRangeValueChange = new EventEmitter<DateRange<Date>>();
  constructor(private rest: RestService, private route: Router, private elRef: ElementRef,private location: Location) {
    super(); this.isEditable = this.route.getCurrentNavigation().extras?.state?.['isEdit'];

  }



  ngOnInit(): void {
    // this.periodsSaveNo()
    // this.selectedRangeValue = new DateRange(moment({date :  10, month :  2 ,year :  2023}), moment({date :  10, month :  2 ,year :  2023}));
    // console.log(this.selectedRangeValue) 
    // this.start_date = '02-09-2023'
    // var inputs = this.elRef.nativeElement.querySelectorAll("input");
    // console.log(inputs) 
    this.getEditData()
  }


  getEditData() {
    this.rest.getPeriodTrackEditData().subscribe((res: any) => {
      console.log(res)
      this.trackData = res
      if (Object.keys(this.trackData).length != 0) {

        if (this.trackData?.data?.onPeriod) {
          this.day_Period = this.trackData?.data?.dayOfPeriod
          this.periodsSaveYes()
          if(!this.day_Period){
            this.showMsg=true
          }
          else{
            this.showMsg=false
          }
        }
        else {
          this.periodsSaveNo()
        }
      }
    })
  }

  editIfnotOnPeriod(res: any) {
    if (Object.keys(res).length != 0) {
    if (res.data?.lastPeriodStartDate && res.data?.lastPeriodEndDate) {
      console.log(this.selectedRangeValue)
      this.start_day = String(new Date(res.data.lastPeriodStartDate).getDate()).padStart(2, '0');
      this.start_month = String(new Date(res.data.lastPeriodStartDate).getMonth() + 1).padStart(2, '0'); //January is 0!
      this.start_year = String(new Date(res.data.lastPeriodStartDate).getFullYear()).padStart(2, '0');
      this.start = this.start_year + '-' + this.start_month + '-' + this.start_day;
      this.start_date = this.start_day + '-' + this.start_month + '-' + this.start_year;

      this.end_day = String(new Date(res.data.lastPeriodEndDate).getDate()).padStart(2, '0');
      this.end_month = String(new Date(res.data.lastPeriodEndDate).getMonth() + 1).padStart(2, '0'); //January is 0!
      this.end_year = String(new Date(res.data.lastPeriodEndDate).getFullYear()).padStart(2, '0');
      this.end = this.end_year + '-' + this.end_month + '-' + this.end_day;
      this.end_date = this.end_day + '-' + this.end_month + '-' + this.end_year;

      this.selectedRangeValue = new DateRange(moment(this.start), moment(this.end));
      this.handleInputStartDate()
    }
    if (res.data?.havingCramps) {
      console.log(23235)
      this.menstualSaveYes()
      this.crampsCount = res.data.crampSeverity
      this.continueHide = true;
    }
    else {
      this.menstualSaveNo()
    }
  }
  }

  periodDays(valid: boolean) {
    console.log("valid no of period : " + valid);
    if (valid) {
      console.log("valid no of period : " + valid);
      this.showMenstual = true;
    } else {
      this.showMenstual = false;
    }

  }

  setComponentTrackDates() {
    this.selectedRangeValueChange.emit(this.selectedRangeValue);
    PeriodTrackComponent.lastPeriodStartDate = this.start;
    PeriodTrackComponent.lastPeriodEndDate = this.end;
  }


  periodsSaveNo() {
    this.pP = false;
    this.onPeriod = false;
    this.periods = "No";
    this.showPeriodStartedDate = true;
    this.showYesPeriod = false;
    this.showPeriodDays = false;
    this.showMenstual = false;
    this.showCrampsCount = false;
    this.continueHide = false;
    this.Menstual = null;
    this.editIfnotOnPeriod(this.trackData)
  }

  periodsSaveYes() {
    this.pP = false;
    this.onPeriod = true;
    this.periods = "Yes";
    this.showPeriodStartedDate = false;
    this.showYesPeriod = true;
    this.showCalendar = false;
    this.continueHide = false;
    this.showPeriodDays = true;
    this.showMenstual = false;
    this.showCrampsCount = false;
    this.end = null;
    this.Menstual = null;
    this.showMsg=true
    this.editIfnotOnPeriod(this.trackData)
  }

  menstualSaveNo() {
    this.crampsCount = 0;
    this.pR = false;
    this.havingCramps = false;
    this.continueHide = true;
    this.Menstual = "No";
    this.showCrampsCount = false;
  }

  menstualSaveYes() {
    this.pR = false;
    this.havingCramps = true;
    this.continueHide = false;
    this.Menstual = "Yes";
    this.showCrampsCount = true;
    this.crampsCount = this.trackData?.data?.crampSeverity
    if (this.crampsCount) this.continueHide = true;
    else this.continueHide = false;
  }

  handleCountCramps(event: Event) {
    const target = event.target as HTMLInputElement
    this.crampsCount = Number(target.value);
    console.log("this.crampsCount : " + this.crampsCount);
    this.continueHide = true;
  }

  onSubmit(details: any) {
    const track = new Track();

    track.period_Tracking = true;
    track.on_Period = this.onPeriod;
    track.day_OfPeriod = 0;
    track.last_PeriodStartDate = Date.parse(this.start);
    track.last_PeriodEndDate = Date.parse(this.end);
    track.having_Cramps = this.havingCramps;
    track.cramp_Severity = this.crampsCount;//details.flexRadioDefault;
    track.assumed_PeriodCycle = 0;
    track.pregnancy_Tracking = false;
    track.ovulation_Tracking = false;
    track.to_Notify = false;
    track.day_OfPeriod = details.dayOfPeriod;
    PeriodTrackComponent.trackDetails = track;
    console.log("track : " + JSON.stringify(track));
    this.onClickContinue.emit('period-track2')
    //this.route.navigate(["/period-track2"]);
  }

  countNumber(event: Event) { 
    const target = event.target as HTMLInputElement
    console.log(target.value)
    if (Number(target.value) > 0 && Number(target.value) < 8 &&target.value != "") {
      this.showMenstual = true
      this.showMsg=false
    }
    else {
      this.showMsg=true
      this.showMenstual = false
    }
  }

  displayBox(){
    var modal = document.getElementById("model_");
    modal.style.display = 'block';
  }

  closeBox(){
    var modal = document.getElementById("model_");
    modal.style.display = 'none';
  }
 
  goBack(){
    this.location.back();
  }

}
