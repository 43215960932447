import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'womean-shop-modal',
  templateUrl: './shop-modal.component.html',
  styleUrls: ['./shop-modal.component.scss']
})
export class ShopModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ShopModalComponent>,
  ) { }

  ngOnInit(): void {
  }

  onCancel() { 
    this.dialogRef.close(); 
  }

}
