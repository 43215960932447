<div class="body">

  <app-shared-header [navigationUrls]="navigationUrls" [showSocialMediaLink]="false"></app-shared-header>
  <h1 class="page-heading">Track Your Pregnancy Trimesters</h1>
  <div class="calendar-sec">
    <div class="container">
      <div class="float-container d-flex justify-content-center">
        <div class="float-child" *ngIf="selectedRangeValue">
          <h2>Your Previous Period</h2>
          <div class="calendar">
            <div class="mat-calendar-wrapper" style="background: #feecf6; border-radius: 30px;">
              <womean-material-calendar [selected]="selectedRangeValue" [type]="'Range'" [canclick]="false">
              </womean-material-calendar>
            </div>
          </div>
        </div>
        <div class="cal2 float-child" *ngIf="estimatedDueDate">
          <h2>Your Due Date</h2>
          <div class="calendar">
            <div class="mat-calendar-wrapper" style="background: #feecf6; border-radius: 30px;">
              <womean-material-calendar [selected]="estimatedDueDate" [canclick]="false"></womean-material-calendar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="text-center">
      <button type="submit" class="btn-select" (click)="edit()">
        Edit
      </button>
    </div>

    <div class="row align-items-center">
      <div class="col-md-6">
        <div class="progress-bar-circular">
          <div class="progress-bar-width">
            <circle-progress [percent]="cycleCompletedPercentage" [radius]="210" [space]="-20" [outerStrokeWidth]="20"
              [outerStrokeColor]="'#882279'" [innerStrokeColor]="'#eeeeee'" [innerStrokeWidth]="20"
              [imageSrc]="'assets/images/pregnancy_dashboard.png'" [imageHeight]="267" [imageWidth]="178"
              [showImage]="true" [showBackground]="false" [animation]="true" [animationDuration]="300"
              [responsive]="true">
            </circle-progress>
          </div>
          <!-- <womean-progress-bar [completed]="cycleCompletedPercentage" [img]="brogressBarImage"></womean-progress-bar> -->
        </div>
      </div>
      <div class="col-md-6">
        <!-- <h1 class="kg-h2">This is what your Cycle looks like</h1>
        <div class="priodMonth">
          <div class="glider-contain"> 
            <div class="glider">
              <div class="glider-track">
                <div class="monthlyPeriodTime">
                  <div class="date-data">31-05-2023</div>
                </div>
                <div class="monthlyPeriodTime">
                  <div class="date-data">31-05-2023</div>
                </div>
                <div class="monthlyPeriodTime">
                  <div class="date-data">31-05-2023</div>
                </div>
              </div>
            </div> 
          </div>
        </div> -->
        <h1 class="kg-h1">Your Cycle Looks Something Like This</h1>
        <div class="fluid-container2">
          <div class="input-container">
            <i style="color: rgb(154, 86, 162)" class="icon1 fa-solid fa-circle"></i>
            <div class="content">
              <h2 class="content-heading">{{ DaysUntilDueDate }} days</h2>
              <h2>until your estimated Due-date</h2>
            </div>
          </div>
          <div class="input-container">
            <i style="color: rgb(113, 112, 46)" class="icon1 fa-solid fa-circle"></i>
            <div class="content" *ngIf="!exceptionPregMsg">
              <h2 class="content-heading">{{ weeksOfPregnancy }} Weeks</h2>
              <h2>of Pregnancy</h2>
            </div>
            <div class="content" *ngIf="exceptionPregMsg">
              <h2 class="content-heading">{{ exceptionPregMsg }}</h2> 
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="container">
    <div class="ag-content">
      <div class="wk-18">
        <div>
          <h1>Week {{ weeksOfPregnancy }} <br></h1>
        </div>
        <div><img src="/assets/images/gender-reveal-time.png" alt=""></div>
      </div>
      <p [innerHTML]="weekText">
      </p>
    </div>
  </div>
  <div *ngIf="" style="margin-left: 15%" class="offset-1">
    <h2 style="font-size: 24pt">Week {{ weeksOfPregnancy }}</h2>
    <!-- <h2>Gender reveal time</h2> -->
    <div class="image">
      <img src="\assets\images-removebg-preview.png" alt="" height="120" width="120" />
    </div>

    <div class="content2">
      <p [innerHTML]="weekText">
      </p>
    </div> 
  </div>

  <div class="container">
    <div class="row">
      <div class="col-sm-4 col-xs-4 d-flex justify-content-center">
        <button type="button" class="select-btn" (click)="handleNavigation('/period-steps')">
          Track <br> Periods
        </button>
      </div>
      <div class="col-sm-4 col-xs-4 d-flex justify-content-center">
        <button type="button" class="select-btn" routerLink="/read">Explore <br> Women-opedia</button>
      </div>
      <div class="col-sm-4 col-xs-4 d-flex justify-content-center">
        <button type="button" class="select-btn" (click)="handleNavigation('/OvulationSteps')">
          Track <br> Ovulation
        </button>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
  <womean-scroll-to-top></womean-scroll-to-top>
</div>
